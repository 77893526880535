import { usePayments } from '../../../hooks/payment.hook';
import { CreateCheckoutSession, Product } from '../../../models/payment';
import { useEffect } from 'react';
import Button from '../../../common/library/Button';
import { PricingBox } from '../../../common/library/PricingBox';
import Spinner from '../../../common/library/Spinner';
import { Merchant } from '../../../models/merchant';
import { User } from '../../../models/users';
import WarningBox from '../../../common/WarningBox';

interface Props {
  merchant: Merchant,
  user: User,
  onComplete: (acceptedSubscription: boolean) => void;
}

export default function SubscribeTutorial({ merchant, user, onComplete }: Props) {
  const { useSubscriptionQuery, useProductsQuery, useCreateCheckoutMutation } =
    usePayments();

  const {
    data: subscription,
    isLoading: isLoadingSubscription,
    isSuccess: isSuccessSubscription,
  } = useSubscriptionQuery(user.id, merchant.id);
  const { data: products, isSuccess: isSuccessProducts } = useProductsQuery(merchant.licenseId, merchant.address.state);
  const createCheckout = useCreateCheckoutMutation(user.id, merchant.id, (res) => {
    onComplete(true);
    window.location.replace(res.url);
  }, (error) => {
    console.error(error);
  });

  const checkout = (product: Product) => {
    const createCheckoutRequest: CreateCheckoutSession = {
      successUrl: `${window.location}`,
      cancelUrl: `${window.location}`,
      merchantId: merchant.id,
      lineItems: [
        {
          productId: product.id,
          price: product.priceId,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      email: user.email,
    };
    createCheckout.mutate(createCheckoutRequest);
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <WarningBox
        message={'Get the most out of Qbon! Sharing capabilities are restricted to subscribed merchant accounts. With a subscription you will be able to give your customers Qbons when they share themselves visiting your business on social media, capture the pictures they took, have access to advanced metrics that show you what platforms your customer base is sharing on and so much more!'} />
      {!isLoadingSubscription ? (
        <div>
          {isSuccessSubscription && subscription.status === 'active' ? (
            <>
              <div className={'pt-6'}>
                <Button label={'Continue'} variant={'outline'}
                        onClick={() => onComplete(true)} buttonWidth={'full'} />
              </div>
            </>
          ) : merchant.status !== 'SUBSCRIBED' && isSuccessProducts ? (
            <>
              <div>
                {products.length > 0 && (
                  <PricingBox
                    loading={createCheckout.status === 'pending'}
                    title={products[0].name}
                    price={(products[0].defaultPrice / 100).toString()}
                    rate={'Month'}
                    description={products[0].description}
                    features={[
                      'Merchant mobile app listing',
                      'Merchant store front',
                      'Merchant QR code card for user physical access',
                      'QR code scan location verification',
                      'Location based user access',
                      'Raffles',
                      'Merchant content feed',
                      'Custom store front links',
                      'Merchant secure Qbon scanner',
                      'Unlimited user QR scans',
                      'Create unlimited Qbons',
                      'Metrics',
                      'Share for points system',
                    ]}
                    onPurchase={() => {
                      checkout(products[0]);
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className={'pt-6'}>
                <Button label={'Continue'} variant={'outline'}
                        onClick={() => onComplete(true)} buttonWidth={'full'} />
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <Spinner height={10} width={10} color="red" />
        </>
      )}
      {merchant.status !== 'SUBSCRIBED' && <div className={'pt-6'}>
        <Button buttonWidth={'full'} label={'No, Thank You!'} onClick={() => onComplete(false)} />
      </div>}
    </>
  );
}