export const vars = {
  supabase: {
    url: "https://auth.qbonapp.com",
    anonKey:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im93bWJ1cXdwcmdmbXNsdHJleXlzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTA3NzM5MjEsImV4cCI6MjAyNjM0OTkyMX0.OGwMKlwjpI8EbnmSXOzN8_cMauZ6M4Fl3KfXnw9BBFQ",
  },
  api: {
    baseUrl: "https://putr56qqmv.us-east-1.awsapprunner.com/api/v1",
  },
};
