import { useContext } from 'react';
import { AuthContext } from '../common/AuthProvider';


export default function useAuthContext() {
  const ctx = useContext(AuthContext);

  if (ctx === undefined) {
    // throwing here is a nice touch because this is really a developer error
    // and if this happened during production, something must have really broken
    // and hopefully an error boundary is available to catch and rendering something safely
    // oh - and - maybe you should have monitoring on your webapps too?
    throw new Error("authContext is not available");
  }

  return ctx;
}