import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'; // Assuming React Router for navigation
import { SupabaseClient } from '@supabase/supabase-js';
import { ErrorMessage } from '../../common/library/ErrorMessage';
import SixDigitInput from '../../common/library/SixDigitInput';
import Button from '../../common/library/Button';
import { InfoMessage } from '../../common/library/InfoMessage';

interface SignUpCompleteProps {
  supabaseClient: SupabaseClient;
}

export default function VerifyEmail({ supabaseClient }: SignUpCompleteProps) {
  const [params] = useSearchParams();
  const email = params.get('email');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const navigate = useNavigate(); // React Router's hook for navigation
  const [completeEnabled, setCompleteEnabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [resending, setResending] = useState<boolean>(false);

  const onPressVerify = async () => {
    if (email) {
      try {
        setVerifying(true);
        const { error } = await supabaseClient.auth.verifyOtp({
          email,
          token: verificationCode,
          type: 'email',
        });
        if (error) {
          console.error('Error verifying code:', error);
          setErrorMessage([error.message]);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying code:', error);
      } finally {
        setVerifying(false);
      }
    }
  };

  const onResendVerification = async () => {
    if (email) {
      try {
        setResending(true);
        const { error } = await supabaseClient.auth.resend({
          email,
          type: 'signup',
        });
        if (error) {
          console.error('Error resending code:', error);
          setErrorMessage([error.message]);
        } else {
          console.info('Another confirmation email has been sent');
        }
      } catch (error) {
        console.error('Error resending code:', error);
        console.error('An error occurred while resending the code.');
      } finally {
        setResending(false);
      }
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col sm:justify-center items-center bg-gray-100">
      <div className="relative sm:max-w-md w-full align-middle">
        <div
          className="relative w-full sm:h-full h-screen rounded-3xl px-2 sm:px-6 py-4 bg-white shadow-md m-auto flex sm:justify-center items-center">
          <div className="w-[500px] m-auto p-3">
            {email && (
              <InfoMessage
                message={[
                  `An email was sent to ${email} with a six digit code to verify your account.`,
                ]}
              />
            )}
            <div className="m-auto w-full">
              <label className="block mt-3 text-lg text-gray-700 text-center font-semibold">
                Verify Email
              </label>
              <div style={{ padding: '24px', backgroundColor: 'white' }}>
                <div className="py-2 text-red-500">
                  <ErrorMessage message={errorMessage} />
                </div>
                <div style={{ padding: '8px' }}>
                  <SixDigitInput
                    onComplete={(val) => {
                      setCompleteEnabled(true);
                      setVerificationCode(val);
                    }}
                    onNotComplete={() => {
                      setCompleteEnabled(false);
                    }}
                  />
                </div>
                <div style={{ padding: '8px', paddingTop: '16px' }}>
                  <Button
                    onClick={onPressVerify}
                    disabled={!completeEnabled}
                    label="Confirm"
                    buttonWidth="full"
                    loading={verifying}
                  />
                </div>
                <div style={{ padding: '8px', paddingTop: '16px' }}>
                  <Button
                    onClick={onResendVerification}
                    variant="outline"
                    label="Resend Verification Code"
                    buttonWidth="full"
                    loading={resending}
                  />
                </div>
                <div style={{ padding: '8px', paddingTop: '16px' }}>
                  <Button
                    onClick={() => navigate('/login')}
                    variant="outline"
                    label="Back To Login"
                    buttonWidth="full"
                    loading={resending}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
