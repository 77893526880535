import { Combobox as CBox } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { ReactNode, useMemo, useState } from 'react';
import HelpTip from './HelpTip/HelpTip';

export interface ComboxboxProps<T> {
  label?: string;
  value: T | null | undefined;
  options: T[];
  displayKey?: keyof T;
  idKey?: keyof T;
  className?: string;
  onChange: (value: T) => void;
  helpTip?: string;
  relative?: boolean;
  icon?: ReactNode;
  required?: boolean;
}

export default function Combobox<T>(props: ComboxboxProps<T>) {
  const {
    value,
    options,
    displayKey,
    idKey,
    className,
    onChange,
    label,
    helpTip,
    relative,
    icon,
    required,
  } = props;
  const [query, setQuery] = useState('');

  const filteredObjects = useMemo(() => {
    return query === ''
      ? options
      : options.filter((option: T) =>
        (displayKey ? (option[displayKey] as any) : option)
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, '')),
      );
  }, [options, query, displayKey]);

  return (
    <CBox value={value} onChange={onChange}>
      <div className="relative">
        <div
          className="focus:outline-none relative w-full cursor-default rounded-md text-left focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          {label && (
            <div className="flex">
              {icon && (
                <>
                  <div className="p-[2px]">{icon}</div>
                </>
              )}
              <label
                htmlFor={label}
                className={classNames('block mb-1 text-sm pl-1 font-bold', required ? 'text-[#fb3310]' : 'text-gray-900')}
              >
                {required && '*'} {label}
              </label>
              {helpTip && (
                <span>
                  <HelpTip message={helpTip} />
                </span>
              )}
            </div>
          )}
          <CBox.Input
            id={label}
            className={classNames(
              'w-full h-10 px-4 py-0 text-sm rounded-md border bg-white border-gray-300 text-gray-800 focus:outline-none',
              className,
            )}
            displayValue={(obj: any) => {
              return displayKey ? obj[displayKey] : obj;
            }}
            onChange={(event) => setQuery(event.target.value)}
          />
          <CBox.Button
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pr-2',
              label && 'top-7',
            )}
          >
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pb-1">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </CBox.Button>
        </div>
        <CBox.Options
          className={classNames(
            '-translate-6 z-50 focus:outline-none mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm',
            relative ? 'relative' : 'absolute',
          )}
        >
          {filteredObjects.length === 0 && query !== '' ? (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
              Nothing found.
            </div>
          ) : (
            filteredObjects.map((obj: any) => (
              <CBox.Option
                key={idKey ? obj[idKey] : obj}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-[#fb3310] text-white' : 'text-gray-900'
                  }`
                }
                value={obj}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {displayKey ? obj[displayKey] : obj}
                    </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                          active ? 'text-white' : 'text-teal-600'
                        }`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </CBox.Option>
            ))
          )}
        </CBox.Options>
      </div>
    </CBox>
  );
}
