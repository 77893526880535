import { ExclamationCircleIcon } from '@heroicons/react/solid';


export interface Props {
  message: string;
}

export default function InfoBox({message}: Props) {
  return (
    <>
      <div className="bg-blue-50 p-6 rounded-lg m-auto">
        <div className="text-blue-700 flex md:w-[100%]">
          <div className="w-[50px] pr-6 m-auto">
            <ExclamationCircleIcon height={32} width={32} />
          </div>
          <div className="m-auto">
            {message}
          </div>
        </div>
      </div>
    </>
  )
}