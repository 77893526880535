import { useState } from 'react';
import { LogoutIcon, MailIcon, OfficeBuildingIcon, UserIcon } from '@heroicons/react/outline';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { BeakerIcon } from '@heroicons/react/solid';
import useAuthContext from '../../hooks/auth-context.hook';
import { Menu, MenuItem } from '@mui/material';  // Import the Licenses component

export function Home() {
  const auth = useAuthContext();
  const { user } = auth.getUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="w-full bg-white">
        <div className="sticky top-0 w-full z-10 bg-white border-b-gray-200 border-b-[1px]">
          <div className="w-full flex flex-col md:flex-row justify-between items-center pb-4 px-4">

            {/* Image centered on small screens, left-aligned on medium and above */}
            <div className="mt-4 md:mt-0 md:order-2 hidden md:block">
              <img
                className="m-auto text-center font-bold h-[40px] md:h-[60px]"
                src={require('../../assets/qbon_long_logo.png')}
                alt="qbon-logo-dashboard"
              />
            </div>

            {/* Buttons, side by side and spaced evenly */}
            <div className="mt-4 w-full md:w-auto flex justify-evenly md:order-3">
              <div className={'px-1'}>
                <>
                  <div className={'flex hover:bg-zinc-100 cursor-pointer rounded-full border-[1px] border-zinc-300 p-2'} onClick={handleClick}>
                    <div className="m-auto pr-2 font-bold text-gray-700 text-sm">
                      {`${user.email}`}
                    </div>
                    <div>
                      <UserIcon height={20} width={20} />
                    </div>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{}}
                  >
                    {user.type === 'SUPER_ADMIN' && <MenuItem onClick={() => navigate('/admin/users')}>
                      <div className={'flex m-auto items-center'}>
                        <div className={'pr-2'}>
                          <BeakerIcon height={14} width={14} />
                        </div>
                        <div>
                          Admin Dashboard
                        </div>
                      </div>
                    </MenuItem>}
                    {!location.pathname.includes('invites') && <MenuItem onClick={() => navigate('/home/invites')}>
                      <div className={'flex m-auto items-center'}>
                        <div className={'pr-2'}>
                          <MailIcon height={14} width={14} />
                        </div>
                        <div>
                          My Invites
                        </div>
                      </div>
                    </MenuItem>}
                    {!location.pathname.includes('merchants') && <MenuItem onClick={() => navigate('/home/merchants')}>
                      <div className={'flex m-auto items-center'}>
                        <div className={'pr-2'}>
                          <OfficeBuildingIcon height={14} width={14} />
                        </div>
                        <div>
                          My Merchants
                        </div>
                      </div>
                    </MenuItem>}
                    <MenuItem onClick={() =>
                      auth.logOut()
                    }>
                      <div className={'flex m-auto items-center'}>
                        <div className={'pr-2'}>
                          <LogoutIcon height={14} width={14} />
                        </div>
                        <div>
                          Log Out
                        </div>
                      </div>
                    </MenuItem>
                  </Menu>
                </>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-4 pt-2">
          <Outlet />
        </div>
      </div>
    </>
  );
}
