import { Combobox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";

export interface AutocompleteBoxProps<T> {
  options: T[];
  labelKey?: keyof T;
  format?: (value: T) => string;
  idKey: keyof T;
  value: T | undefined;
  label?: string;
  onSelected: (value: T | undefined) => void;
  onInputChange: (value: string) => void;
  disabled?: boolean;
}

export default function AutocompleteBox<T>(props: AutocompleteBoxProps<T>) {
  const {
    options,
    labelKey,
    idKey,
    value,
    label,
    onSelected,
    format,
    onInputChange,
    disabled,
  } = props;

  return (
    <div className="w-full top-16">
      {label && (
        <div className="flex">
          <label
            htmlFor={label}
            className={classNames(
              "block text-sm font-bold",
              disabled ? "text-gray-400" : "text-gray-900"
            )}
          >
            {label}
          </label>
        </div>
      )}
      <Combobox disabled={disabled} value={value} onChange={onSelected}>
        <div className="relative mt-1">
          <div className="relative w-full text-left bg-white cursor-default outline-gray-300 rounded-md sm:text-sm overflow-hidden">
            <Combobox.Input
              className="w-full h-10 px-4 py-0 text-sm rounded-md border bg-white border-gray-300 text-gray-800"
              displayValue={(selected: T | undefined) => {
                if (value !== undefined && format) {
                  return format(value);
                }
                if (value !== undefined && labelKey !== undefined) {
                  return (value as any)[labelKey];
                }
                return "";
              }}
              onChange={(event) => onInputChange(event.target.value as string)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>

          <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-xl max-h-60 sm:text-sm z-40">
            {options.length === 0 ? (
              <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              options.map((option: T) => (
                <Combobox.Option
                  key={(option as any)[idKey]}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 ${
                      active ? "text-white bg-red-600" : "text-gray-900"
                    }`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {format !== undefined
                          ? format(option)
                          : (option as any)[labelKey]}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-teal-600"
                          }`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}
