import { Merchant } from '../../../models/merchant';
import Input from '../../../common/library/Input';
import AddCustomLinkButton from '../../../common/library/AddCustomLinkButton';
import useMerchants from '../../../hooks/merchants.hook';
import useMerchantCustomLinks from '../../../hooks/merchants-custom-links.hook';
import Button from '../../../common/library/Button';
import WarningBox from '../../../common/WarningBox';
import { toast } from 'react-toastify';

interface Props {
  merchant: Merchant;
  onComplete: () => void;
}

export default function CustomLinksTutorial({ merchant, onComplete }: Props) {
  const {
    useMerchantQuery,
  } = useMerchants();
  const { data: editingMerchant, refetch } = useMerchantQuery(merchant.id);
  const { useCreateCustomLinkMutation, useDeleteCustomLinkMutation } = useMerchantCustomLinks();
  const createCustomLink = useCreateCustomLinkMutation(merchant.id, () => {
    toast('Custom Link created', { type: 'success' });
    refetch();
  }, (error) => toast(error.message[0], { type: 'error' }));
  const deleteCustomLink = useDeleteCustomLinkMutation(merchant.id, () => {
    toast('Custom Link deleted', { type: 'info' });
    refetch();
  }, (error) => toast(error.message[0], { type: 'error' }));

  return (
    <>
      <WarningBox
        message={'Custom links are links to social media accounts, websites, menus, and other links that were not natively covered on the Qbon Merchant Signup. For example, maybe you would like to add a Drink Menu link your customers can access on your Merchant Store Front (The personalized landing page customers get to when they tap your plate or scan your QR Code.'} />
      <div className="pt-6">
        <h1 className="font-bold ">Custom Links</h1>
      </div>
      {editingMerchant && editingMerchant.customLinks && editingMerchant.customLinks.length > 0 && (
        <>
          {editingMerchant.customLinks.map((link, index) => (
            <div className="py-2">
              <Input
                key={index}
                value={link.url}
                label={link.label}
                deletable
                onDelete={() => deleteCustomLink.mutate(link.id)}
              />
            </div>
          ))}
        </>
      )}
      <div className={'pt-6'}>
        <AddCustomLinkButton
          onCreate={(label, url) => createCustomLink.mutate({ label, url })}
        />
      </div>
      <div className={'pt-6'}>
        <Button buttonWidth={'full'} label={'Continue'} onClick={onComplete} />
      </div>
    </>
  );
}