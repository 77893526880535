import { MetricView } from '../../common/metrics/MetricView';
import React from 'react';

export function SystemMetrics() {
  return (
    <>
      <div className="md:flex md:justify-between">
        <div className=" w-full">
          <MetricView isSystemMetrics={true} defaultMetricType={'USER_CREATED'} primaryColor={'#5DA370'} />
        </div>
      </div>
    </>
  )
}