import useAuthContext from '../../../hooks/auth-context.hook';
import useMerchantTutorial from '../../../hooks/merchant-tutorial.hook';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Spinner from '../../../common/library/Spinner';
import { LogoAndCoverTutorial } from './LogoAndCoverTutorial';
import CustomLinksTutorial from './CustomLinksTutorial';
import AddDevicesTutorial from './AddDevicesTutorial';
import SubscribeTutorial from './SubscribeTutorial';
import GeofenceTutorial from './GeofenceTutorial';
import CreateQbonTutorial from './CreateQbonTutorial';
import useMerchants from '../../../hooks/merchants.hook';
import Button from '../../../common/library/Button';

export default function TutorialPage() {
  const navigate = useNavigate();
  const auth = useAuthContext();
  const { user, merchant: contextMerchant } = auth.getMerchantAndUser();
  const { useMerchantQuery } = useMerchants();
  const { data: merchant } = useMerchantQuery(contextMerchant.id);
  const { useUpdateMerchantTutorialMutation, useMerchantTutorialQuery } = useMerchantTutorial();
  const { data: tutorialState, isLoading, isError, refetch } = useMerchantTutorialQuery(contextMerchant.id);
  const { mutate: updateMerchantTutorial } = useUpdateMerchantTutorialMutation(contextMerchant.id, () => {
    refetch();
  });

  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    if (isError) {
      navigate('dashboard/metrics');
    }

    if (!isLoading && tutorialState) {
      setStep(tutorialState.step);
    }

    if (tutorialState && tutorialState.step === 5 && merchant && merchant.status === 'UNSUBSCRIBED') {
      console.log(tutorialState);
      console.log(merchant);
      updateMerchantTutorial({ step: 4 });
      setStep(4);
    }

    if (tutorialState && tutorialState.completed) {
      navigate('/dashboard/profile');
    }

    if (step > 6) {
      updateMerchantTutorial({ completed: true });
    }

  }, [isError, tutorialState, isLoading, merchant, navigate, updateMerchantTutorial, step]);

  return (
    <>
      <div className={'p-3 w-full lg:w-[550px] lg:m-auto'}>
        {
          isLoading ? (<>
            <Spinner width={32} height={32} color={'red'} />
          </>) : (
            <>
              {merchant && <>
                {
                  step === 1 && <LogoAndCoverTutorial user={user} merchant={merchant}
                                                      onComplete={() => updateMerchantTutorial({
                                                        completed: false,
                                                        step: 2,
                                                      })} />
                }
                {
                  step === 2 && <><CustomLinksTutorial merchant={merchant} onComplete={() => updateMerchantTutorial({
                    completed: false,
                    step: 3,
                  })} /></>
                }
                {
                  step === 3 && <><AddDevicesTutorial onComplete={() => updateMerchantTutorial({
                    completed: false,
                    step: 4,
                  })} /></>
                }
                {
                  step === 4 && <><SubscribeTutorial merchant={merchant} user={user}
                                                     onComplete={(acceptedSubscription) => updateMerchantTutorial({
                                                       completed: !acceptedSubscription,
                                                       step: 5,
                                                       acceptedSubscription,
                                                     })} /></>
                }
                {
                  step === 5 && <><GeofenceTutorial merchant={merchant} user={user}
                                                    onComplete={() => updateMerchantTutorial({
                                                      completed: false,
                                                      step: 6,
                                                    })} /></>
                }
                {
                  step === 6 && <><CreateQbonTutorial merchant={merchant} user={user}
                                                      onComplete={() => updateMerchantTutorial({
                                                        completed: true,
                                                      })} /></>
                }
              </>}
            </>
          )
        }
        <div className={'py-3'}>
          <Button label={'Exit to Change Merchant'} buttonWidth={'full'} onClick={() => auth.exitMerchant()}
                  variant={'outline'} />
        </div>
      </div>
    </>
  );
}