import { useEffect, useState } from 'react';
import Input from '../../common/library/Input';
import useLicenses from '../../hooks/licenses.hook';
import LicenseCard from '../../common/library/LicenseCard';
import Spinner from '../../common/library/Spinner';
import CreateLicenseModal from '../home/CreateLicenseModal';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { LicenseType } from '../../models/license';
import useAuthContext from '../../hooks/auth-context.hook';

export default function Licenses() {
  const authContext = useAuthContext();
  const user = authContext.getUser();
  const [searchInput, setSearchInput] = useState<string>('');
  const [query, setQuery] = useState<{ contains?: string; }>({ contains: undefined });
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);

  const { useLicensesQuery } = useLicenses();
  const { data: licenses, refetch, isLoading } = useLicensesQuery(query.contains, query.contains);

  useEffect(() => {
    refetch();
  }, [query.contains, refetch]);

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        console.log('Fetching license types from http://localhost:3000/api/v1/license-types');
        const response = await fetch('http://localhost:3000/api/v1/license-types');
        console.log('Response status:', response.status);
        const data = await response.json();
        console.log('Fetched license types:', data);
        setLicenseTypes(data);
      } catch (error) {
        console.error('Error fetching license types:', error);
      }
    };

    fetchLicenseTypes();
  }, []);

  return (
    <>
      <div>
        <CreateLicenseModal
          open={createOpen}
          user={user.user} 
          onClose={() => setCreateOpen(false)}
          onCreateComplete={() => {
            setCreateOpen(false);
            refetch();
          }}
          licenseTypes={licenseTypes}
        />
        <div className="w-full pb-4">
          <Input
            label="Search Licenses"
            placeholder="Search: License Code or State. Press Enter to Search"
            value={searchInput}
            onChange={(value) => setSearchInput(value)}
            onEnter={() => {
              setQuery({
                ...query,
                contains: searchInput === '' ? undefined : searchInput,
              });
            }}
          />
        </div>
        {!isLoading ? (
          <>
            <div className="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-4">
              <div onClick={() => setCreateOpen(true)} style={{
                margin: 8,
                width: '95%',
                alignSelf: 'center',
                overflow: 'hidden',
                borderWidth: 1,
                borderRadius: 18,
                paddingBottom: 8,
                borderColor: '#dddddd',
                backgroundColor: 'white',
              }}
                   className="shadow-md hover:scale-105 transition-transform duration-300 ease-in-out hover:cursor-pointer">
                <PlusCircleIcon height={128} width={128} className={'m-auto pt-6'} color={'#bbbbbb'} />
                <div className={'font-bold text-center m-4'}>
                  Create a License
                </div>
              </div>
              {licenses.map((license) => (
                <LicenseCard license={license} key={license.id} />
              ))}
            </div>
          </>
        ) : (
          <Spinner height={4} width={4} color="red" />
        )}
      </div>
    </>
  );
}
