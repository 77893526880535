import { MenuIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { createContext, useState } from 'react';
import Button from './library/Button';
import MenuItem, { MenuItemProps } from './MenuItem';

export interface DashboardProps {
  menuItems: MenuItemProps[];
  logo: React.ReactNode;
  children: React.ReactNode;
  barItems?: React.ReactNode;

}

export const DashboardContext = createContext<
  | {
  setHideSidebar: any;
}
  | undefined
>(undefined);

export default function Dashboard(props: DashboardProps) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [hideSidebar, setHideSidebar] = useState<boolean>(false);
  const { menuItems, logo, children, barItems } = props;

  return (
    <>
      <div>
        <div className="flex h-screen bg-zinc-800">
          {!hideSidebar && (
            <div
              className={classNames(
                'fixed z-30 inset-y-0 left-0 transform bg-white overflow-y-auto lg:static lg:inset-0 hidden lg:block w-[280px] border-r-[1px] border-[#dddddd]',
              )}
            >
              <div className="flex items-center justify-center text-center pl-6">
                {logo}
              </div>

              <nav className="pl-6 pr-3">
                {menuItems.map((menuItem, index) => (
                  <div key={index}>
                    <MenuItem
                      label={menuItem.label}
                      picked={menuItem.picked}
                      href={menuItem.href}
                      icon={menuItem.icon}
                      subItems={menuItem.subItems}
                      openSubItems={menuItem.openSubItems}
                      onClick={menuItem.onClick}
                      loading={menuItem.loading}
                      statusIcon={menuItem.statusIcon}
                    />
                  </div>
                ))}
              </nav>
              <div className="w-full"></div>
            </div>
          )}
          <div
            className={classNames(
              'transform transition-all duration-300 fixed z-30 inset-y-0 left-0 bg-white overflow-y-auto lg:static lg:inset-0 lg:hidden',
              sidebarOpen
                ? 'translate-x-0 block w-full'
                : '-translate-x-full w-0',
            )}
          >
            <div className="flex justify-between mt-6 items-center">
              <div></div>
              <div>{logo}</div>
              <div className="cursor-pointer">
                {' '}
                <div onClick={() => setSidebarOpen(!sidebarOpen)}>
                  <ChevronLeftIcon className="h-8 w-8 mt-1 text-black" />
                </div>
              </div>
            </div>
            {menuItems.map((menuItem, index) => (
              <div key={index}>
                <MenuItem
                  label={menuItem.label}
                  picked={menuItem.picked}
                  href={menuItem.href}
                  icon={menuItem.icon}
                  subItems={menuItem.subItems}
                  openSubItems={menuItem.openSubItems}
                  onClick={() => {
                    if (menuItem.onClick) {
                      menuItem.onClick();
                    }
                    setSidebarOpen(false);
                    return;
                  }}
                  loading={menuItem.loading}
                  statusIcon={menuItem.statusIcon}
                />
              </div>
            ))}
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <header
              className="flex justify-between items-center py-4 px-2 md:px-6 bg-white">
              <div className="pr-2">
                <div className={'lg:hidden'}>
                  <Button
                    label="Menu"
                    alwaysShowLabel
                    icon={<MenuIcon />}
                    variant="free"
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                  />
                </div>
              </div>
              <div className="pr-2">
                {barItems}
              </div>
            </header>
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white px-2 lg:px-[120px]">
              <DashboardContext.Provider value={{ setHideSidebar }}>
                {children}
              </DashboardContext.Provider>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
