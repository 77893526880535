import { vars } from "../env/env";

export interface ErrorResponse {
  message: string;
  error?: string;
  status: string;
  statusCode: number;
}

function executeFetch(
  url: string,
  accessToken?: string,
  options?: RequestInit,
  withContentType: boolean = true
) {
  return fetch(vars.api.baseUrl + url, {
    ...options,
    headers: new Headers({
      ...(withContentType ? { "Content-Type": "application/json" } : {}),
      ...(accessToken && { authorization: `${accessToken}` }),
    }),
  });
}

export async function executePlainFetch(
  url: string,
  accessToken?: string,
  options?: RequestInit,
  withContentType: boolean = true,
): Promise<any> {
  const response = await executeFetch(url, accessToken, options, withContentType);
  if (response.status >= 400) {
    return response.json().then((body) => {
      if (!body) {
        return Promise.reject({
          message: ["An error occurred with the server!"],
          status: 500
        })
      }
      if (response.status >= 500) {
        return Promise.reject({
          message: ["An error occurred with the server!"],
          status: 500
        })
      }
      return Promise.reject({
        message: body.message || body.error,
        status: body.status,
        statusCode: response.status,
      });
    });
  }
  try {
    const body_1 = await response
      .json();
    return await Promise.resolve(body_1);
  } catch (error) {
    return await Promise.resolve();
  }
}

export async function fetchJson<T>(
  url: string,
  accessToken?: string,
  options?: RequestInit,
  withContentType: boolean = true,
): Promise<T> {
  const response = await executeFetch(url, accessToken, options, withContentType);
  if (response.status >= 400) {
    return response.json().then((body) => {
      if (!body) {
        return Promise.reject({
          message: ["An error occurred with the server!"],
          status: 500
        })
      }
      if (response.status >= 500) {
        return Promise.reject({
          message: ["An error occurred with the server!"],
          status: 500
        })
      }
      return Promise.reject({
        message: body.message || body.error,
        status: body.status,
        statusCode: response.status,
      });
    });
  }
  const body_1 = await response.json();

  return await Promise.resolve(body_1 as T);
}
