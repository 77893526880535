import React from 'react';
import { useNavigate } from 'react-router';
import { useRaffles } from '../../../hooks/raffle.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import Table, { TableColumn } from '../../../common/library/Table';
import { Raffle } from '../../../models/raffle';
import Button from '../../../common/library/Button';
import { PlusIcon } from '@heroicons/react/outline';

export default function MerchantRaffles() {
  const auth = useAuthContext();
  const { merchant } = auth.getMerchantAndUser();
  const navigate = useNavigate();

  const { useMerchantRafflesQuery } = useRaffles();
  const { isSuccess, data, isLoading } = useMerchantRafflesQuery(merchant.id);

  // createRaffleMutation.mutate()

  const columns: TableColumn<Raffle>[] = [
    {
      label: 'Name',
      format: (val) => <>{val.name}</>,
    },
    {
      className: 'hidden md:table-cell',
      label: 'Status',
      format: (val) => <>{val.status.name}</>,
    },
    {
      className: 'hidden md:table-cell',
      label: 'Start Date',
      format: (val) => <>{val.startDate && new Date(val.startDate).toDateString()}</>,
    },
    {
      className: 'hidden md:table-cell',
      label: 'End Date',
      format: (val) => <>{val.endDate && new Date(val.endDate).toDateString()}</>,
    },
  ];

  return (
    <>
      <div className="flex justify-between px-2 md:px-0 w-full pb-2">
        <h1 className="font-bold text-xl">Raffles</h1>
        <div className="float-right">
          <Button
            icon={<PlusIcon height={16} width={16} />}
            label="Create Raffle"
            onClick={() => navigate('/dashboard/raffles/create')}
          />
        </div>
      </div>
      <div className="w-full">
        <Table
          loading={isLoading}
          columns={columns}
          data={isSuccess ? data : []}
          onRowClick={(row) => navigate(`/dashboard/raffles/${row.id}`)}
        />
      </div>
    </>
  );
}



