import { useEffect, useState } from 'react';
import {
  Merchant,
  MerchantRequestDTO,
} from '../../../models/merchant';
import Spinner from '../../../common/library/Spinner';
import {
  feetToMetersTextToInt,
  metersToText,
} from '../../../util';
import { GeoLocation } from '../../../hooks/google-maps.hook';
import {
  SaveIcon,
} from '@heroicons/react/outline';
import AutocompleteAddress from '../../../common/AutocompleteAddress';
import Input from '../../../common/library/Input';
import Button from '../../../common/library/Button';
import Combobox from '../../../common/library/Combobox';
import Toggle from '../../../common/library/Toggle';
import useAuthContext from '../../../hooks/auth-context.hook';
import useMerchants from '../../../hooks/merchants.hook';
import { ErrorMessage } from '../../../common/library/ErrorMessage';
import { toast } from 'react-toastify';

export default function MerchantStoreAndAddressSettings() {
  const auth = useAuthContext();
  const { merchant: activeMerchant } = auth.getMerchantAndUser();
  const {
    useUpdateMerchantMutation,
    useMerchantQuery,
    useUpdateStoreFrontSettingsMutation,
  } = useMerchants();
  const { data: merchant, refetch: refetchMerchant } = useMerchantQuery(activeMerchant.id, activeMerchant);
  const updateMerchant = useUpdateMerchantMutation(activeMerchant.id, (res) => {
    refetchMerchant();
    auth.updateMerchant(res);
    toast.success('Address updated');
  }, (error) => {
    setErrorMessage(error.message);
    toast.error('Failed to update address');
  });
  const updateStoreFrontSettings = useUpdateStoreFrontSettingsMutation(activeMerchant.id, (res) => {
    refetchMerchant();
  }, (error) => {
    setErrorMessage(error.message);
  });

  const [editingMerchant, setEditingMerchant] = useState<Merchant | undefined>(merchant);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [merchantAddress, setMerchantAddress] = useState<GeoLocation>({
    placeId: '',
    addressline: merchant?.address ? merchant.address.addressLine1 : '',
    city: merchant?.address ? merchant.address.city : '',
    coordinates: {
      latitude: merchant?.coordinates
        ? merchant.coordinates?.coordinates[0]
        : 0,
      longitude: merchant?.coordinates
        ? merchant.coordinates.coordinates[1]
        : 0,
    },
    country: merchant?.address ? merchant.address.country : '',
    county: merchant?.address ? merchant.address.county : '',
    postal_code: merchant?.address ? merchant.address.postalCode : '',
    state: merchant?.address ? merchant.address.state : '',
  });

  useEffect(() => {
    merchant && auth.updateMerchant(merchant);
    merchant && setEditingMerchant(merchant);
  }, [merchant, auth]);

  return (
    <>
      {editingMerchant ? (
        <div className="w-full lg:w-[550px]">
          <div>
            <ErrorMessage message={errorMessage} />
            <div className="py-3">
              <h1 className="font-bold ">Store Front</h1>
            </div>
            <div className="py-3 pt-6">
              <div className="text-sm">
                Enable QR-less arrivals? (Users can go to your store front
                when at your store by checking your profile)
              </div>
              <div className="pt-3">
                <Toggle
                  enabled={
                    editingMerchant.storeFrontSettings?.enableArriveWithLocation
                      ? editingMerchant.storeFrontSettings.enableArriveWithLocation
                      : false
                  }
                  onChange={(val) => {
                    if (editingMerchant.storeFrontSettings) {
                      updateStoreFrontSettings.mutate({
                        ...editingMerchant.storeFrontSettings,
                        enableArriveWithLocation: val,
                      });
                    } else {
                      updateStoreFrontSettings.mutate({
                        enableArriveWithLocation: val,
                        arriveAtDistanceMeters: 10,
                        enableVerifyQRScanLocation: false,
                        verifyQRScanDistanceMeters: 0,
                      });
                    }
                  }}
                />
              </div>
            </div>
            {editingMerchant.storeFrontSettings?.enableArriveWithLocation && (
              <div className="py-3 pb-12">
                <Combobox
                  label="Maximum distance to allow arrival from your address map marker"
                  options={[
                    '15 Meters, Approximately 50 Feet',
                    '30 Meters, Approximately 100 Feet',
                    '60 Meters, Approximately 200 Feet',
                    '150 Meters, Approximately 500 Feet',
                    '230 Meters, Approximately 750 Feet',
                    '300 Meters, Approximately 1000 Feet',
                  ]}
                  value={metersToText(
                    editingMerchant.storeFrontSettings?.arriveAtDistanceMeters,
                  )}
                  onChange={(val: string) => {
                    if (editingMerchant.storeFrontSettings) {
                      updateStoreFrontSettings.mutate({
                        ...editingMerchant.storeFrontSettings,
                        arriveAtDistanceMeters: feetToMetersTextToInt(val),
                      });
                    } else {
                      updateStoreFrontSettings.mutate({
                        enableArriveWithLocation: true,
                        arriveAtDistanceMeters: feetToMetersTextToInt(val),
                        enableVerifyQRScanLocation: false,
                        verifyQRScanDistanceMeters: 0,
                      });
                    }
                  }}
                />
              </div>
            )}
            <div className="py-3">
              <div className="text-sm">
                Enable Verify QR Scan Location? (Users must be a certain
                distance from your locations map marker for QR codes to work)
              </div>
              <div className="pt-3">
                <Toggle
                  enabled={
                    editingMerchant.storeFrontSettings?.enableVerifyQRScanLocation
                      ? editingMerchant.storeFrontSettings.enableVerifyQRScanLocation
                      : false
                  }
                  onChange={(val) => {
                    if (editingMerchant.storeFrontSettings) {
                      updateStoreFrontSettings.mutate({
                        ...editingMerchant.storeFrontSettings,
                        enableVerifyQRScanLocation: val,
                      });
                    } else {
                      updateStoreFrontSettings.mutate({
                        enableArriveWithLocation: false,
                        arriveAtDistanceMeters: 10,
                        enableVerifyQRScanLocation: val,
                        verifyQRScanDistanceMeters: 0,
                      });
                    }
                  }}
                />
              </div>
            </div>
            {editingMerchant.storeFrontSettings?.enableVerifyQRScanLocation && (
              <div className="py-3 pb-12">
                <Combobox
                  label="Maximum distance to allow QR scan for users"
                  options={[
                    '15 Meters, Approximately 50 Feet',
                    '30 Meters, Approximately 100 Feet',
                    '60 Meters, Approximately 200 Feet',
                    '150 Meters, Approximately 500 Feet',
                    '230 Meters, Approximately 750 Feet',
                    '300 Meters, Approximately 1000 Feet',
                  ]}
                  value={metersToText(
                    editingMerchant.storeFrontSettings?.verifyQRScanDistanceMeters,
                  )}
                  onChange={(val: string) => {
                    if (editingMerchant.storeFrontSettings) {
                      updateStoreFrontSettings.mutate({
                        ...editingMerchant.storeFrontSettings,
                        verifyQRScanDistanceMeters:
                          feetToMetersTextToInt(val),
                      });
                    } else {
                      updateStoreFrontSettings.mutate({
                        enableArriveWithLocation: true,
                        verifyQRScanDistanceMeters:
                          feetToMetersTextToInt(val),
                        enableVerifyQRScanLocation: false,
                        arriveAtDistanceMeters: 0,
                      });
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="py-3">
            <div className="py-3">
              <h1 className="font-bold ">Address</h1>
            </div>
            <div className="py-3">
              <AutocompleteAddress
                onSelected={(address) => {
                  console.log(address);
                  setMerchantAddress(address);
                }}
              />
            </div>
            <div className="w-full p-1.5">
              <iframe
                title="map"
                className="w-full"
                src={`https://maps.google.com/maps?q=${merchantAddress.coordinates.latitude},${merchantAddress.coordinates.longitude}&z=15&output=embed`}
              ></iframe>
            </div>
            <div className="py-3">
              <Input
                value={merchantAddress.addressline}
                label="Address Line"
                onChange={(val) =>
                  setMerchantAddress({ ...merchantAddress, addressline: val })
                }
              />
            </div>
            <div className="py-3">
              <Input
                value={merchantAddress.city}
                label="City"
                onChange={(val) =>
                  setMerchantAddress({ ...merchantAddress, city: val })
                }
              />
            </div>
            <div className="py-3">
              <Input
                value={merchantAddress.county}
                label="County"
                onChange={(val) =>
                  setMerchantAddress({ ...merchantAddress, county: val })
                }
              />
            </div>
            <div className="py-3">
              <Input
                value={merchantAddress.state}
                label="State"
                onChange={(val) =>
                  setMerchantAddress({ ...merchantAddress, state: val })
                }
              />
            </div>
            <div className="py-3">
              <Input
                value={merchantAddress.country}
                label="Country"
                onChange={(val) =>
                  setMerchantAddress({ ...merchantAddress, country: val })
                }
              />
            </div>
            <div className="py-3">
              <Input
                value={merchantAddress.postal_code}
                label="Postal Code"
                onChange={(val) =>
                  setMerchantAddress({ ...merchantAddress, postal_code: val })
                }
              />
            </div>
            <div className="py-3">
              <Button
                label="Save Address"
                alwaysShowLabel
                loading={updateMerchant.status === 'pending'}
                icon={<SaveIcon />}
                variant="danger"
                buttonWidth="full"
                onClick={() => {
                  if (merchantAddress) {
                    const newMerchant: MerchantRequestDTO = {
                      ...editingMerchant,
                      coordinates: merchantAddress.coordinates
                        ? {
                          type: 'Point',
                          coordinates: [
                            merchantAddress.coordinates.latitude,
                            merchantAddress.coordinates.longitude,
                          ],
                        }
                        : undefined,
                      address: {
                        addressLine1: merchantAddress.addressline,
                        city: merchantAddress.city,
                        country: merchantAddress.country,
                        county: merchantAddress.county,
                        postalCode: merchantAddress.postal_code,
                        state: merchantAddress.state,
                        addressLine2: '',
                      },
                    };
                    updateMerchant.mutate({ ...newMerchant });
                  } else {
                    const updates: MerchantRequestDTO = {
                      ...editingMerchant,
                      coordinates: undefined,
                      address: {
                        addressLine1: '',
                        city: '',
                        country: '',
                        county: '',
                        postalCode: '',
                        state: '',
                        addressLine2: '',
                      },
                    };
                    updateMerchant.mutate({ ...updates });
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full">
            <div className="m-auto">
              <Spinner width={8} height={8} color="red" />
            </div>
          </div>
        </>
      )}
    </>
  );
}
