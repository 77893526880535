import { DatabaseIcon } from '@heroicons/react/solid';
import { ChartBarIcon, IdentificationIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import { useContext } from "react";
import { Outlet, useLocation } from "react-router";
import { AuthContext } from "../../common/AuthProvider";
import Dashboard from "../../common/Dashboard";
import PeopleGroup from "../../common/icons/PeopleGroup";
import { MenuItemProps } from "../../common/MenuItem";

export default function AdminDashboard() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const menuItems: MenuItemProps[] = [
    {
      picked: location.pathname.includes("users"),
      label: "Users",
      href: "/admin/users",
      icon: <PeopleGroup />,
    },
    {
      picked: location.pathname.includes("licenses"),
      label: "Licenses",
      href: "/admin/licenses",
      icon: <IdentificationIcon className="h-4 w-4" />,
    },
    {
      picked: location.pathname.includes("devices"),
      label: "Devices",
      href: "/admin/devices",
      icon: <DatabaseIcon className="h-4 w-4" />,
    },
    {
      picked: location.pathname.includes("system-metrics"),
      label: "System Metrics",
      href: "/admin/system-metrics",
      // eslint-disable-next-line react/jsx-no-undef
      icon: <ChartBarIcon className="h-4 w-4" />,
    },
    {
      picked: false,
      label: "Go To Merchants",
      onClick: () => authContext?.exitMerchant(),
      icon: <LocationMarkerIcon className="h-4 w-4" />,
    },
  ];

  const logo = (
    <>
      <div className="flex items-center">
        <span className="text-white text-2xl mx-2 font-semibold">
          Admin Dashboard
        </span>
      </div>
    </>
  );

  return (
    <>
      <Dashboard menuItems={menuItems} logo={logo}>
        <div>
          <div className="md:px-4 pt-2">
            <Outlet />
          </div>
        </div>
      </Dashboard>
    </>
  );
}
