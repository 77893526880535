import { useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../common/library/Button';
import Input from '../../common/library/Input';
import { ErrorMessage } from '../../common/library/ErrorMessage';
import { SupabaseClient } from '@supabase/supabase-js';
import { Modal } from '../../common/library/Modal';

export default function ForgotPassword({
                                         supabase,
                                       }: {
  supabase: SupabaseClient;
}) {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  function changePassword() {
    setLoading(true);
    if (email && email !== '') {
      supabase.auth
        .resetPasswordForEmail(email, {
          redirectTo: `${
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '')
          }/new-password`,
        })
        .then((value) => {
          setOpen(true);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      setErrorMessage(['Please input an email']);
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          navigate('/login');
        }}
      >
        <>
          <span>
            An email was sent with instructions on resetting your password.
          </span>
        </>
      </Modal>
      <div className="relative min-h-screen flex flex-col sm:justify-center items-center bg-gray-100">
        <div className="relative sm:max-w-md w-full align-middle">
          <div
            className="relative w-full sm:h-full h-screen sm:rounded-3xl px-2 sm:px-6 py-6 bg-white shadow-md m-auto flex sm:justify-center items-center">
            <div className="w-[500px] m-auto p-3">
              <div className="m-auto w-full">
                <label className="block mt-3 text-lg text-gray-700 text-center font-semibold">
                  Qbon Forgot Password
                </label>

                <div className="py-2">
                  <ErrorMessage message={errorMessage} />
                </div>
                <div>
                  <Input
                    label="Email"
                    value={email}
                    onChange={(value) => setEmail(value)}
                  />
                </div>

                <div className="mt-7 mb-5">
                  <Button
                    label="Reset Password"
                    buttonWidth="full"
                    onClick={changePassword}
                    loading={loading}
                  />
                </div>
                <div className="mt-3 mb-5">
                  <Button
                    label="Back to Login"
                    buttonWidth="full"
                    variant="secondary"
                    onClick={() => navigate('/login')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
