import React, { useCallback, useState } from "react";
import ImageCropModal from "../ImageCropper";

export default function CoverImageUpload({
  imageUrl,
  onSave,
  saving,
}: {
  imageUrl?: string;
  onSave?: (file: Blob) => void;
  saving: boolean;
}) {
  const [state, setState] = useState<{
    file: Blob | undefined;
    imagePreviewUrl: string | undefined;
    showCropModal: boolean;
  }>({
    file: undefined,
    imagePreviewUrl: imageUrl,
    showCropModal: false,
  });

  const photoUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setState((prevState) => ({
        ...prevState,
        file,
        imagePreviewUrl: url,
        showCropModal: true,
      }));
    }
  }, []);
  const handleCropComplete = useCallback(
    (croppedBlob: Blob | null) => {
      if (croppedBlob) {
        if (onSave) {
          onSave(croppedBlob);
        }
        setState((prevState) => ({
          ...prevState,
          file: croppedBlob,
          imagePreviewUrl: URL.createObjectURL(croppedBlob),
          showCropModal: false,
        }));
      }
    },
    [onSave]
  );
  return (
    <div>
      <label className="w-full h-[300px] hover:opacity-75">
        <img
          className="h-[300px] w-full object-cover object-top rounded-md cursor-pointer"
          alt="upload"
          src={state.imagePreviewUrl || ""}
        />
        <input
          id="photo-upload-cover"
          accept="image/*"
          type="file"
          className="hidden"
          onChange={photoUpload}
        />
      </label>
      {state.showCropModal && state.imagePreviewUrl && (
        <ImageCropModal
          src={state.imagePreviewUrl}
          aspect={[16, 9]}
          onComplete={handleCropComplete}
          onClose={() =>
            setState((prevState) => ({ ...prevState, showCropModal: false }))
          }
        />
      )}
    </div>
  );
}
