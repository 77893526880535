import useContent from '../../../hooks/content.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import { useState } from 'react';
import Pagination from '../../../common/library/Pagination';
import Tabs from '../../../common/library/Tabs';
import Button from '../../../common/library/Button';
import { useSocialHandles } from '../../../hooks/use-socia-handles.hook';
import { Modal } from '../../../common/library/Modal';
import { GetContentPostDto } from '../../../models/content';
import { CheckIcon, DownloadIcon, XIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';

interface Props {
  content: GetContentPostDto;
  showDelete: boolean;
  showUnapprove: boolean;
  onUnapprove: () => void;
  onDelete: () => void;
  onApprove: () => void;
  onDownload: () => void;
}

function ContentCard(props: Props) {
  const { content, showDelete, showUnapprove, onUnapprove, onDelete, onApprove, onDownload } = props;
  const [openViewSocial, setOpenViewSocials] = useState<boolean>(false);

  const { useSocialHandlesMutationQuery } = useSocialHandles();
  const {
    mutate: getSocialHandles,
    data: socialHandles,
    isSuccess: isSuccessSocialHandles,
  } = useSocialHandlesMutationQuery(() => {
  }, () => {
  });

  return (
    <div
      className="relative max-w-full h-full flex flex-col justify-between border-2 border-gray-50 transition-transform duration-500 hover:scale-105">
      <Modal open={openViewSocial && isSuccessSocialHandles} onClose={() => setOpenViewSocials(false)}
             title={'Social Handles for Post'}>
        {
          isSuccessSocialHandles && socialHandles.map(handle => {
            return (
              <>
                <div className={'p-2'}>
                  <div className={'border-gray-200 border-2 rounded-xl p-2'}>
                    <div className={'font-bold'}>
                      {handle.social}
                    </div>
                    <div>
                      {handle.handle}
                    </div>
                  </div>
                </div>
              </>
            );
          })
        }
      </Modal>
      <div className="overflow-hidden">
        <img className="w-full h-full object-cover" src={content.mediaUrl} alt="" />
      </div>
      <div className="p-4 flex flex-col justify-between">
        <h1 className="text-2xl font-bold text-gray-800 cursor-pointer hover:text-gray-900 transition duration-100">
          {content.userContentProfile.firstName} {content.userContentProfile.lastName}
        </h1>
        <p>{content.description}</p>
        <div className="flex justify-between mt-2">
          {showUnapprove ? (
            <span onClick={onUnapprove} className="cursor-pointer">
              <XIcon color="red" height={24} width={24} />
            </span>
          ) : (
            <span onClick={onApprove} className="cursor-pointer">
              <CheckIcon color="green" height={24} width={24} />
            </span>
          )}
          {showDelete && (
            <span onClick={onDelete} className="cursor-pointer">
              <TrashIcon color="red" height={24} width={24} />
            </span>
          )}
          <span className="cursor-pointer" onClick={onDownload}>
            <DownloadIcon color="black" height={24} width={24} />
          </span>
        </div>
        <div className="mt-4">
          <Button
            buttonWidth="full"
            onClick={() => {
              getSocialHandles(content.userContentProfile.userId);
              setOpenViewSocials(true);
            }}
            label="View Social Handles"
          />
        </div>
      </div>
    </div>
  );
}

export default function MerchantContentPage() {
  const authContext = useAuthContext();
  const { merchant } = authContext.getMerchantAndUser();

  const [filter, setFilter] = useState<{
    page: number;
    pageSize: number;
    approvedByMerchant: boolean;
  }>({
    page: 0,
    pageSize: 10,
    approvedByMerchant: true,
  });

  const {
    useMerchantPostsQuery,
    useMerchantPostsAcceptMutation,
    useMerchantPostsDeleteMutation,
    useMerchantPostsDenyMutation,
  } = useContent();
  const { data, isSuccess, refetch } =
    useMerchantPostsQuery(merchant.id, filter.page, filter.pageSize, filter.approvedByMerchant);
  const acceptMutation = useMerchantPostsAcceptMutation(merchant.id, () => {
    refetch();
  });
  const deleteMutation = useMerchantPostsDeleteMutation(merchant.id, () => {
    refetch();
  });
  const denyMutation = useMerchantPostsDenyMutation(merchant.id, () => {
    refetch();
  });

  const Gallery = () => {
    const handleDownload = (url: string) => {
      fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
      })
        .then(response => response.blob())
        .then(blob => {
          console.log(blob);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const urlSplit = url.split('/').pop();
          if (urlSplit) {
            link.download = urlSplit;
            link.click();
          }
        })
        .catch(e => console.error('Download error', e));
    };

    return (
      <div className="container mx-auto p-4">
        <div className="flex flex-wrap justify-between">
          {isSuccess && data.data.map((image, index) => (
            <div key={index} className="p-2 w-full sm:w-1/3">
              <ContentCard
                content={image}
                showDelete={!filter.approvedByMerchant}
                showUnapprove={filter.approvedByMerchant}
                onUnapprove={() => denyMutation.mutate(image.id)}
                onDelete={() => deleteMutation.mutate(image.id)}
                onApprove={() => acceptMutation.mutate(image.id)}
                onDownload={() => handleDownload(image.mediaUrl)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };


  return (
    <>
      <h1 className="font-bold text-xl px-2 md:px-0 pb-2">Content</h1>
      <Tabs
        tabs={[
          {
            category: 'Approved',
            title: 'Approved',
            content: (
              <>
                <Gallery />
              </>
            ),
            onClick: () => {
              setFilter({ page: 0, pageSize: filter.pageSize, approvedByMerchant: true });
            },
          },
          {
            category: 'Waiting Approval',
            title: 'Waiting Approval',
            content: (
              <>
                <Gallery />
              </>
            ),
            onClick: () => setFilter({ page: 0, pageSize: filter.pageSize, approvedByMerchant: false }),
          },
        ]}
      />
      <div className={'pt-2'}>
        {isSuccess && <Pagination
          pageInfo={{
            pageNumber: filter.page + 1,
            pageSize: filter.pageSize,
            totalSize: data.totalSize,
            hasPrevious: data.hasPrevious,
            hasNext: data.hasNext,
          }}
          onChange={(info) =>
            setFilter({
              page: Math.floor(info.pageNumber - 1),
              pageSize: info.pageSize,
              approvedByMerchant: filter.approvedByMerchant,
            })
          }
        />}
      </div>
    </>
  );
}