import { useFetch } from './fetch.hook';
import { License, LicenseRequestDTO } from '../models/license';
import { useMutation, useQuery } from '@tanstack/react-query';

const Routes = {
  licenses: () => `/licenses`,
  license: (licenseId: number) => `/licenses/${licenseId}`,
  licenseSearch: () => `/search/license`,
  userLicense: (userId: number) => `/users/${userId}/license`,
  licenseSubscriptions: (userId: number) => `/users/${userId}/license/subscriptions`,
};

export default function useLicenses() {
  const { fetchJsonFunc, executeFetchFunc } = useFetch();

  const useCreateLicenseMutation = (
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (req: LicenseRequestDTO) => fetchJsonFunc<License>(Routes.licenses(), {
        method: 'POST',
        body: JSON.stringify(req),
      }),
      onSuccess,
      onError,
    });
  };

  const useLicensesQuery = (state?: string, licenseCode?: string) => {
    return useQuery({
      queryKey: ['licenses', state, licenseCode],
      queryFn: () => fetchJsonFunc<License[]>(Routes.licenseSearch(), {
        method: 'POST',
        body: JSON.stringify({ state, licenseCode }),
      }),
      initialData: [],
    });
  };

  const useLicenseQuery = (licenseId: number, initialData?: License) => {
    return useQuery({
      queryKey: ['license', licenseId],
      queryFn: () => fetchJsonFunc<License>(Routes.license(licenseId)),
      initialData,
    });
  };

  const useUserLicenseQuery = (userId: number, initialData?: License) => {
    return useQuery({
      queryKey: ['userLicense', userId],
      queryFn: () => fetchJsonFunc<License>(Routes.userLicense(userId)),
      initialData,
    });
  };

  const useLicenseSubscriptionsQuery = (userId: number) => {
    return useQuery({
      queryKey: ['licenseSubscriptions', userId],
      queryFn: () => fetchJsonFunc<License[]>(Routes.licenseSubscriptions(userId)),
      initialData: [],
    });
  };

  const useUpdateLicenseMutation = (
    licenseId: number,
    onSuccess: (res: License) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (req: LicenseRequestDTO) => fetchJsonFunc<License>(Routes.license(licenseId), {
        method: 'PUT',
        body: JSON.stringify(req),
      }),
      onSuccess,
      onError,
    });
  };

  const useDeleteLicenseMutation = (
    licenseId: number,
    onSuccess: (res: void) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: () => executeFetchFunc(Routes.license(licenseId), {
        method: 'DELETE',
      }),
      onSuccess,
      onError,
    });
  };

  return {
    useCreateLicenseMutation,
    useLicensesQuery,
    useLicenseQuery,
    useUserLicenseQuery,
    useLicenseSubscriptionsQuery,
    useUpdateLicenseMutation,
    useDeleteLicenseMutation,
  };
}
