import { useProviderAuth } from '../hooks/auth.hook';
import { createContext } from 'react';
import Login from '../pages/login/Login';
import { Session, SupabaseClient } from '@supabase/supabase-js';
import { User } from '../models/users';
import { Merchant } from '../models/merchant';
import { Navigate, Route, Routes } from 'react-router';
import SignUp from '../pages/login/SignUp';
import ForgotPassword from '../pages/login/ForgotPassword';
import NewPassword from '../pages/login/NewPassword';
import NewPasswordComplete from '../pages/login/NewPasswordComplete';
import VerifyEmail from '../pages/login/VerifyEmail';
import Spinner from './library/Spinner';

export const AuthContext = createContext<
  | {
  authUser: User | undefined;
  supabaseClient: SupabaseClient | undefined;
  session: Session | null;
  setMerchant: (merchant: Merchant) => void;
  exitMerchant: () => void;
  updateMerchant: (merchant: Merchant) => void;
  merchant: Merchant | undefined;
  loadingSession: boolean;
  refetchUser: () => void;
  logOut: () => Promise<any>;
  getMerchantAndUser: () => { merchant: Merchant, user: User };
  getUser: () => { user: User };
}
  | undefined
>(undefined);

// Wrap your app with <ProvideAuth />
export function AuthProvider({ children }: any) {
  const auth = useProviderAuth();

  if (auth.loadingSession) {
    return (
      <>
        <div className="w-full h-full">
          <div className="m-auto">
            <Spinner height={10} width={10} color="red" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {auth.session !== null ? (
        <>
          <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
        </>
      ) : (
        <>
          <Routes>
            <Route
              path="login"
              element={<Login supabase={auth.supabaseClient} />}
            />
            <Route
              path="signup"
              element={<SignUp supabase={auth.supabaseClient} />}
            />
            <Route
              path="forgot-password"
              element={<ForgotPassword supabase={auth.supabaseClient} />}
            />
            <Route
              path="new-password"
              element={<NewPassword supabase={auth.supabaseClient} />}
            />
            <Route
              path="new-password-complete"
              element={<NewPasswordComplete />}
            />
            <Route
              path="verify"
              element={<VerifyEmail supabaseClient={auth.supabaseClient} />}
            />
            <Route path="*" element={<Navigate to="login" replace />} />
          </Routes>
        </>
      )}
    </>
  );
}