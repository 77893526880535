interface Props {
  message: string[];
}

export function ErrorMessage({ message }: Props) {
  return (
    <>
      {message && message.length > 0 && (
        <div
          role="alert"
          className="rounded border-s-4 border-[#fb3310] bg-red-50 p-4"
        >
          <strong className="block font-medium text-red-800">
            {" "}
            One or more problems found{" "}
          </strong>
          {message.map((m) => {
            return (
              <>
                <p className="mt-2 text-sm text-red-700">*{" "}{m}</p>
              </>
            );
          })}
        </div>
      )}
    </>
  );
}
