import { Page } from '../types';
import { useFetch } from './fetch.hook';
import { User } from '../models/users';
import { useMutation, useQuery } from '@tanstack/react-query';

const Routes = {
  queryUser: (externalId: string) => `/users?externalId=${externalId}`,
  user: (userId: number) => `/users/${userId}`,
};

export function useUsers() {
  const { fetchJsonFunc } = useFetch();

  const useGetSystemUsersQuery = (merchantId: number) => {
    return useQuery({
      queryKey: ['systemUsers', merchantId],
      queryFn: () => fetchJsonFunc<Page<User>>(``),
    });
  };

  const useUserQuery = (externalId: string) => {
    return useQuery({
      queryKey: ['singleUserQuery', externalId],
      queryFn: () => fetchJsonFunc<User>(Routes.queryUser(externalId)),
    });
  };

  const useGetUserQuery = (userId: number) => {
    return useQuery({
      queryKey: ['singleUserQuery', userId],
      queryFn: () => fetchJsonFunc<User>(''),
    });
  };

  const useCreateUserMutation =
    () => {
      return useMutation({
        mutationFn: (req: any) => fetchJsonFunc<User>('', {
          method: 'POST',
          body: JSON.stringify(req),
        }),
      });
    };

  const useUpdateUserMutation =
    (userId: number, onSuccess?: () => void, onError?: () => void) => {
      return useMutation({
        mutationFn: (user: any) => fetchJsonFunc<User>(Routes.user(userId), {
          method: 'PUT',
          body: JSON.stringify(user),
        }),
        onSuccess,
        onError
      });
    };

  const useAddRoleToUserMutation =
    (userId: number) => {
      return useMutation({
        mutationFn: (roleId: number) => fetchJsonFunc<User>('', {
          method: 'PUT',
          body: JSON.stringify({
            roleId,
          }),
        }),
      });
    };

  const useRemoveUserRoleMutation =
    (userId: number) => {
      return useMutation({
        mutationFn: (roleId: number) => fetchJsonFunc<User>('', {
          method: 'DELETE',
          body: JSON.stringify({
            roleId,
          }),
        }),
      });
    };

  const useSearchUsersQuery =
    (email: string) => {
      return useQuery({
        queryKey: ['searchUserEmail', email],
        queryFn: () => fetchJsonFunc<Page<User>>(`/admin/users?email=${email}`)
      });
    };

  return {
    useGetSystemUsersQuery,
    useUserQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useAddRoleToUserMutation,
    useRemoveUserRoleMutation,
    useSearchUsersQuery
  };
}
