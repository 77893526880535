import { Merchant } from '../models/merchant';
import { useEffect, useRef, useState } from 'react';
import { toJpeg } from 'html-to-image';
import Button from './library/Button';
import { DownloadIcon, PrinterIcon } from '@heroicons/react/solid';
import { useReactToPrint } from 'react-to-print';
import classNames from 'classnames';
import Input from './library/Input';

interface Props {
  merchant: Merchant;
}

export default function MerchantQRCodeCard({ merchant }: Props) {
  const divRef = useRef<HTMLDivElement>(null); // Reference to the div you want to download as JPEG
  const qrDivRef = useRef<HTMLImageElement>(null); // Reference to the div you want to download as JPEG
  const printRef = useRef<HTMLDivElement>(null);

  const [onDownload, setOnDownload] = useState<boolean>(false);
  const [onDownloadQR, setOnDownloadQR] = useState<boolean>(false);
  const [onPrint, setOnPrint] = useState<boolean>(false);
  const [text, setText] = useState<string>('');

  const downloadImage = async () => {
    if (divRef.current === null) return;

    try {
      const dataUrl = await toJpeg(divRef.current, { quality: 0.95 });
      // Create a link and set the URL as the href
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'merchant_qr_card.jpeg'; // Name of the downloaded file
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error('Could not download the image', error);
    }
  };

  const downloadQR = async () => {
    if (qrDivRef.current === null) {
      setOnDownloadQR(false)
      return;
    };

    try {
      const dataUrl = await toJpeg(qrDivRef.current, { quality: 1 });
      // Create a link and set the URL as the href
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'merchant_qr_code_only.jpeg'; // Name of the downloaded file
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error('Could not download the image', error);
    } finally {
      setOnDownloadQR(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (onDownload) {
      downloadImage();
      setOnDownload(false);
    }

    if (onDownloadQR) {
      downloadQR();
    }

    if (onPrint) {
      handlePrint();
      setOnPrint(false);
    }
  }, [onDownload, onPrint, handlePrint, onDownloadQR]);

  return (
    <>
      <div className="w-full lg:w-[550px] px-2">
        <div className="flex flex-row">
          <div>
            <Button
              label="Download"
              onClick={() => setOnDownload(true)}
              icon={<DownloadIcon />}
            />
          </div>
          <div className="pl-2">
            <Button
              label="Print"
              onClick={() => setOnPrint(true)}
              icon={<PrinterIcon />}
            />
          </div>
          <div className={'pl-2'}>
            <Button
              label="Download QR Code Only"
              variant={'outline'}
              onClick={() => setOnDownloadQR(true)}
              icon={<DownloadIcon />}
            />
          </div>
        </div>
        <div className="py-2">
          <p>
            This card is a 6" X 4" (1800 pixels X 1200 pixels ). When using
            services to print, make sure to select the proper size.
          </p>
        </div>
        <div className="py-2">
          <Input
            label="Text to add to printable card"
            onChange={(val) => setText(val)}
          />
        </div>

        <div className="w-[200px] h-[750px] pb-64 md:scale-[0.4] scale-[0.2]">
          <div
            className={classNames(
              'w-[1800px] h-[1200px] relative border-2 border-black',
              !onPrint &&
              'md:right-[150px] bottom-[1400px] right-[380px] md:bottom-[500px]',
            )}
            ref={printRef}
          >
            <img
              src={merchant.qrCodeUrl}
              className="w-[500px] h-[500px] absolute top-[200px] right-[240px]"
              alt="qr-code-url-card"
            />
            <div className="absolute top-[750px] right-[140px] text-4xl font-bold text-center w-[700px] text-white">
              <div className="text-center">{text}</div>
            </div>
            <div
              className="w-[900px] h-[200px] absolute top-[620px] rounded-full text-4xl font-bold text-center text-white">
              {merchant.name}
            </div>
            <img
              src={require('../assets/qr_code_logo_card.png')}
              alt="qr_code_logo_card"
            />
          </div>
        </div>

        <div className={classNames(onDownloadQR ? 'block' : 'hidden')}>
          <img ref={qrDivRef}
            src={merchant.qrCodeUrl}
            className="w-[250px] h-[250px]"
            alt="qr-code-url-card"
          />
        </div>

        <div
          className={classNames(
            'w-[1800px] h-[1200px] relative',
            onDownload ? 'block' : 'hidden',
          )}
          ref={divRef}
        >
          <div>
            <img
              src={merchant.qrCodeUrl}
              className="w-[500px] h-[500px] absolute top-[200px] right-[240px]"
              alt="qr-code-url-card"
            />
          </div>
          <div className="absolute top-[750px] right-[140px] text-4xl font-bold text-center w-[700px] text-white">
            <div className="text-center">{text}</div>
          </div>
          <div
            className="w-[900px] h-[200px] absolute top-[620px] rounded-full text-4xl font-bold text-center text-white">
            {merchant.name}
          </div>
          <img
            src={require('../assets/qr_code_logo_card.png')}
            alt="qr_code_logo_card"
          />
        </div>
      </div>
    </>
  );
}
