import Card from '../../../common/library/Card';
import MerchantQRCodeCard from '../../../common/MerchantQRCodeCard';
import useAuthContext from '../../../hooks/auth-context.hook';
import useMerchants from '../../../hooks/merchants.hook';

export default function MerchantQRCode() {
  const { merchant } = useAuthContext().getMerchantAndUser();

  const { useMerchantQuery } = useMerchants();
  const { data, isSuccess } = useMerchantQuery(merchant.id);

  return (
    <>
      <>
        {isSuccess && data.status === 'SUBSCRIBED' ? (
          <>
            <MerchantQRCodeCard merchant={data} />
          </>
        ) : (
          <>
            <Card>
              <div className="text-center">
                Must be subscribed to retrieve your QR code!
              </div>
            </Card>
          </>
        )}
      </>
    </>
  );
}
