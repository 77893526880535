import classNames from "classnames";
import Spinner from "./Spinner";

export interface ButtonProps {
  id?: any;
  ref?: any;
  label?: string;
  onClick?: () => void;
  variant?:
    | "primary"
    | "secondary"
    | "warning"
    | "danger"
    | "outline"
    | "activate"
    | "free";
  icon?: React.ReactNode;
  image?: React.ReactNode;
  className?: string;
  buttonWidth?: "full" | "auto";
  disabled?: boolean;
  loading?: boolean;
  onEnter?: () => void;
  rounded?: boolean;
  alwaysShowLabel?: boolean;
}

export default function Button({
  id,
  ref,
  label,
  onClick,
  variant = "primary",
  icon,
  image,
  className,
  buttonWidth,
  disabled,
  loading,
  onEnter,
  rounded,
  alwaysShowLabel = false
}: ButtonProps) {
  const variants = {
    primary: "bg-[#fb3310] hover:bg-red-700 text-white border-transparent",
    secondary: "bg-grey-600 hover:bg-grey-700 text-black",
    warning: "bg-yellow-500 hover:bg-yellow-700 text-white border-transparent",
    danger: "bg-[#fb3310] hover:bg-red-700 text-white border-transparent",
    activate: "bg-green-600 hover:bg-green-700 text-white border-transparent",
    outline: "border-gray-300 bg-white text-gray-700 hover:bg-gray-50",
    disabled: "border-gray-300 bg-slate-200 text-black",
    free: "border-transparent hover:bg-gray-100"
  };

  let buttonClass = "";

  if (!disabled && !loading) {
    buttonClass = `${
      variants[variant]
    } inline-flex h-10 justify-center border shadow-sm px-4 font-medium focus:outline-none text-sm ${
      rounded ? "rounded-full" : "rounded-md"
    }`;
  } else {
    buttonClass = `${
      variants["disabled"]
    } cursor-not-allowed inline-flex h-10 justify-center border shadow-sm px-4 font-medium focus:outline-none text-sm ${
      rounded ? "rounded-full" : "rounded-md"
    }`;
  }

  return (
    <button
      id={id}
      ref={ref}
      disabled={disabled || loading}
      className={classNames(
        buttonClass,
        className,
        buttonWidth && buttonWidth === "full" ? "w-full" : "w-auto"
      )}
      onClick={onClick}
      onKeyDown={(e) => {
        if (onEnter && e.key === "Enter") {
          onEnter();
        }
      }}
    >
      {!loading && (
        <div className="m-auto flex">
          {icon && <div className={classNames("h-4 w-4 m-auto", label && "mr-1")}>{icon}</div>}
          {image && <div className={classNames("m-auto", label && "mr-1")}>{image}</div>}
          {label && (
            <div className={classNames(!alwaysShowLabel && icon ? "hidden md:block" : "block")}>
              {label}
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className="m-auto">
          <Spinner width={6} height={6} color="red" />
        </div>
      )}
    </button>
  );
}
