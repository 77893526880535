import { useState } from "react";
import Input from "./Input";
import Button from "./Button";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { LinkIcon } from "@heroicons/react/outline";

interface Props {
  onCreate: (label: string, url: string) => void;
}

function AddCustomLinkButton({ onCreate }: Props) {
  const [label, setLabel] = useState("");
  const [url, setUrl] = useState("");
  const [mode, setMode] = useState("button"); // Modes: 'button', 'expand', 'edit'

  const handleClick = () => {
    if (mode === "button") {
      setMode("edit");
    }
  };

  const handleExit = () => {
    setMode("button");
  };

  return (
    <div className="flex justify-center items-center">
      {mode !== "edit" ? (
        <div
          className={`relative flex flex-row items-center justify-center w-8 h-12 rounded-full bg-[#fb3310] text-white cursor-pointer 
                      transition-all duration-300 ease-in-out
                      hover:w-[250px] ${
                        mode === "expand" ? "w-[250px]" : "w-[200px]"
                      }`}
          onClick={handleClick}
        >
          <span className="pr-2">
            <LinkIcon className="h-4 w-4" />
          </span>
          <span className={`${mode !== "expand" ? "block" : "hidden"}`}>
            Add Custom Link
          </span>
        </div>
      ) : (
        <div className="flex space-x-2">
          <Input
            type="text"
            placeholder="Label"
            value={label}
            onChange={(val) => setLabel(val)}
          />
          <Input
            type="text"
            placeholder="URL"
            value={url}
            onChange={(val) => setUrl(val)}
          />
          <Button
            onClick={() => {
              onCreate(label, url);
              setLabel("");
              setUrl("");
              setMode("button");
            }}
            className="text-white p-2 rounded"
            icon={<CheckIcon className="h-4 w-4" />}
          />
          <Button
            onClick={handleExit}
            className="text-white p-2 rounded"
            icon={<XIcon className="h-4 w-4" />}
          />
        </div>
      )}
    </div>
  );
}

export default AddCustomLinkButton;
