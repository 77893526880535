import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Input from "../../common/library/Input";
import Pagination, { PageInfo } from "../../common/library/Pagination";
import useMerchants from "../../hooks/merchants.hook";
import useAuthContext from "../../hooks/auth-context.hook";
import { PageSizing } from "../../types";
import LocationCard from "../../common/MerchantCard";
import Spinner from "../../common/library/Spinner";
import Button from '../../common/library/Button';
import { PlusCircleIcon } from '@heroicons/react/solid';

export default function Merchants() {
  const authContext = useAuthContext();
  const { user } = authContext.getUser();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState<string>("");
  const [query, setQuery] = useState<{
    contains?: string;
  }>({
    contains: undefined,
  });
  const [pageInfo, setPageInfo] = useState<PageSizing>({
    page: 0,
    pageSize: 10,
  });

  const { useUserMerchantsQuery } = useMerchants();
  const {
    data: merchants,
    isLoading,
  } = useUserMerchantsQuery(
    user.id,
    query.contains,
    pageInfo.page,
    pageInfo.pageSize
  );

  const setPage = useCallback(
    (changePage?: number, changePageSize?: number) => {
      const pageQuery = searchParams.get("page");
      const pageSizeQuery = searchParams.get("pageSize");

      const page =
        changePage !== undefined
          ? changePage
          : parseInt(pageQuery ? pageQuery : "0");
      const pageSize =
        changePageSize !== undefined
          ? changePageSize
          : parseInt(pageSizeQuery ? pageSizeQuery : "10");

      navigate({
        search: `?page=${page}&pageSize=${pageSize}`,
      });

      localStorage.setItem("merchantsPage", page.toString());
      localStorage.setItem("merchantsPageSize", pageSize.toString());
    },
    [searchParams, navigate]
  );

  useEffect(() => {
    const pageQuery = searchParams.get("page");
    const pageSizeQuery = searchParams.get("pageSize");

    if (!pageQuery && !pageSizeQuery) {
      setPage(0, 10);
    }

    const page = parseInt(pageQuery ? pageQuery : "0");
    const pageSize = parseInt(pageSizeQuery ? pageSizeQuery : "10");
    setPageInfo({
      page,
      pageSize,
    });
  }, [searchParams, setPage]);

  return (
    <>
      <div className={"w-full flex justify-between"}>
        <div></div>
        <div className={'px-1 w-full'}>
          <div className={'md:block hidden py-2 float-right'}>
            <Button
              onClick={() => navigate('/create-merchant')}
              label="Create Merchant"
              icon={<PlusCircleIcon />}
            />
          </div>
          <div className={'md:hidden py-2'}>
            <Button
              onClick={() => navigate('/create-merchant')}
              label="Create Merchant"
              buttonWidth={"full"}
              icon={<PlusCircleIcon />}
              alwaysShowLabel
            />
          </div>
        </div>
      </div>
      <div className="w-full pb-3">
        <Input
          label="Search My Merchants"
          placeholder="Search: Name, Phone Number, and Email. Press Enter to Search"
          value={searchInput}
          onChange={(value) => {
            setSearchInput(value);
          }}
          onEnter={() => {
            setPage(0);
            setQuery({
              ...query,
              contains: searchInput === "" ? undefined : searchInput,
            });
          }}
        />
      </div>
      {!isLoading ? (
        <>
          <div className="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2">
            {merchants.data.map((merchant) => (
              <LocationCard
                location={merchant}
                onClick={() => authContext.setMerchant(merchant)}
              />
            ))}
          </div>
          <Pagination
            onChange={(info: PageInfo) => {
              const pageNumber = info.pageNumber - 1;
              const pageSize = info.pageSize;
              setPage(pageNumber, pageSize);
            }}
            pageInfo={{
              pageNumber: parseInt(merchants.pageNumber as any) + 1,
              pageSize: parseInt(merchants.pageSize as any),
              totalSize: merchants.totalSize,
              hasPrevious: merchants.hasPrevious,
              hasNext: merchants.hasNext,
            }}
          />
        </>
      ) : (
        <>
          <Spinner height={4} width={4} color="red" />
        </>
      )}
    </>
  );
}
