import { useFetch } from "./fetch.hook";
import { MerchantCustomLink } from '../models/merchant';
import { useMutation } from '@tanstack/react-query';

const Routes = {
  merchantCustomLinks: (merchantId: number) =>
    `/merchants/${merchantId}/custom-links`,
  merchantCustomLink: (
    merchantId: number,
    customLinkId: number
  ) => `/merchants/${merchantId}/custom-links/${customLinkId}`,
}

export default function useMerchantCustomLinks(userId?: number, merchantId?: number) {
  const { fetchJsonFunc, executeFetchFunc } = useFetch();

  const useCreateCustomLinkMutation = (
    merchantId: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ) => {
    return useMutation({
      mutationFn: (request: Partial<MerchantCustomLink>) => fetchJsonFunc<MerchantCustomLink>(
        Routes.merchantCustomLinks(merchantId),
        {
          method: "POST",
          body: JSON.stringify(request),
        }
      ),
      onSuccess,
      onError
    });
  };

  const useDeleteCustomLinkMutation = (
    merchantId: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ) => {
    return useMutation({
      mutationFn: (customLinkId: number) => executeFetchFunc(Routes.merchantCustomLink(
        merchantId,
        customLinkId
      ), {
        method: "DELETE",
      }),
      onSuccess,
      onError
    });
  };

  return {
    useCreateCustomLinkMutation,
    useDeleteCustomLinkMutation
  };
}
