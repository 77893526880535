import { QrScanner } from '@yudiel/react-qr-scanner';
import { useNavigate } from 'react-router';

export default function MerchantScannerPage() {
  const navigate = useNavigate();

  return (
    <>
      <QrScanner
        onResult={(result: any) => {
          if (result) {
            navigate(`/dashboard/scanner/redeem?userCouponId=${result.text.replace("USER_QBON_", "")}`);
          }
        }}
        onError={(error: any) => console.log(error?.message)}
      />
    </>
  );
}
