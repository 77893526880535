import Button from "./Button";

export interface PricingBoxProps {
  loading: boolean;
  title: string;
  price: string;
  rate: string;
  rateDisclosure?: string;
  description: string;
  features: string[];
  onPurchase: () => void;
}

export function PricingBox(props: PricingBoxProps) {
  const {
    loading,
    price,
    rate,
    description,
    features,
    onPurchase,
    title,
    rateDisclosure,
  } = props;
  return (
    <>
      <div className="flex flex-col items-center justify-center mt-16 space-y-8 lg:flex-row lg:items-stretch lg:space-x-8 lg:space-y-0">
        <section className="flex flex-col w-full max-w-sm p-12 space-y-6 bg-white rounded-lg shadow-md">
          <span className="text-3xl font-medium m-auto">{title}</span>
          <div className="flex-shrink-0">
            <span className="text-2xl font-medium pr-2 tracking-tight">
              ${price}
            </span>
            <span
              className="text-gray-400"
              x-text="billPlan == 'monthly' ? '/month' : '/year'"
            >
              / {rate}
            </span>
          </div>
          {rateDisclosure && (
            <div className="flex-shrink-0">
              <span className="text-gray-400">*{rateDisclosure}</span>
            </div>
          )}
          <div className="flex-shrink-0 pb-6 space-y-2 border-b">
            <p className="text-sm text-gray-400" x-text="plan.discretion">
              {description}
            </p>
          </div>
          <ul className="flex-1 space-y-4">
            {features.map((feature) => {
              return (
                <li className="flex items-start">
                  <div className="w-6 h-6">
                    <svg
                      className="w-6 h-6 text-green-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="ml-3 text-base font-medium" x-text="feature">
                    {feature}
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="flex-shrink-0 pt-4">
            <Button label="Subscribe" loading={loading} buttonWidth="full" onClick={onPurchase} />
          </div>
        </section>
      </div>
    </>
  );
}
