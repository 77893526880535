import React, { useEffect, useRef } from "react";
import "./Popover.css";

export interface PopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
  visible: boolean;
  position?: string;
  onOutsideClick: () => void;
}

const Popover = ({visible, children, content, position, onOutsideClick}: PopoverProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, onOutsideClick]);

  return (
    <div
      className="Tooltip-Wrapper"
    >
      {/* Wrapping */}
      {children}
      {visible && (
        <div className={`Tooltip-Tip ${position || "top"}`} ref={divRef}>
          {/* Content */}
          {content}
        </div>
      )}
    </div>
  );
};

export default Popover;