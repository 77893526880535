interface Props {
  message: string[];
}

export function InfoMessage({ message }: Props) {
  return (
    <>
      {message && message.length > 0 && (
        <div
          role="alert"
          className="rounded border-s-4 border-blue-300 bg-blue-50 p-4"
        >
          <strong className="block text-blue-800 font-bold">
            {" "}
            Info{" "}
          </strong>
          {message.map((m) => {
            return (
              <>
                <p className="mt-2 text-sm text-blue-700">{" "}{m}</p>
              </>
            );
          })}
        </div>
      )}
    </>
  );
}
