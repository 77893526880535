import SubMenuItem, { SubMenuItemProps } from "./SubMenuItem";
import { useNavigate } from "react-router";
import classNames from "classnames";
import Spinner from "./library/Spinner";

export interface MenuItemProps {
  picked: boolean;
  label: string;
  icon: React.ReactNode;
  href?: string;
  onClick?: () => void;
  loading?: boolean;
  onSubitemClick?: () => void;
  statusIcon?: React.ReactNode;
  openSubItems?: boolean;
  subItems?: SubMenuItemProps[];
}

export default function MenuItem(props: MenuItemProps) {
  const {
    picked,
    label,
    href,
    icon,
    openSubItems,
    subItems,
    statusIcon,
    onClick,
    onSubitemClick,
    loading,
  } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full border-solid border-zinc-200">
        <div
          className={classNames(
            `flex last:items-center p-1.5 w-full cursor-pointer text-black`
          )}
          onClick={() => {
            if (href) {
              navigate(href);
            }
            if (onClick) {
              onClick();
            }
          }}
        >
          <div
            className={classNames(
              "flex h-12 w-full items-center hover:bg-zinc-100 rounded-lg p-3 transition duration-200 ease-in-out",
              picked ?
                "bg-white text-[#fb3310] font-bold hover:text-gray-700 rounded-lg" : "text-gray-700"
            )}
          >
            {loading ? <Spinner height={4} width={4} color="red" /> : icon}
            <div className="mx-3 text-sm">{label}</div>
            <div className="m-1">{statusIcon && statusIcon}</div>
          </div>
        </div>

        <div
          className={classNames(
            "transform transition-all duration-200",
            openSubItems && subItems !== undefined
              ? "origin-top scale-y-full block"
              : "origin-top scale-y-0 h-0"
          )}
          onClick={() => {
            if (onSubitemClick) {
              onSubitemClick();
            }
          }}
        >
          {openSubItems &&
            subItems !== undefined &&
            subItems.map((subProps: SubMenuItemProps) => {
              return (
                <>
                  <SubMenuItem
                    picked={subProps.picked}
                    label={subProps.label}
                    href={subProps.href}
                    onClick={() => {
                      if (subProps.onClick) {
                        subProps.onClick();
                      }
                    }}
                    icon={subProps.icon}
                    loading={subProps.loading}
                  />
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}
