import classNames from "classnames";
import { useNavigate } from "react-router";
import Spinner from "./library/Spinner";

export interface SubMenuItemProps {
  picked: boolean;
  label: string;
  href?: string;
  icon: React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
}

export default function SubMenuItem(props: SubMenuItemProps) {
  const { picked, label, href, icon, onClick, loading } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full px-4 py-1 cursor-pointer">
        <div
          className={classNames(
            "pl-10 flex h-[40px] w-full text-white hover:text-black items-center hover:shadow-xl hover:bg-gradient-to-r hover:to-zinc-200 hover:from-white hover:rounded-lg",
            picked
              ? "bg-gradient-to-r to-zinc-200 from-white rounded-lg shadow-xl text-black"
              : "text-white"
          )}
          onClick={() => {
            if (href) {
              navigate(href);
            }
            if (onClick) {
              onClick();
            }
          }}
        >
          <div className="py-1 flex w-full items-center">
            {loading ? <Spinner height={4} width={4} color="red" /> : icon}
            <span className="mx-3">{label}</span>
          </div>
        </div>
      </div>
    </>
  );
}
