import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useRef, useState } from "react";
import { useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import Button from "./Button";
import Popover from "./Popover/Popover";

export interface PageInfo {
  pageNumber: number;
  pageSize: number;
  hasPrevious: boolean;
  hasNext: boolean;
  totalSize: number;
}

export interface PaginationProps {
  pageInfo: PageInfo;
  onChange: (info: PageInfo) => void;
}

export default function Pagination(props: PaginationProps) {
  const widthRef = useRef<HTMLDivElement>(null);
  const [showPageSizePicker, setShowPageSizePicker] = useState<boolean>(false);
  const [marginPages, setMarginPages] = useState<number>(3);
  const { pageInfo, onChange } = props;

  const totalPages = useMemo(() => {
    if (pageInfo.totalSize % pageInfo.pageSize === 0) {
      return Math.floor(pageInfo.totalSize / pageInfo.pageSize);
    }
    return Math.floor(pageInfo.totalSize / pageInfo.pageSize) + 1;
  }, [pageInfo.totalSize, pageInfo.pageSize]);

  const isLastPage = useMemo(() => {
    return pageInfo.pageSize * pageInfo.pageNumber > pageInfo.totalSize;
  }, [pageInfo.pageSize, pageInfo.pageNumber, pageInfo.totalSize]);

  useEffect(() => {
    const handleResize = () => {
      if (widthRef.current && widthRef.current.clientWidth && widthRef.current.clientWidth >= 1000 && marginPages <= 3) {
        setMarginPages(3);
      } else if (widthRef.current && widthRef.current.clientWidth && widthRef.current.clientWidth < 1000 && marginPages >= 1) {
        setMarginPages(1);
      }
    }
    window.addEventListener('resize', handleResize);

    if (widthRef.current && widthRef.current.clientWidth && widthRef.current.clientWidth >= 1000 && marginPages <= 3) {
      setMarginPages(3);
    } else if (widthRef.current && widthRef.current.clientWidth && widthRef.current.clientWidth < 1000 && marginPages >= 1) {
      setMarginPages(1);
    }

    if (
      pageInfo.pageNumber !== 1 &&
      (pageInfo.pageNumber - 1) * pageInfo.pageSize >= pageInfo.totalSize
    ) {
      onChange({ ...pageInfo, pageNumber: pageInfo.pageNumber - 1 });
    }
  }, [pageInfo, onChange, marginPages]);

  const onChangePageSize = (value: number) => {
    const totalPages = pageInfo.totalSize / value;
    setShowPageSizePicker(false);
    if (pageInfo.pageNumber > totalPages) {
      onChange({
        ...pageInfo,
        pageNumber: totalPages + 1,
        pageSize: value,
      });
    } else {
      onChange({
        ...pageInfo,
        pageSize: value,
      });
    }
  };

  return (
    <>
      <div className="w-full" ref={widthRef}>
        <div className="bg-white px-3 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="flex-1 flex items-center md:justify-between">
            <div className="hidden md:block">
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">
                  {(pageInfo.pageNumber - 1) * pageInfo.pageSize + 1}
                </span>{" "}
                to{" "}
                <span className="font-medium">
                  {isLastPage
                    ? pageInfo.totalSize
                    : pageInfo.pageNumber * pageInfo.pageSize}
                </span>{" "}
                of <span className="font-medium">{pageInfo.totalSize}</span>{" "}
                results
              </p>
            </div>
            <div className="m-auto md:m-0 flex md:flex-row flex-col">
              <div>
                <ReactPaginate
                  className="inline-flex w-full"
                  breakLabel={
                    <>
                      <div className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-6 py-6 border text-sm font-medium">
                        ...
                      </div>
                    </>
                  }
                  nextLabel={
                    <>
                      {pageInfo.hasNext && (
                        <div
                          onClick={() =>
                            onChange({
                              ...pageInfo,
                              pageNumber: pageInfo.pageNumber + 1,
                            })
                          }
                          className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-6 py-3 border text-sm font-medium rounded-r-md"
                        >
                          <ChevronRightIcon className="h-5 w-4" />
                        </div>
                      )}
                      {!pageInfo.hasNext && (
                        <div className="bg-white border-gray-200 text-gray-300 cursor-not-allowed hover:bg-gray-50 relative inline-flex items-center px-6 py-3 border text-sm font-medium rounded-r-md">
                          <ChevronRightIcon className="h-5 w-4" />
                        </div>
                      )}
                    </>
                  }
                  pageRangeDisplayed={marginPages}
                  marginPagesDisplayed={marginPages}
                  pageCount={totalPages}
                  previousLabel={
                    <>
                      {pageInfo.hasPrevious && (
                        <div
                          onClick={() =>
                            onChange({
                              ...pageInfo,
                              pageNumber: pageInfo.pageNumber - 1,
                            })
                          }
                          className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-6 py-3 border text-sm font-medium rounded-l-md"
                        >
                          <ChevronLeftIcon className="h-5 w-4" />
                        </div>
                      )}
                      {!pageInfo.hasPrevious && (
                        <div className="bg-white border-gray-200 text-gray-300 cursor-not-allowed hover:bg-gray-50 relative inline-flex items-center px-6 py-3 border text-sm font-medium rounded-l-md">
                          <ChevronLeftIcon className="h-5 w-4" />
                        </div>
                      )}
                    </>
                  }
                  pageLabelBuilder={(page: number) => {
                    return (
                      <>
                        <div
                          className={classNames(
                            "relative inline-flex items-center px-6 py-3 border text-sm font-medium",
                            pageInfo.pageNumber === page
                              ? "bg-red-50 border-[#fb3310] text-red-600"
                              : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                          )}
                          onClick={() =>
                            onChange({
                              ...pageInfo,
                              pageNumber: page,
                            })
                          }
                        >
                          {page}
                        </div>
                      </>
                    );
                  }}
                />
              </div>
              <div className="md:w-[100px] md:pl-2 pt-2 md:pt-0 w-full">
                <Popover
                  onOutsideClick={() => setShowPageSizePicker(false)}
                  visible={showPageSizePicker}
                  content={
                    <>
                      <div className="bg-slate-100 p-1 shadow-xl rounded-md">
                        <ul>
                          <li
                            onClick={() => onChangePageSize(10)}
                            className="py-2 px-4 hover:bg-[#fb3310] hover:text-white hover:rounded-md cursor-pointer"
                          >
                            10
                          </li>
                          <li
                            onClick={() => onChangePageSize(20)}
                            className="py-2 px-4 hover:bg-[#fb3310] hover:text-white hover:rounded-md cursor-pointer"
                          >
                            20
                          </li>
                          <li
                            onClick={() => onChangePageSize(50)}
                            className="py-2 px-4 hover:bg-[#fb3310] hover:text-white hover:rounded-md cursor-pointer"
                          >
                            50
                          </li>
                          <li
                            onClick={() => onChangePageSize(100)}
                            className="py-2 px-4 hover:bg-[#fb3310] hover:text-white hover:rounded-md cursor-pointer"
                          >
                            100
                          </li>
                        </ul>
                      </div>
                    </>
                  }
                >
                  <Button
                    className="h-[38px]"
                    buttonWidth="full"
                    variant="outline"
                    label={pageInfo.pageSize.toString()}
                    onClick={() => setShowPageSizePicker(!showPageSizePicker)}
                  />
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
