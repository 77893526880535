import { useCallback, useContext } from 'react';
import { AuthContext } from '../common/AuthProvider';
import { executePlainFetch, fetchJson } from '../util/fetcher';

// Requires auth context
export function useFetch() {
  const authContext = useContext(AuthContext);

  const executeFetchFunc = useCallback(
    (
      url: string,
      options?: RequestInit,
      withContentType: boolean = true,
    ): Promise<any> => {
      return executePlainFetch(
        url,
        authContext?.session?.access_token,
        options,
        withContentType,
      );
    },
    [authContext],
  );

  const fetchJsonFunc = useCallback(
    async <T>(
      url: string,
      options?: RequestInit,
      withContentType: boolean = true,
    ): Promise<T> =>
      fetchJson<T>(
        url,
        authContext?.session?.access_token,
        options,
        withContentType,
      ),
    [authContext],
  );

  return { fetchJsonFunc, executeFetchFunc };
}
