import { useNavigate } from "react-router";
import Button from "../../common/library/Button";

export default function NewPasswordComplete() {
  const navigate = useNavigate();

  return (
    <>
      <div className="relative min-h-screen flex flex-col sm:justify-center items-center bg-gray-100">
        <div className="relative sm:max-w-md w-full align-middle">
          <div
            className="relative w-full sm:h-full h-screen rounded-3xl px-2 sm:px-6 py-4 bg-white shadow-md m-auto flex sm:justify-center items-center">
            <div className="w-[500px] m-auto p-3">
              <div className="m-auto w-full">
                <label className="block mt-3 text-lg text-gray-700 text-center font-semibold">
                  Qbon Change Password
                </label>

                <p className="py-6 text-center">Password reset complete!</p>

                <div className="mt-3 mb-5">
                  <Button
                    label="Back to Login"
                    buttonWidth="full"
                    onClick={() => navigate("/login")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
