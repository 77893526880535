import { useState } from 'react';
import AutocompleteBox from './library/AutocompleteBox';
import useGoogleMaps, {
  AutocompleteSuggestionsPrediction,
  GeoLocation,
} from '../hooks/google-maps.hook';

interface Props {
  onSelected: (value: GeoLocation) => void;
  disabled?: boolean;
  label?: string;
}

export default function AutocompleteAddress({ onSelected, disabled, label }: Props) {
  const { autocomplete, geocode } = useGoogleMaps();

  const [options, setOptions] = useState<AutocompleteSuggestionsPrediction[]>(
    [],
  );
  const [value, setValue] = useState<AutocompleteSuggestionsPrediction>({
    description: '',
    place_id: '',
  });

  return (
    <>
      <AutocompleteBox
        options={options}
        disabled={disabled}
        idKey={'place_id'}
        label={label ? label : 'Search Business Address'}
        value={value}
        onSelected={(
          selected: AutocompleteSuggestionsPrediction | undefined,
        ) => {
          console.log(selected);
          if (selected) {
            setValue(selected);
            geocode(selected.place_id).then((location) => onSelected({ ...location, placeId: selected.place_id }));
          }
        }}
        onInputChange={(text: string) => {
          autocomplete(text).then((sugg) => {
            setOptions(sugg.predictions);
          });
        }}
        format={(val) => {
          return val.description;
        }}
      />
    </>
  );
}
