export type MetricType =
  'STORE_FRONT_ARRIVED'
  | 'POINTS_REWARDED'
  | 'LINK_CLICKED'
  | 'SOCIAL_SHARE'
  | 'COUPON_CLAIMED'
  | 'COUPON_REDEEMED'
  | 'COUPON_CREATED'
  | 'COUPON_VIEWED'
  | 'RAFFLE_ENTERED'
  | 'CONTENT_POSTED'
  | 'MERCHANT_CREATED'
  | 'USER_CREATED'

export const secondaryColors = [
  "#4285F4",
  "#EA4335",
  "#FBBC04",
  "#34A853",
  "#202124",
  "#643566",
  "#3E6635",
  "#2D3947",
  "#1345BD",
  "#BD5C13",
  "#663543",
]

export function getDifferenceInDays(date1: Date, date2: Date): number {
  const oneDay = 1000 * 60 * 60 * 24; // Milliseconds in one day
  const diffInTime = Math.abs(date2.getTime() - date1.getTime()); // Difference in milliseconds
  const diffInDays = Math.ceil(diffInTime / oneDay); // Convert milliseconds to days and round up
  return diffInDays;
}