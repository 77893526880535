import React, { useCallback, useState } from "react";
import "./ImageCircleUpload.css";
import ImageCropModal from "../ImageCropper";

export default function ImageCircleUpload({
  imageUrl,
  onSave,
  saving,
}: {
  imageUrl?: string;
  onSave?: (file: Blob) => void;
  saving: boolean;
}) {
  const [state, setState] = useState<{
    file: Blob | undefined;
    imagePreviewUrl: string | undefined;
    active: string;
    showCropModal: boolean;
  }>({
    file: undefined,
    imagePreviewUrl: imageUrl,
    active: "edit",
    showCropModal: false,
  });

  const photoUpload = useCallback((e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setState((prevState) => ({
      ...prevState,
      file,
      imagePreviewUrl: url,
      showCropModal: true,
    }));
  }, []);

  const handleCropComplete = useCallback((croppedBlob: Blob | null) => {
    if (croppedBlob && onSave) {
      onSave(croppedBlob); // Call the onSave function directly with the croppedBlob
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result as string;
        setState((prevState) => ({
          ...prevState,
          file: croppedBlob,
          imagePreviewUrl: dataUrl,
          showCropModal: false,
        }));
      };
      reader.readAsDataURL(croppedBlob);
    } else {
      setState((prevState) => ({
        ...prevState,
        showCropModal: false,
      }));
    }
  }, [onSave]); // Ensure onSave is included in the dependency array

  return (
    <div>
      {state.active === "edit" && (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={photoUpload}
            style={{ display: "none" }}
            id="photo-upload"
          />
          <label htmlFor="photo-upload" className="custom-file-upload fas">
            <div className="img-wrap img-upload">
              <img
                alt="upload"
                className="object-cover object-center"
                src={state.imagePreviewUrl || ""}
              />
            </div>
          </label>
        </>
      )}
      {state.showCropModal && state.imagePreviewUrl && (
        <ImageCropModal
          src={state.imagePreviewUrl}
          aspect={[1, 1]} // Aspect ratio for cropping the image
          onComplete={handleCropComplete}
          onClose={() =>
            setState((prevState) => ({ ...prevState, showCropModal: false }))
          }
        />
      )}
    </div>
  );
}
