import "./styles.css";

export interface HelpTipProps {
  message: string;
}

export default function HelpTip({ message }: HelpTipProps) {
  return (
    <div className="help-tip">
      <p>{message}</p>
    </div>
  );
}
