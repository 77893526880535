import React, { useState, useCallback } from "react";
import "react-image-crop/dist/ReactCrop.css";
import Cropper, { Area, Point } from "react-easy-crop";

interface ImageCropModalProps {
  src: string;
  aspect: [number, number];
  onComplete: (croppedImage: Blob | null) => void;
  onClose: () => void;
}

const ImageCropModal: React.FC<ImageCropModalProps> = ({
  src,
  aspect,
  onComplete,
  onClose,
}) => {
  const [crop, setCrop] = useState<Area>({ x: 0, y: 0, width: 0, height: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCropChange = (point: Point) => {
    const newCrop: Area = { ...crop, x: point.x, y: point.y };
    setCrop(newCrop);
  };

  const handleCrop = async () => {
    try {
      if (croppedAreaPixels.width && croppedAreaPixels.height) {
        const croppedImage = await getCroppedImg(src, croppedAreaPixels);
        onComplete(croppedImage);
      } else {
        alert("Please select an area to crop.");
      }
    } catch (error) {
      console.error("Error cropping image:", error);
      onComplete(null);
    }
  };

  const getCroppedImg = async (
    imageSrc: string,
    croppedAreaPixels: Area
  ): Promise<Blob | null> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          resolve(null);
          return;
        }
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            resolve(null);
          }
        }, "image/jpeg");
      };
      image.src = imageSrc;
    });
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        width: "80%",
        height: "60%",
        maxWidth: "800px",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ marginBottom: "24px", borderRadius: "8px", flex: 1 }}>
        <Cropper
          image={src}
          crop={crop}
          aspect={aspect[0] / aspect[1]}
          onCropChange={handleCropChange}
          onCropComplete={onCropComplete}
          zoomWithScroll
          showGrid={false}
          minZoom={0.5}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <button
          onClick={handleCrop}
          style={{
            padding: "12px 24px",
            borderRadius: "8px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            cursor: "pointer",
            transition: "background-color 0.3s",
            zIndex: 2, 
          }}
        >
          Confirm Selection
        </button>
        <button
          onClick={onClose}
          style={{
            padding: "12px 24px",
            borderRadius: "8px",
            backgroundColor: "#FF5722",
            color: "white",
            border: "none",
            cursor: "pointer",
            transition: "background-color 0.3s",
            zIndex: 2,
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ImageCropModal;
