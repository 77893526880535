import { useEffect, useState } from 'react';
import BasicForm, { FormField } from '../../../common/library/BasicForm';
import { useRaffles } from '../../../hooks/raffle.hook';

interface Props {
  onChange: (raffleData: any) => void;
}

export default function CreateRaffleInfo({ onChange }: Props) {
  const { useRaffleRequirementsQuery } = useRaffles();

  const { data: requirementsData, isSuccess: requirementsSuccesss } = useRaffleRequirementsQuery();
  const [raffleData, setRaffleData] = useState<any>({
    name: '',
    description: '',
    entryRequirementId: 1,
    code: '',
    startDate: new Date(),
  });

  useEffect(() => {
    onChange(raffleData);
  }, [raffleData, onChange]);

  const fields: FormField[] = [
    {
      label: 'Name',
      required: true,
      value: raffleData.name,
      onValueChange: (name) => {
        const newData = { ...raffleData, name };
        setRaffleData(newData);
      },
    },
    {
      label: 'Description',
      required: true,
      value: raffleData.description,
      onValueChange: (description) => {
        const newData = { ...raffleData, description };
        setRaffleData(newData);
      },
    },
    {
      label: 'Entry Requirement',
      value: requirementsSuccesss ? (requirementsData.find(r => r.id === raffleData.entryRequirementId) ?? { name: '' }).name : '',
      combobox: true,
      options: requirementsSuccesss ? requirementsData.map((method) => method.name) : [],
      onValueChange: (value) => {

        if (value && requirementsSuccesss) {
          const id = (requirementsData.find(r => r.name === value) ?? { id: 1 }).id;
          const newData = { ...raffleData, entryRequirementId: id };
          setRaffleData(newData);
        }
      },
    },
    {
      label: 'Date',
      value: raffleData.startDate?.toString(),
      type: 'date',
      onValueChange: (startDate) => {
        const newData = {
          ...raffleData,
          startDate: new Date(startDate),
          endDate: new Date(startDate)
        };
        setRaffleData(newData);
      },
    },
  ];

  return (
    <>
      <div className="py-4">
        <BasicForm fields={fields} />
      </div>
    </>
  );
}
