import { ExclamationCircleIcon } from '@heroicons/react/solid';


export interface Props {
  message: string;
}

export default function WarningBox({message}: Props) {
  return (
    <>
      <div className="bg-yellow-50 p-6 rounded-lg">
        <div className="text-yellow-700 flex md:w-[100%]">
          <div className="w-[50px] pr-6 m-auto">
            <ExclamationCircleIcon height={32} width={32} />
          </div>
          <div className="m-auto">
            {message}
          </div>
        </div>
      </div>
    </>
  )
}