import { useFetch } from './fetch.hook';
import { useMutation } from '@tanstack/react-query';
import { UserSocialHandle } from '../models/users';

const Routes = {
  userSocialHandles: (userId: number) => `/users/${userId}/social-handles`,
};

export function useSocialHandles() {
  const { fetchJsonFunc } = useFetch();

  const useSocialHandlesMutationQuery = (
    onSuccess: () => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (userId: number) => fetchJsonFunc<UserSocialHandle[]>(
        Routes.userSocialHandles(userId)),
      onSuccess,
      onError,
    });
  };

  return {
    useSocialHandlesMutationQuery,
  };
}