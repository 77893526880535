// This method can build a phone number at any state in which the number is at
// Once it reaches (xxx) xxx-xxxx no more digits will be added to the string and
// It will be returned
export function buildVisualPhoneNumber(phoneNumber: string): string {
  try {
    if (phoneNumber) {
      if (phoneNumber.length > 10 && phoneNumber.includes('+')) {
        let extraLength = phoneNumber.length - 10;
        return formatPhoneNumber(phoneNumber.substring(extraLength));
      }
      return formatPhoneNumber(phoneNumber);
    }
    return '';
  } catch (error) {
    return '';
  }
}

function formatPhoneNumber(value: string) {
  let x = value!.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  if (x != null) {
    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  }
  return '';
}

export function buildServerPhoneNumber(phoneNumber: string): string {
  try {
    let number = phoneNumber
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '');
    if (number.length < 10) {
      return '';
    }
    return `+1${number.replace('+1', '')}`;
  } catch (error) {
    return '';
  }
}

export function metersToText(meters: number) {
  if (meters === 15) {
    return '15 Meters, Approximately 50 Feet';
  }

  if (meters === 30) {
    return '30 Meters, Approximately 100 Feet';
  }

  if (meters === 60) {
    return '60 Meters, Approximately 200 Feet';
  }

  if (meters === 150) {
    return '150 Meters, Approximately 500 Feet';
  }

  if (meters === 230) {
    return '230 Meters, Approximately 750 Feet˝';
  }

  if (meters === 300) {
    return '300 Meters, Approximately 1000 Feet';
  }

  return '15 Meters, Approximately 50 Feet';
}

export function feetToMetersTextToInt(text: string) {
  if (text === '15 Meters, Approximately 50 Feet') {
    return 15;
  }

  if (text === '30 Meters, Approximately 100 Feet') {
    return 30;
  }

  if (text === '60 Meters, Approximately 200 Feet') {
    return 60;
  }

  if (text === '150 Meters, Approximately 500 Feet') {
    return 150;
  }

  if (text === '230 Meters, Approximately 750 Feet') {
    return 230;
  }

  if (text === '300 Meters, Approximately 1000 Feet') {
    return 300;
  }
  return 15;
}

export function buildPhoneNumberDigits(phoneNumber: string): string {
  try {
    let number = phoneNumber
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '');
    if (number.length < 10) {
      return '';
    }
    return number;
  } catch (error) {
    return '';
  }
}

export const defaultPageData = {
  data: [],
  pageNumber: 0,
  pageSize: 10,
  totalSize: 0,
  hasNext: false,
  hasPrevious: false,
};

export const expirationDateToText = (startDate: Date, endDate: Date) => {
  if (startDate && endDate && startDate !== null && endDate !== null) {
    return `Good ${startDate.toLocaleDateString()} thru ${endDate.toLocaleDateString()}`;
  }
  if (startDate && startDate !== null) {
    return `Starts on ${startDate.toLocaleDateString()}`;
  }

  if (endDate && endDate !== null) {
    return `expires: ${endDate.toLocaleDateString()}`;
  }

  return 'Never expires (unless merchant cancels deal)';
};