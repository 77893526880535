import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback } from 'react';

export interface TabProps {
  category: string;
  title: string;
  content: React.ReactNode;
  onClick?: () => void;
}

export interface TabsProps {
  tabs: TabProps[];
}

export default function Tabs({ tabs }: TabsProps) {
  const getTabClassName = useCallback(
    (selected: boolean) =>
      classNames(
        'w-full py-5 px-4 text-sm font-medium leading-5 transition-all duration-300 ease-in-out',
        'focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50',
        'rounded-t-sm',
        selected
          ? ' shadow font-bold border-b-2 border-[#fb3310]'
          : 'text-gray-700 hover:bg-gray-100 border-b-2 border-transparent border-b-2 border-gray-100',
      ),
    [],
  );

  return (
    <div className="w-full rounded-2xl bg-white">
      <Tab.Group>
        <Tab.List className="flex">
          {tabs.map((tab, idx) => (
            <Tab
              key={idx}
              onClick={tab.onClick}
              className={({ selected }) => getTabClassName(selected)}
            >
              {({ selected }) => (
                <span className={classNames(selected ? 'font-bold' : '')}>
                                  {tab.title}
                                </span>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="p-4">
          {tabs.map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className="bg-white rounded-b-lg focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
            >
              {tab.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}