import { useFetch } from './fetch.hook';
import { Page, Timeseries, TimeseriesV2 } from '../types';
import { useQuery } from '@tanstack/react-query';
import { UserArrivalLog } from '../models/arrival-logs';
import { MetricType } from '../common/metrics/type';

const Routes = {
  merchantArrivalLogsMetrics: (merchantId: number) =>
    `/merchants/${merchantId}/arrival-logs/metrics`,
  merchantArrivalLogs: (merchantId: number, page = 0, pageSize = 10) =>
    `/merchants/${merchantId}/arrival-logs?page=${page}&pageSize=${pageSize}`,
  merchantMetrics: (merchantId: number) => `/merchants/${merchantId}/metrics`,
  systemMetrics: () => `/metrics/general`,
};

function merchantMetricTypeToPath(type: MetricType) {
  switch (type) {
    case 'SOCIAL_SHARE': {
      return 'social-share';
    }
    case 'COUPON_CLAIMED': {
      return 'coupon';
    }
    case 'COUPON_CREATED': {
      return 'coupon';
    }
    case 'COUPON_REDEEMED': {
      return 'coupon';
    }
    case 'LINK_CLICKED': {
      return 'links';
    }
    case 'POINTS_REWARDED': {
      return 'points';
    }
    case 'CONTENT_POSTED': {
      return 'content';
    }
    case 'COUPON_VIEWED': {
      return 'coupon';
    }
    case 'RAFFLE_ENTERED': {
      return 'raffle';
    }
    default: {
      return 'store-front';
    }
  }
}

export function useMetrics() {
  const { fetchJsonFunc } = useFetch();

  const useArrivalLogMetricsQuery =
    (
      merchantId: number,
    ) => {
      return useQuery({
        queryKey: ['merchantMetrics', merchantId],
        queryFn: () => fetchJsonFunc<Timeseries>(
          Routes.merchantArrivalLogsMetrics(merchantId),
        ),
      });
    };

  const useMerchantArrivalLogsQuery =
    (
      merchantId: number,
      page = 0,
      pageSize = 10,
    ) => {
      return useQuery({
        queryKey: ['merchantArrivalLogs', merchantId, page, pageSize],
        queryFn: () => fetchJsonFunc<Page<UserArrivalLog>>(
          Routes.merchantArrivalLogs(merchantId, page, pageSize),
        ),
      });
    };

  const useMerchantMetricsQuery =
    (
      merchantId: number,
      type: MetricType,
      fromDate: Date,
      toDate: Date,
      params: string[],
    ) => {
      let query = '';
      for (const p of params) {
        if (p !== '') {
          query = query + `&${p}`;
        }
      }
      return useQuery({
        queryKey: ['merchantMetrics', merchantId, type, fromDate, toDate, ...params],
        queryFn: () => {
          const route = `${Routes.merchantMetrics(merchantId)}/${merchantMetricTypeToPath(type)}?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}${query}`;
          return fetchJsonFunc<TimeseriesV2>(
            route,
          );
        },
      });
    };

  const useSystemMetrics =
    (
      type: MetricType,
      fromDate: Date,
      toDate: Date,
    ) => {
      return useQuery({
        queryKey: ['merchantMetrics', type],
        queryFn: () => fetchJsonFunc<TimeseriesV2>(
          `${Routes.systemMetrics()}?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}&systemMetricValue=${type}`,
        ),
      });
    };

  return {
    useMerchantMetricsQuery,
    useSystemMetrics,
    useArrivalLogMetricsQuery,
    useMerchantArrivalLogsQuery,
  };
}
