import { useState } from "react";
import { useNavigate } from "react-router";
import Button from "../../common/library/Button";
import Input from "../../common/library/Input";
import { ErrorMessage } from "../../common/library/ErrorMessage";
import { SupabaseClient } from "@supabase/supabase-js";
import Checkbox from "../../common/library/Checkbox";

export default function SignUp({ supabase }: { supabase: SupabaseClient }) {
  const navigate = useNavigate();

  const [agreedToConditions, setAgreedToConditions] = useState<boolean>(false);
  const [passwordRepeat, setPasswordRepeat] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function submitCreateUser() {
    setLoading(true);
    if (
      password &&
      passwordRepeat &&
      password !== "" &&
      passwordRepeat !== "" &&
      email &&
      firstName &&
      email !== "" &&
      firstName !== "" &&
      lastName &&
      lastName !== ""
    ) {
      if (!agreedToConditions) {
        setErrorMessage([
          "You must agree to the privacy policy and terms of use.",
        ]);
        setLoading(false);
        return;
      }
      if (password && passwordRepeat && password === passwordRepeat) {
        supabase.auth
          .signUp({
            email,
            password,
            options: {
              data: {
                first_name: firstName,
                last_name: lastName,
                accepted_terms_and_conditions: true,
              },
            },
          })
          .then((result) => {
            console.log(result);
            if (result.error) {
              setErrorMessage([result.error.message]);
            } else {
              navigate(`/verify?email=${email}`);
            }
          })
          .catch((error) => {
            setErrorMessage(error.message);
          })
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
        setErrorMessage(["Passwords do not match."]);
      }
    } else {
      setLoading(false);
      setErrorMessage(["Please make sure no fields are left blank."]);
    }
  }

  return (
    <>
      <div className="relative min-h-screen flex flex-col sm:justify-center items-center bg-gray-100">
        <div className="relative sm:max-w-md w-full align-middle">
          <div
            className="relative w-full sm:h-full min-h-screen sm:rounded-3xl px-2 sm:px-6 py-6 bg-white shadow-md m-auto flex sm:justify-center items-center">
            <div className="w-[500px] m-auto p-3">
              <div className="m-auto w-full">
                <label className="block mt-3 text-lg text-gray-700 text-center font-semibold">
                  Create Account
                </label>

                <div className="py-3 text-red-500">
                  <ErrorMessage message={errorMessage} />
                </div>

                <div className="mt-3">
                  <Input
                    required={true}
                    label="Email"
                    value={email}
                    onChange={(value) => setEmail(value)}
                  />
                </div>

                <div className="mt-3">
                  <Input
                    required={true}
                    label="First Name"
                    value={firstName}
                    onChange={(value) => setFirstName(value)}
                  />
                </div>

                <div className="mt-3">
                  <Input
                    required={true}
                    label="Last Name"
                    value={lastName}
                    onChange={(value) => setLastName(value)}
                  />
                </div>

                <div className="mt-3">
                  <Input
                    required={true}
                    label="Password"
                    value={password}
                    onChange={(value) => setPassword(value)}
                    type="password"
                  />
                </div>

                <div className="mt-3">
                  <Input
                    required={true}
                    type={"password"}
                    label="Confirm Password"
                    value={passwordRepeat}
                    onChange={(value) => setPasswordRepeat(value)}
                  />
                </div>

                <div className="pt-6">
                  <ul>
                    <li className="font-bold">Password Requirements</li>
                    <li className="font-thin">* Atleast 8 characters</li>
                    <li className="font-thin">* Contains at least 1 number</li>
                    <li className="font-thin">
                      * Contains at least 1 special character fromt the
                      following{" "}
                      {`^ $ * . [ ] { } ( ) ? " ! @ # % & / , > < ' : ; | _ ~`}
                      {" ` = + -"}
                    </li>
                    <li className="font-thin">
                      * Contains at least 1 uppercase letter
                    </li>
                    <li className="font-thin">
                      * Contains at least 1 lowercase letter
                    </li>
                  </ul>
                </div>

                <div className="pt-6">
                  <Checkbox
                    checked={agreedToConditions}
                    label={
                      <>
                        I agree to the{" "}
                        <span>
                          <a
                            href="https://qbonapp.com/privacy-policy-2/"
                            className="underline text-blue-500"
                          >
                            privacy policy
                          </a>
                        </span>{" "}
                        and{" "}
                        <a
                          href="https://qbonapp.com/terms-of-use/"
                          className="underline text-blue-500"
                        >
                          terms of use
                        </a>
                        .
                      </>
                    }
                    onClick={() => setAgreedToConditions(!agreedToConditions)}
                  />
                </div>

                <div className="py-3">
                  <Button
                    loading={loading}
                    label="Create Account"
                    buttonWidth="full"
                    onClick={submitCreateUser}
                  />
                </div>
                <div className="py-3">
                  <Button
                    label="Back to Login"
                    buttonWidth="full"
                    variant="secondary"
                    onClick={() => navigate("/login")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
