import { useCallback, useState, useEffect } from 'react';
import { Modal } from '../../common/library/Modal';
import { User } from '../../models/users';
import BasicForm, { FormField } from '../../common/library/BasicForm';
import { LicenseRequestDTO, LicenseType } from '../../models/license';
import { ErrorMessage } from '../../common/library/ErrorMessage';
import Button from '../../common/library/Button';
import { PlusIcon } from '@heroicons/react/solid';
import useLicenses from '../../hooks/licenses.hook';
import Input from '../../common/library/Input';
import Spinner from '../../common/library/Spinner';
import Card from '../../common/library/Card';
import { useUsers } from '../../hooks/users.hook';

interface Props {
  open: boolean;
  user: User;
  onClose: () => void;
  onCreateComplete: () => void;
  licenseTypes: LicenseType[];
}

const defaultLicenseForm: LicenseRequestDTO = {
  licenseCode: '',
  userId: 0,
  licenseTypeId: 1,
  state: '',
};

export default function CreateLicenseModal({
                                             open,
                                             user,
                                             onClose,
                                             onCreateComplete,
                                             licenseTypes,
                                           }: Props) {
  const { useCreateLicenseMutation } = useLicenses();
  const createLicense = useCreateLicenseMutation(
    () => {
      setLicenseForm({ ...defaultLicenseForm, userId: user.id });
      onCreateComplete();
    },
    (error) => setLicenseFormError([error.message]),
  );

  const [licenseFormError, setLicenseFormError] = useState<string[]>([]);
  const [licenseForm, setLicenseForm] = useState<LicenseRequestDTO>({
    ...defaultLicenseForm,
    userId: user.id,
  });

  const [userSearch, setUserSearch] = useState<string>('');
  const { useSearchUsersQuery } = useUsers();
  const {
    data: searchUsersResult,
    refetch: refetchUserSearch,
    isLoading: userSearchLoading,
    isSuccess: isSuccessUserSearch,
  } = useSearchUsersQuery(userSearch);

  useEffect(() => {
    console.log('License types received in modal:', licenseTypes); // Debug log
  }, [licenseTypes]);

  const createLicenseHandler = useCallback(() => {
    if (!licenseForm.licenseCode || licenseForm.licenseCode === '') {
      setLicenseFormError(['License must have a code']);
      return;
    }

    if (!licenseForm.licenseTypeId || licenseForm.licenseTypeId === 0) {
      setLicenseFormError(['License Type must be selected']);
      return;
    }

    if (!searchUsersResult || searchUsersResult.data.length === 0) {
      setLicenseFormError(['Please pick a user to assign a license to']);
      return;
    }

    setLicenseFormError([]);
    createLicense.mutate({
      ...licenseForm,
      state: licenseForm.state !== '' ? licenseForm.state : undefined,
      userId: searchUsersResult.data[0].id,
    });
  }, [createLicense, licenseForm, searchUsersResult]);

  const handleComboboxChange = (value: string) => {
    const selectedType = licenseTypes.find((type) => type.name === value);
    if (selectedType) {
      setLicenseForm({ ...licenseForm, licenseTypeId: selectedType.id });
    }
  };

  const fields: FormField[] = [
    {
      label: 'License Code',
      value: licenseForm.licenseCode,
      onValueChange: (licenseCode) =>
        setLicenseForm({ ...licenseForm, licenseCode }),
      required: true,
    },
    {
      label: 'License Type',
      value: licenseForm.licenseTypeId.toString() === "1" ? "state" : "individual",
      onValueChange: handleComboboxChange,
      required: true,
      combobox: true,
      options: licenseTypes.map((type) => type.name), // Ensure options are strings
    },
    {
      label: 'State',
      value: licenseForm.state,
      onValueChange: (state) => setLicenseForm({ ...licenseForm, state }),
      required: false,
    },
  ];

  return (
    <Modal
      open={open}
      buttonLoading={createLicense.status === 'pending'}
      onClose={() => {
        onClose();
        setLicenseForm({ ...defaultLicenseForm, userId: user.id });
      }}
      title="Create License"
      buttonLabel="Close"
    >
      <p className="text-[#fb3310] p-4">
        <ErrorMessage message={licenseFormError} />
      </p>
      <>
        <div className="w-full">
          <Input
            value={userSearch}
            onEnter={() => refetchUserSearch()}
            label="User Email (Press Enter to Search)"
            onChange={(val) => setUserSearch(val)}
            required
          />
          <div>
            {userSearchLoading && <Spinner height={4} width={4} color="red" />}
            {!userSearchLoading && isSuccessUserSearch && searchUsersResult.data.length === 0 ? (
              <>
                <Card>No user found</Card>
              </>
            ) : (
              !userSearchLoading &&
              isSuccessUserSearch && searchUsersResult.data.length > 0 && (
                <>
                  <div className={'border-2 rounded-xl border-gray-200 pt-2 pb-2 mt-4 mb-4'}>
                    <Card>
                      <div>Found</div>
                      <div>
                        {`${searchUsersResult.data[0].lastName}, ${searchUsersResult.data[0].firstName} ${searchUsersResult.data[0].email}`}
                      </div>
                    </Card>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </>
      <BasicForm fields={fields} />
      <div className="inline-flex text-center w-full">
        <div className="p-1">
          <Button
            label="Create License"
            onClick={createLicenseHandler}
            icon={<PlusIcon width={16} height={16} />}
          />
        </div>
      </div>
    </Modal>
  );
}
