import classNames from "classnames";

const Divider = ({
  withLine,
  size = "sm",
}: {
  withLine?: boolean;
  size?: "sm" | "lg";
}) => {
  return (
    <>
      <div className={classNames(size === "sm" ? "py-2" : "py-8")}>
        <div
          className={classNames(
            withLine && "border-b-[1px] w-full border-zinc-200"
          )}
        />
      </div>
    </>
  );
};

export default Divider;
