import useDevices from '../../hooks/devices.hook';
import Table, { TableColumn } from '../../common/library/Table';
import { QbonDevice } from '../../models/devices';
import Button from '../../common/library/Button';
import { ChipIcon } from '@heroicons/react/solid';
import { Modal } from '../../common/library/Modal';
import { useState } from 'react';
import { CSVLink } from 'react-csv';

export default function Devices() {
  const { useAdminDevicesQuery, useAdminGenerateDevicesMutation } = useDevices();
  const { data: devices } = useAdminDevicesQuery();
  const [open, setOpen] = useState<boolean>(false);
  const { mutate: generateDevices, data: newDevices } = useAdminGenerateDevicesMutation(() => {
      setOpen(true);
    }, () => {},
  );

  // CSV data preparation function
  const generateCSVData = (devices: QbonDevice[]) => {
    return devices.map((device) => ({
      URL: device.url,
    }));
  };

  const columns: TableColumn<QbonDevice>[] = [
    {
      label: 'ID',
      key: 'uuid',
    },
    {
      label: 'Merchant ID',
      key: 'merchantId',
    },
    {
      label: 'Created Date',
      key: 'createdDate',
    },
  ];

  return (
    <>
      <div>
        <Modal open={open} onClose={() => setOpen(false)}>
          {newDevices && (
            <CSVLink
              data={generateCSVData(newDevices)}
              headers={[{ label: 'URL', key: 'URL' }]}
              filename="qbon_devices.csv"
            >
              Download Generated Devices CSV
            </CSVLink>
          )}
        </Modal>
        <div>
          <Button label={'Generate Devices'} icon={<ChipIcon />} onClick={() => generateDevices({ count: 50 })} />
        </div>
        {devices && <Table columns={columns} data={devices.records} />}
      </div>
    </>
  );
}
