import React from 'react';
import { License } from '../../models/license';
import { CheckCircleIcon } from '@heroicons/react/outline';

interface LicenseCardProps {
  license: License;
}

const LicenseCard: React.FC<LicenseCardProps> = ({ license }) => {
  console.log('LicenseCard received license:', license);

  return (
    <div
      style={{
        margin: 8,
        width: '95%',
        alignSelf: 'center',
        overflow: 'hidden',
        borderWidth: 1,
        borderRadius: 18,
        paddingBottom: 8,
        borderColor: '#dddddd',
        backgroundColor: 'white',
      }}
      className="shadow-md hover:scale-105 transition-transform duration-300 ease-in-out hover:cursor-pointer"
    >
      <div className="p-4">
        <div className="flex flex-row w-full">
          <div className="m-4">
            <CheckCircleIcon width={48} height={48} className="text-green-600" />
          </div>
          <div className="font-bold align-middle content-center">
            <p>{license.licenseCode}</p>
          </div>
        </div>
        <div className="m-4">
          <div className="flex flex-row items-center">
            <p className="text-gray-600 font-semibold">User ID: {license.userId}</p>
          </div>
          <div className="flex flex-row items-center">
            <p className="text-gray-600 font-semibold">Type: {license.licenseType?.name || 'Unknown'}</p>
          </div>
          <div className="flex flex-row items-center">
            <p className="text-gray-600 font-semibold">State: {license.state || 'N/A'}</p>
          </div>
          <div className="flex flex-row items-center">
            <p className="text-gray-600 font-semibold">Created Date: {new Date(license.createdDate).toLocaleDateString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseCard;
