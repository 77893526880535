import { useState } from "react";
import { useNavigate } from "react-router";
import Button from "../../common/library/Button";
import Input from "../../common/library/Input";
import { SupabaseClient } from "@supabase/supabase-js";

export default function NewPassword({
  supabase,
}: {
  supabase: SupabaseClient;
}) {
  const navigate = useNavigate();

  const [passwordRepeat, setPasswordRepeat] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  function changePassword() {
    setLoading(true);
    if (
      password &&
      passwordRepeat &&
      password !== "" &&
      passwordRepeat !== ""
    ) {
      if (password && passwordRepeat && password === passwordRepeat) {
        supabase.auth
          .updateUser({ password })
          .then((result) => {
            setLoading(false);
            if (result.error) {
              setErrorMessage(result.error.message);
            } else {
              navigate("/new-password-complete");
            }
          })
          .catch((error) => {
            setLoading(false);
            setErrorMessage(error.message);
          });
      } else {
        setLoading(false);
        setErrorMessage("Passwords do not match.");
      }
    } else {
      setLoading(false);
      setErrorMessage("Please input a new password");
    }
  }

  return (
    <>
      <div className="relative min-h-screen flex flex-col sm:justify-center items-center bg-gray-100">
        <div className="relative sm:max-w-md w-full align-middle">
          <div
            className="relative w-full sm:h-full h-screen rounded-3xl px-2 sm:px-6 py-4 bg-white shadow-md m-auto flex sm:justify-center items-center">
            <div className="w-[500px] m-auto p-3">
              <div className="m-auto w-full">
                <label className="block mt-3 text-lg text-gray-700 text-center font-semibold">
                  Qbon Change Password
                </label>

                <div className="py-4 text-red-500">
                  {errorMessage && errorMessage}
                </div>

                <div>
                  <Input
                    label="New Password"
                    value={password}
                    onChange={(value) => setPassword(value)}
                    type="password"
                  />
                </div>

                <div className="mt-7">
                  <Input
                    type={"password"}
                    label="Confirm New Password"
                    value={passwordRepeat}
                    onChange={(value) => setPasswordRepeat(value)}
                  />
                </div>

                <div className="mt-7 mb-5">
                  <Button
                    loading={loading}
                    label="Change Password"
                    buttonWidth="full"
                    onClick={changePassword}
                  />
                </div>
                <div className="mt-3 mb-5">
                  <Button
                    label="Back to Login"
                    buttonWidth="full"
                    variant="secondary"
                    onClick={() => navigate("/login")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
