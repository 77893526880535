import { useEffect, useState } from 'react';
import {
  Merchant,
} from '../../../models/merchant';
import Spinner from '../../../common/library/Spinner';
import Toggle from '../../../common/library/Toggle';
import useAuthContext from '../../../hooks/auth-context.hook';
import useContent from '../../../hooks/content.hook';
import useMerchants from '../../../hooks/merchants.hook';
import { ErrorMessage } from '../../../common/library/ErrorMessage';

export default function MerchantContentSettings() {
  const auth = useAuthContext();
  const { merchant: activeMerchant } = auth.getMerchantAndUser();

  const { useMerchantContentProfileQuery, useUpdateMerchantContentProfileMutation } = useContent();
  const mutateContentProfile =
    useUpdateMerchantContentProfileMutation(activeMerchant.id, () => refetchContentProfile(), (error) => {
      setErrorMessage(error.message);
    });
  const {
    data: contentProfileData,
    refetch: refetchContentProfile,
    isSuccess: contentProfileSuccess,
  } = useMerchantContentProfileQuery(activeMerchant.id);

  // Merchant data
  const {
    useMerchantQuery,
  } = useMerchants();
  const { data: merchant } = useMerchantQuery(activeMerchant.id, activeMerchant);

  const [editingMerchant, setEditingMerchant] = useState<Merchant | undefined>(merchant);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  useEffect(() => {
    merchant && auth.updateMerchant(merchant);
    merchant && setEditingMerchant(merchant);
  }, [merchant, auth]);


  return (
    <>
      {editingMerchant ? (
        <div className="w-full">
          <div className="h-[250px]">
            <ErrorMessage message={errorMessage} />
            <div className="py-2">
              <div className="font-bold text-sm">
                Auto approve content
              </div>
              <div className="pt-2">
                <Toggle
                  enabled={
                    contentProfileSuccess ? contentProfileData.enableAutoApprove : false
                  }
                  onChange={(val) => {
                    mutateContentProfile.mutate({
                      ...contentProfileData,
                      enableAutoApprove: val,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full">
            <div className="m-auto">
              <Spinner width={8} height={8} color="red" />
            </div>
          </div>
        </>
      )}
    </>
  );
}
