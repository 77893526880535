import { Merchant } from '../../../models/merchant';
import ImageCircleUpload from '../../../common/library/ImageCircleUpload/ImageCircleUpload';
import CoverImageUpload from '../../../common/library/CoverImageUpload.tsx/CoverImageUpload';
import { useMerchantFiles } from '../../../hooks/merchant-files.hook';
import useMerchants from '../../../hooks/merchants.hook';
import { User } from '../../../models/users';
import { useState } from 'react';
import Button from '../../../common/library/Button';
import WarningBox from '../../../common/WarningBox';

interface Props {
  user: User,
  merchant: Merchant;
  onComplete: () => void;
}

export function LogoAndCoverTutorial({ user, merchant, onComplete }: Props) {

  const [editingMerchant, setEditingMerchant] = useState<Merchant>(merchant);

  // Merchant data
  const {
    useUpdateMerchantMutation,
  } = useMerchants();
  const updateMerchant = useUpdateMerchantMutation(merchant.id, (res) => {
    setEditingMerchant({ ...editingMerchant, logoImageUrl: res.logoImageUrl, coverImageUrl: res.coverImageUrl });
  }, () => {
  });

  const { useUploadMerchantFileMutation } = useMerchantFiles();
  const uploadLogo = useUploadMerchantFileMutation(user.id, merchant.id, (res) => {
    merchant && updateMerchant.mutate({ ...editingMerchant, logoImageUrl: res.fileUrl });
  }, (error) => {
  });
  const uploadCover = useUploadMerchantFileMutation(user.id, merchant.id, (res) => {
    merchant && updateMerchant.mutate({ ...editingMerchant, coverImageUrl: res.fileUrl });
  }, (error) => {
  });

  const saveImage = async (blob: Blob, type: 'cover' | 'logo') => {
    if (merchant && user) {
      const file = new File([blob], 'image.jpg', { type: blob.type });
      type === 'logo' ? uploadLogo.mutate(file) : uploadCover.mutate(file);
    }
  };


  return (
    <>
      <WarningBox
        message={'Let\'s give your merchant profile a logo and cover photo. The logo should be your businesses logo or placeholder, the cover photo can be a picture of your business or background design that represents your business. Click on the logo and cover photos to change them.'} />
      <div className="pb-12">
        <div className="m-auto">
          <div className="text-center m-auto pt-6">
            <ImageCircleUpload
              saving={uploadLogo.status === 'pending'}
              imageUrl={
                editingMerchant.logoImageUrl ||
                'https://prod-werehere-images.s3.amazonaws.com/QBON.png'
              }
              onSave={(file) => saveImage(file, 'logo')}
            />
          </div>
        </div>
        <div className="m-auto">
          <div className="text-center m-auto pt-2 w-[300px] h-[200px]">
            <CoverImageUpload
              saving={uploadCover.status === 'pending'}
              imageUrl={
                editingMerchant.coverImageUrl ||
                'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              }
              onSave={(file) => saveImage(file, 'cover')}
            />
          </div>
        </div>
      </div>
      <div className={'pt-24'}>
        <Button buttonWidth={'full'} label={'Continue'} onClick={onComplete} />
      </div>
    </>
  );
}