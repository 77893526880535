import Tabs from '../../../common/library/Tabs';
import MerchantUsers from './MerchantUsers';
import MerchantUsersInvites from './MerchantInvitations';
import React from 'react';


export default function MerchantUsersPage() {
  return (
    <>

      <div className="flex justify-between px-2 md:px-0 w-full pb-2">
        <h1 className="font-bold text-xl">Users</h1>
        <div className="float-right">
        </div>
      </div>

      <Tabs
        tabs={[
          {
            category: 'Active Users',
            title: 'Active Users',
            content: (
              <>
                <MerchantUsers />
              </>
            ),
          },
          {
            category: 'Invites',
            title: 'Invites',
            content: (
              <>
                <MerchantUsersInvites />
              </>
            ),
          },

        ]}
      />


    </>
  );
}
