import Tabs from '../../../common/library/Tabs';
import MerchantProfileGeneral from './MerchantProfileGeneral';
import MerchantStoreAndAddressSettings from './MerchantStoreAndAddressSettings';
import MerchantContentSettings from './MerchantContentSettings';
import useAuthContext from '../../../hooks/auth-context.hook';
import React from 'react';
import MerchantQRCode from '../merchant-qr-code/MerchantQRCode';

export default function MerchantProfilePage() {
  const { merchant } = useAuthContext().getMerchantAndUser();

  return (
    <>
      <h1 className="font-bold text-xl px-2 md:px-0 pb-2">Profile</h1>
      <Tabs
        tabs={[
          {
            category: 'General',
            title: 'Details & Links',
            content: <MerchantProfileGeneral />,
          },
          {
            category: 'Store Front & Address',
            title: 'Geofence & Address',
            content: <MerchantStoreAndAddressSettings />,
          },
          {
            title: 'Content Settings',
            category: 'Content Settings',
            content: <MerchantContentSettings />,
          },
          ...(merchant.status === 'SUBSCRIBED' ? [{
            title: 'Downloadable QR Code',
            category: 'Downloadable',
            content: <MerchantQRCode />,
          }] : []),
        ]}
      />
    </>
  );
}
