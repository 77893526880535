import { useNavigate } from 'react-router';
import useQueryParams from '../../../hooks/query-params.hook';
import { useEffect, useState } from 'react';
import Button from '../../../common/library/Button';
import Input from '../../../common/library/Input';
import Card from '../../../common/library/Card';
import { ErrorMessage } from '../../../common/library/ErrorMessage';
import useCoupons from '../../../hooks/coupon.hook';
import useAuthContext from '../../../hooks/auth-context.hook';

export default function MerchantScannerRedeem() {
  const query = useQueryParams();
  const navigate = useNavigate();
  const { merchant } = useAuthContext().getMerchantAndUser();
  const merchantId = merchant.id;
  const { useScanCouponMutation } = useCoupons();

  const [error, setError] = useState<string[]>([]);
  const { mutate: scanCoupon, data, isSuccess } = useScanCouponMutation((error: any) => {
    setError(error.message);
  });

  const userCouponId = query.get('userCouponId')
    ? parseInt(query.get('userCouponId')!)
    : undefined;

  if (!userCouponId) {
    navigate(`/dashboard/scanner`);
  }

  const returnToScanner = () => {
    navigate(`/dashboard/scanner`);
  };

  useEffect(() => {
    scanCoupon({
      merchantId,
      userCouponId: userCouponId!,
    });
  }, [userCouponId, scanCoupon, merchantId]);

  return (
    <>
      <Card>
        <div className="py-2">
          <ErrorMessage message={error} />
        </div>
        {isSuccess && data && (
          <>
            <h1 className="font-bold">Coupon Redeemed</h1>
            <p>{data.name} has been redeemed</p>
            <br />
            <div className="py-4">
              <Input label="Name" value={data.name} disabled />
            </div>
            <div className="py-4">
              <Input label="Code" value={data.code} disabled />
            </div>
            <div className="py-4">
              <Input label="Description" value={data.description} disabled />
            </div>
            <div className="py-4">
              <Input label="Start Date" value={data.startDate} disabled />
            </div>
            <div className="py-4">
              <Input label="End Date" value={data.endDate} disabled />
            </div>
            {data.barcodeImageUrl && (
              <div className="p-4">
                <img
                  src={data.barcodeImageUrl}
                  className="w-[200px] h-[200px]"
                  alt="barcode-redeem"
                />
              </div>
            )}
          </>
        )}
        <div className="pt-4">
          <Button
            label="Back to Scanner"
            onClick={returnToScanner}
            buttonWidth="full"
          />
        </div>
      </Card>
    </>
  );
}
