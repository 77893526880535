import { usePayments } from '../../../hooks/payment.hook';
import Card from '../../../common/library/Card';
import { CreateCheckoutSession, Product } from '../../../models/payment';
import { PricingBox } from '../../../common/library/PricingBox';
import Button from '../../../common/library/Button';
import Spinner from '../../../common/library/Spinner';
import useAuthContext from '../../../hooks/auth-context.hook';
import { useEffect } from 'react';

export default function MerchantSubscriptionPage() {
  const { merchant, user } = useAuthContext().getMerchantAndUser();
  const { useSubscriptionQuery, useProductsQuery, useCreateCheckoutMutation, useCreateBillingPortalMutation } =
    usePayments();

  const {
    data: subscription,
    isLoading: isLoadingSubscription,
    isSuccess: isSuccessSubscription,
  } = useSubscriptionQuery(user.id, merchant.id);
  const { data: products, isSuccess: isSuccessProducts } = useProductsQuery(merchant.licenseId, merchant.address.state);
  const createCheckout = useCreateCheckoutMutation(user.id, merchant.id, (res) => {
    window.location.replace(res.url);
  }, (error) => {
    console.error(error);
  });

  const createBillingPortal = useCreateBillingPortalMutation(user.id, merchant.id, (res) => {
    window.location.replace(res.url);
  }, (error) => {
    console.error(error);
  });

  const checkout = (product: Product) => {
    const createCheckoutRequest: CreateCheckoutSession = {
      successUrl: `${window.location}`,
      cancelUrl: `${window.location}`,
      merchantId: merchant.id,
      lineItems: [
        {
          productId: product.id,
          price: product.priceId,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      email: user.email,
    };
    createCheckout.mutate(createCheckoutRequest);
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <h1 className="font-bold text-xl pb-4 px-2 md:px-0">Subscription</h1>
      {!isLoadingSubscription ? (
        <div>
          {isSuccessSubscription && subscription.status === 'active' ? (
            <div className="">
              <div className="w-full pb-4">
                <Card>
                  <div className="w-full">
                    <h1 className="font-bold text-2xl">
                      {subscription.status === 'active'
                        ? 'Merchant Plan'
                        : 'No Active Subscription'}
                    </h1>
                    <h3 className="text-xl pt-2">
                      {subscription.amount &&
                        `$${subscription.amount / 100} / Month`}
                    </h3>
                    <br />
                  </div>
                </Card>
              </div>
              <div className="w-full">
                <Card>
                  <div className="w-full py-4">
                    <h3>
                      <span className="font-bold">Subscription ID: </span>
                      {subscription.externalSubscriptionId}
                    </h3>
                  </div>
                  <div className="w-full py-4">
                    <h3>
                      <span className="font-bold">Status: </span>
                      {subscription.status}
                    </h3>
                  </div>
                  <div className="w-full py-4">
                    <h3>
                      <span className="font-bold">Renews on: </span>
                      {subscription.renewalDate &&
                        new Date(subscription.renewalDate).toLocaleDateString()}
                    </h3>
                  </div>
                  <div className="w-full py-2">
                    <Button
                      buttonWidth="full"
                      label="Manage Subscription Information"
                      variant="outline"
                      onClick={() => createBillingPortal.mutate()}
                      loading={createBillingPortal.status === 'pending'}
                    />
                  </div>
                </Card>
              </div>
            </div>
          ) : merchant.status !== 'SUBSCRIBED' && isSuccessProducts ? (
            <>
              <div>
                {products.length > 0 && (
                  <PricingBox
                    loading={createCheckout.status === 'pending'}
                    title={products[0].name}
                    price={(products[0].defaultPrice / 100).toString()}
                    rate={'Month'}
                    description={products[0].description}
                    features={[
                      'Merchant mobile app listing',
                      'Qbons',
                      'QR code scan location verification',
                      'Location based user access',
                      'Raffles',
                      'Merchant content feed',
                      'Custom store front links',
                      'Merchant secure Qbon scanner',
                      'Unlimited user QR scans',
                      'Create unlimited Qbons',
                      'Metrics',
                      'Share for points system',
                    ]}
                    onPurchase={() => {
                      checkout(products[0]);
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div>
                <Card>This account is subscribed</Card>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <Spinner height={10} width={10} color="red" />
        </>
      )}
    </>
  );
}
