import { CheckCircleIcon, ExclamationCircleIcon, LocationMarkerIcon } from '@heroicons/react/outline';
import { Merchant } from '../models/merchant';

interface LocationCardProps {
  location: Merchant;
  displayImage?: boolean;
  displayAddress?: boolean;
  displayDistance?: boolean;
  displayYouAreHere?: boolean;
  onClick: () => void;
}

const LocationCard: React.FC<LocationCardProps> = ({
                                                     location,
                                                     displayImage = true,
                                                     displayAddress = true,
                                                     displayDistance = true,
                                                     displayYouAreHere = false,
                                                     onClick,
                                                   }) => {
  return (
    <div onClick={onClick} style={{
      margin: 8,
      width: '95%', // Adjust if you want to specify the width
      alignSelf: 'center',
      overflow: 'hidden',
      borderWidth: 1,
      borderRadius: 18,
      paddingBottom: 8,
      borderColor: '#dddddd',
      backgroundColor: 'white',
    }}
         className="shadow-md hover:scale-105 transition-transform duration-300 ease-in-out hover:cursor-pointer">
      {displayImage && (
        <>
          <img
            alt={"cover-logo"}
            src={
              location.coverImageUrl ||
              'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
            className={'h-[250px] w-full object-cover object-top rounded-2xl cursor-pointer'}
          />
        </>
      )}
      <div>
        <div className={'flex flex-row w-full'}>
          <img
            src={
              location.logoImageUrl ||
              'https://prod-werehere-images.s3.amazonaws.com/QBON.png'
            }
            style={{ height: 48, width: 48, borderRadius: 100 }}
            className={'m-4'}
            alt={"cover"}
          />
          <div className={'font-bold align-middle content-center'}>
            <p>
              {location.name}
            </p>
          </div>
        </div>
        <div>
          <div className={'flex flex-row m-4'}>
            {displayAddress && (
              <>
                <LocationMarkerIcon
                  name="location-on"
                  color="#666"
                  height={16} width={16}
                  style={{ marginRight: 5 }}
                />
                <p style={{
                  fontSize: 12,
                  color: '#666',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignSelf: 'center',
                  fontWeight: '600',
                }}>{location.address.city}</p>
                <p
                  style={{
                    fontSize: 4,
                    color: '#888888',
                    alignSelf: 'center',
                  }}
                  className={'mx-2'}
                >
                  {'\u2B24'}
                </p>
                <p style={{
                  fontSize: 12,
                  color: '#666',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignSelf: 'center',
                  fontWeight: '600',
                }}>{location.industry}</p>
                <p
                  style={{
                    fontSize: 4,
                    color: '#888888',
                    alignSelf: 'center',
                  }}
                  className={'mx-2'}
                >
                  {'\u2B24'}
                </p>
                <>
                  {location.status === 'SUBSCRIBED' ? (
                    <div className="text-green-600 flex flex-row">
                      <CheckCircleIcon width={24} height={24} />
                      <div className={"font-bold pl-2"}>
                        Subscribed
                      </div>
                    </div>
                  ) : (
                    <div className="text-yellow-500 flex flex-row">
                      <ExclamationCircleIcon width={24} height={24} />
                      <div className={'font-bold pl-2'}>
                        Not Subscribed
                      </div>
                    </div>
                  )}
                </>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;