import { useFetch } from './fetch.hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Tutorial, TutorialRequest } from '../models/tutorial';

const Routes = {
  merchantTutorial: (merchantId: number) => `/merchants/${merchantId}/tutorial`,
};

export default function useMerchantTutorial() {
  const { fetchJsonFunc } = useFetch();

  const useUpdateMerchantTutorialMutation = (
    merchantId: number,
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (req: TutorialRequest) => fetchJsonFunc<Tutorial>(Routes.merchantTutorial(merchantId), {
        method: 'PUT',
        body: JSON.stringify(req),
      }),
      onSuccess,
      onError,
    });
  };

  const useMerchantTutorialQuery = (
    merchantId: number,
  ) => {
    return useQuery({
      queryKey: ['merchantTutorial', merchantId],
      queryFn: () => fetchJsonFunc<Tutorial>(Routes.merchantTutorial(merchantId)),
    });
  };

  return {
    useMerchantTutorialQuery,
    useUpdateMerchantTutorialMutation
  };
}
