import { useNavigate, useParams } from 'react-router';
import { ArchiveIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Tabs from '../../../common/library/Tabs';
import useCoupons from '../../../hooks/coupon.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import CouponWrapper from './CouponWrapper';
import { MetricView } from '../../../common/metrics/MetricView';
import React, { useState } from 'react';
import ArchiveCouponModal from './ArchiveCouponModal';
import Button from '../../../common/library/Button';


export default function MerchantSingleCoupon() {
  const navigate = useNavigate();
  const { merchant, user } = useAuthContext().getMerchantAndUser();
  const { couponId } = useParams();

  const { useMerchantSingleCouponQuery } = useCoupons();
  const { data, isSuccess } = useMerchantSingleCouponQuery(merchant.id, parseInt(couponId || ''));

  const [archiveOpen, setArchiveOpen] = useState<boolean>(false);

  return (
    <>
      {data && <ArchiveCouponModal
        open={archiveOpen}
        merchantId={merchant.id}
        userId={user.id}
        coupon={data}
        onClose={() => setArchiveOpen(false)}
        onArchiveComplete={() => {
          setArchiveOpen(false);
          navigate('/dashboard/qbons');
        }}
      />}
      <div className="flex flex-row">
        <div>
          <h1
            className="font-bold text-xl pb-4 px-2 md:px-0 underline cursor-pointer text-[#fb3310]"
            onClick={() => navigate('/dashboard/qbons')}
          >
            Qbons
          </h1>
        </div>
        <div>
          <ChevronRightIcon
            className="h-6 w-6 text-gray-500 pt-1"
            aria-hidden="true"
          />
        </div>
        <div>
          <h1 className="font-bold text-xl pb-4 px-2 md:px-0">
            Qbon Details
          </h1>
        </div>
      </div>

      <Tabs
        tabs={[
          {
            category: 'Details',
            title: 'Details',
            content: (
              <>
                {isSuccess &&
                  <>
                    {data.type !== 'CHILD' && data.status === 'AVAILABLE' && data.redeemAction !== 'RAFFLE' && (
                      <div className={'flex justify-between'}>
                        <div></div>
                        <div className={'px-24 py-2'}>
                          <Button
                            label="Archive"
                            onClick={() => {
                              setArchiveOpen(true);
                            }}
                            icon={<ArchiveIcon width={16} height={16} />}
                            buttonWidth={'full'}
                          />
                        </div>
                      </div>
                    )}
                    <CouponWrapper coupon={data} merchant={merchant} userId={user.id} />
                  </>}
              </>
            ),
          },
          {
            category: 'Performance',
            title: 'Performance',
            content: (
              <>
                {couponId && <>
                  <div className="md:flex md:justify-between">
                    <div className=" w-full">
                      <MetricView isSystemMetrics={false} couponId={parseInt(couponId)}
                                  defaultMetricType={'COUPON_VIEWED'}
                                  primaryColor={'#5DA370'} />
                    </div>
                  </div>
                  <div className=" md:pl-2 w-full">
                    <MetricView isSystemMetrics={false} couponId={parseInt(couponId)}
                                defaultMetricType={'COUPON_CLAIMED'}
                                primaryColor={'#262666'} />
                  </div>
                  <div className="md:flex md:justify-between">
                    <div className="w-full">
                      <MetricView isSystemMetrics={false} couponId={parseInt(couponId)}
                                  defaultMetricType={'COUPON_REDEEMED'}
                                  primaryColor={'#E62929'} />
                    </div>
                  </div>
                </>}
              </>
            ),
          },
        ]}
      />

    </>
  );
}
