import classNames from "classnames";

interface Props {
  checked: boolean;
  label: string | React.ReactNode;
  onClick: () => void;
  bold?: boolean;
}

const Checkbox = ({ checked, label, onClick, bold }: Props) => {
  return (
    <>
      <div className="flex flex-row">
        <input
          checked={checked}
          type="checkbox"
          className="form-checkbox h-5 w-5 text-gray-600"
          onClick={onClick}
        />
        <span className={classNames("text-gray-700 ml-2", bold ? "font-bold" : "")}>{label}</span>
      </div>
    </>
  );
};

export default Checkbox;
