import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React, { useMemo } from 'react';
import { useMetrics } from '../../hooks/metrics.hook';
import useAuthContext from '../../hooks/auth-context.hook';
import { MetricType, secondaryColors } from './type';

interface Props {
  metricType: MetricType;
  fromDate: Date;
  toDate: Date;
  primaryColor: string;
  couponId?: number;
  raffleId?: number;
}

export function MerchantMetricView({ primaryColor, metricType, couponId, raffleId, fromDate, toDate }: Props) {
  const auth = useAuthContext();
  const { merchant } = auth.getMerchantAndUser();

  const meta = () => {
    switch (metricType) {
      case ('STORE_FRONT_ARRIVED'): {
        return { title: 'Scanned into or arrived at store front', param: '' };
      }
      case ('POINTS_REWARDED'): {
        return { title: 'Points rewarded', param: '' };
      }
      case ('LINK_CLICKED'): {
        return { title: 'Links clicked', param: '' };
      }
      case ('SOCIAL_SHARE'): {
        return { title: 'Social platforms users have shared to', param: '' };
      }
      case ('COUPON_CLAIMED'): {
        return { title: 'Qbon(s) rewarded to users count', param: 'couponMetricValue=CLAIMED' };
      }
      case ('COUPON_REDEEMED'): {
        return { title: 'Qbon(s) redeemed by users count', param: 'couponMetricValue=REDEEMED' };
      }
      case ('COUPON_CREATED'): {
        return { title: 'Qbons created count', param: 'couponMetricValue=CREATED' };
      }
      case ('COUPON_VIEWED'): {
        return { title: 'View count for Qbon', param: 'couponMetricValue=VIEWED' };
      }
      case ('RAFFLE_ENTERED'): {
        return { title: 'Users entering raffle count', param: 'raffleMetricValue=ENTERED' };
      }
      case ('CONTENT_POSTED'): {
        return { title: 'Count of content posted to your feed', param: 'contentMetricValue=CREATED' };
      }
      case ('MERCHANT_CREATED'): {
        return { title: 'Merchants created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      case ('USER_CREATED'): {
        return { title: 'Users created', param: 'systemMetricValue=MERCHANT_CREATED' };
      }
      default: {
        return { title: 'No metric available', param: '' };
      }
    }
  };

  const couponIdQueryParam = couponId ? `couponId=${couponId}` : '';
  const raffleIdQueryParam = raffleId ? `raffleId=${raffleId}` : '';

  const { useMerchantMetricsQuery } = useMetrics();
  const { data, isSuccess, isLoading } = useMerchantMetricsQuery(
    merchant.id,
    metricType,
    fromDate,
    toDate,
    [meta().param, couponIdQueryParam, raffleIdQueryParam],
  );

  function extractUniqueKeys(obj: any): string[] {
    const uniqueKeys = new Set<string>();

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key.toLowerCase() !== 'date') {
        uniqueKeys.add(key);
      }
    }

    return Array.from(uniqueKeys);
  }

  const lines = useMemo(() => {
    if (isSuccess) {
      const keys = extractUniqueKeys(data.timeseries[0]);
      return (
        keys.map((key, index) => (
          <Line
            type="monotone"
            dataKey={key}
            stroke={index === 0 ? primaryColor : secondaryColors[index % 10]}
            activeDot={{ r: 8 }}
          />
        ))
      );
    }
    return (<></>);
  }, [data, isSuccess, primaryColor]);

  if (metricType === 'COUPON_VIEWED' && !couponId) {
    return (<>Can not display this metric</>);
  }

  if (metricType === 'RAFFLE_ENTERED' && !raffleId) {
    return (<>Can not display this metric</>);
  }

  return (
    <>
      <div className="font-bold pl-12 pb-4">{meta().title}</div>
      {!isLoading &&
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={isSuccess ? data.timeseries : []}
            margin={{
              top: 5,
              right: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {lines}
          </LineChart>
        </ResponsiveContainer>}
    </>
  );
}