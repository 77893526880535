import React, { useState } from 'react';
import { Coupon, CouponStatus } from '../../../models/coupon';
import Button from '../../../common/library/Button';
import { PlusIcon } from '@heroicons/react/solid';
import { PageInfo } from '../../../common/library/Pagination';
import { useNavigate } from 'react-router';
import useCoupons from '../../../hooks/coupon.hook';
import useAuthContext from '../../../hooks/auth-context.hook';
import InfoBox from '../../../common/InfoBox';
import Table, { TableColumn } from '../../../common/library/Table';
import { expirationDateToText } from '../../../util';

export default function MerchantCoupons() {
  const { merchant } = useAuthContext().getMerchantAndUser();
  const navigate = useNavigate();

  const [pageInfo, setPageInfo] = useState<{ pageNumber: number, pageSize: number }>({
    pageNumber: 0,
    pageSize: 10,
  });

  const [filter] = useState<CouponStatus>('AVAILABLE');
  const { useMerchantCouponsQuery } = useCoupons();

  const {
    data: referralCoupon,
    isSuccess: isReferralSuccess,
  } = useMerchantCouponsQuery(merchant.id, 'AVAILABLE', 0, 10, undefined, 'REFERRAL');
  const {
    data: freeCoupon,
    isSuccess: isFreeSuccess,
  } = useMerchantCouponsQuery(merchant.id, 'AVAILABLE', 0, 10, undefined, 'FREE');

  const { data: coupons, isSuccess, isLoading: isLoadingCoupons } =
    useMerchantCouponsQuery(merchant.id, filter, pageInfo.pageNumber, pageInfo.pageSize,
      {
        pageNumber: 0,
        pageSize: 0,
        totalSize: 0,
        hasNext: false,
        hasPrevious: false,
        data: [
          ...(filter === 'AVAILABLE'
            ? [
              {
                id: -1,
                name: '10% Off Your Next Purchase',
                description:
                  'This is a sample coupon, to create one click on the plus icon above!',
                status: 'SAMPLE',
                startDate: new Date(),
                endDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
                merchantLocationId: 0,
                type: 'PRIMARY',
                childCouponId: null,
                code: undefined,
                barcodeImageUrl: undefined,
                couponImageUrl: undefined,
                createdDate: new Date(),
                updatedDate: new Date(),
                redeemAction: 'SOCIAL_SHARE',
              } as any,
            ]
            : []),
        ],
      });

  const columns: TableColumn<Coupon>[] = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Description',
      key: 'description',
    },
    {
      label: 'Status',
      format: (coupon: Coupon) => {
        return (
          <div className="text-center">
            {coupon.status === 'SAMPLE' ? (
              <div className="text-yellow-500">Sample Coupon</div>
            ) : coupon.startDate &&
            new Date(coupon.startDate) > new Date() ? (
              <div className="text-yellow-500">Starts in Future</div>
            ) : (
              <div className="text-green-500">Actively Distributing</div>
            )}
          </div>
        );
      },
    },
    {
      label: 'Expiration Date',
      format: (coupon: Coupon) => {
        return expirationDateToText(coupon.startDate && new Date(coupon.startDate), coupon.endDate && new Date(coupon.endDate));
      },
    },
    {
      label: 'Created Date',
      format: (coupon: Coupon) => {
        return new Date(coupon.createdDate).toLocaleDateString();
      },
    },
  ];

  return (
    <>
      <h1 className="font-bold text-xl px-2 md:px-0 pb-2">Qbons</h1>
      <div className="md:flex md:justify-between pb-2">
        <div className="w-full pt-1.5 flex-1">
          <div className="h-full flex flex-col justify-between">
            <div className="font-bold py-1">Referral Qbon</div>
            {isReferralSuccess && referralCoupon?.data.length === 0 && (
              <>
                <InfoBox
                  message={
                    'No Referral Qbon is active! A Referral Qbon is a discount your customers can share with their friends via a link when they post. Incentivize your customers friends to visit your business. (Optional)'
                  }
                />
                <div className="pt-2 mt-auto">
                  <Button
                    label={'Create Referral Qbon'}
                    buttonWidth={'full'}
                    variant={'outline'}
                    onClick={() => navigate(`/dashboard/qbons/create?type=REFERRAL`)}
                  />
                </div>
              </>
            )}
            {isReferralSuccess && referralCoupon?.data.length > 0 && (
              <>
                <div
                  className={'border-dashed border-gray-300 border-[1px] rounded-lg p-2 hover:bg-zinc-100 cursor-pointer'}
                  onClick={() => navigate(`/dashboard/qbons/${referralCoupon?.data[0].id}`)}>
                  <div className={'font-bold'}>
                    {referralCoupon?.data[0].name}
                  </div>
                  <div className={'py-1'}>
                    {referralCoupon?.data[0].description}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="md:pl-3 w-full pt-1.5 flex-1">
          <div className="h-full flex flex-col justify-between">
            <div className="font-bold py-1">Free Qbon</div>
            {isFreeSuccess && freeCoupon?.data.length === 0 && (
              <>
                <InfoBox
                  message={
                    'No Free Qbon is active! A Free Qbon is given to users who have the Qbon Mobile App after they scan, tap, or arrive at your business; no sharing required only claimable once every 12 hours. (Optional)'
                  }
                />
                <div className="pt-2 mt-auto">
                  <Button
                    label={'Create Free Qbon'}
                    buttonWidth={'full'}
                    variant={'outline'}
                    onClick={() => navigate(`/dashboard/qbons/create?type=FREE`)}
                  />
                </div>
              </>
            )}
            {isFreeSuccess && freeCoupon?.data.length > 0 && (
              <>
                <>
                  <div
                    className={'border-dashed border-gray-300 border-[1px] rounded-lg p-2 hover:bg-zinc-100 cursor-pointer'}
                    onClick={() => navigate(`/dashboard/qbons/${freeCoupon?.data[0].id}`)}>
                    <div className={'font-bold'}>
                      {freeCoupon?.data[0].name}
                    </div>
                    <div className={'py-1'}>
                      {freeCoupon?.data[0].description}
                    </div>
                  </div>
                </>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={'flex justify-between w-full'}>
        <div>
        </div>
        <div>
          <Button
            onClick={() => navigate(`/dashboard/qbons/create?type=SHARE`)}
            icon={<PlusIcon height={16} width={16} />}
            label="Create Qbon"
          />
        </div>
      </div>
      <Table
        loading={isLoadingCoupons}
        columns={columns}
        onRowClick={(coupon) => navigate(`/dashboard/qbons/${coupon.id}`)}
        data={coupons ? coupons.data : []}
        pagination={{
          pageInfo: {
            pageNumber: isSuccess ? coupons.pageNumber + 1 : pageInfo.pageNumber,
            pageSize: isSuccess ? coupons.pageSize : pageInfo.pageSize,
            totalSize: isSuccess ? coupons.totalSize : 0,
            hasPrevious: isSuccess ? coupons.hasPrevious : false,
            hasNext: isSuccess ? coupons.hasNext : false,
          },
          onChange: (info: PageInfo) => {
            setPageInfo({
              pageNumber: info.pageNumber - 1,
              pageSize: info.pageSize,
            });
          },
        }}
      />

    </>
  );
}
