import { useFetch } from './fetch.hook';
import { CreateRaffleDto, Raffle, RaffleEntry, RaffleEntryRequirement, RaffleResponse } from '../models/raffle';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Page } from '../types';

const Routes = {
  raffleRequirements: () => `/raffles/requirements`,
  merchantRaffles: (merchantId: number) => `/merchants/${merchantId}/raffles`,
  raffleEntries: (merchantId: number, raffleId: number, page: number, pageSize: number) =>
    `/merchants/${merchantId}/raffles/${raffleId}/entries?page=${page}&pageSize=${pageSize}`,
  raffles: (merchantId: number) => `/merchants/${merchantId}/raffles`,
  raffleDraw: (merchantId: number, raffleId: number) =>
    `/merchants/${merchantId}/raffles/${raffleId}/draw`,
  raffleComplete: (merchantId: number, raffleId: number) =>
    `/merchants/${merchantId}/raffles/${raffleId}/complete`,
  raffle: (raffleId: number) =>
    `/raffles/${raffleId}`,
  raffleCustomEntry: (merchantId: number, raffleId: number) =>
    `/merchants/${merchantId}/raffles/${raffleId}/entries-custom`,
};


export function useRaffles() {
  const { fetchJsonFunc } = useFetch();

  const useCreateRaffleMutation = (
    merchantId: number,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (request: CreateRaffleDto) => fetchJsonFunc<RaffleResponse>(
        Routes.raffles(merchantId), { method: 'POST', body: JSON.stringify(request) }),
      onSuccess,
      onError,
    });
  };

  const useDrawRaffleMutation = (
    merchantId: number,
    raffleId: number,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: () => fetchJsonFunc<RaffleEntry>(
        Routes.raffleDraw(merchantId, raffleId), { method: 'POST' }),
      onSuccess,
      onError,
    });
  };

  const useCompleteRaffleMutation = (
    merchantId: number,
    raffleId: number,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: () => fetchJsonFunc<Raffle>(
        Routes.raffleComplete(merchantId, raffleId), { method: 'POST' }),
      onSuccess,
      onError,
    });
  };

  const useCreateCustomEntryMutation = (
    merchantId: number,
    raffleId: number,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (
        req: { name: string, email: string },
      ) => fetchJsonFunc<RaffleEntry>(
        Routes.raffleCustomEntry(merchantId, raffleId), { method: 'POST', body: JSON.stringify(req) }),
      onSuccess,
      onError,
    });
  };

  const useMerchantSingleRaffleQuery = (raffleId: number) => {
    return useQuery({
      queryKey: ['singleRaffle', raffleId],
      queryFn: () => fetchJsonFunc<RaffleResponse>(Routes.raffle(raffleId)),
    });
  };

  const useMerchantRaffleEntries = (
    userId: number,
    merchantId: number,
    raffleId: number,
    page: number,
    pageSize: number,
  ) => {
    return useQuery({
      queryKey: ['raffleEntries', userId, merchantId, raffleId, page, pageSize],
      queryFn: () => fetchJsonFunc<Page<RaffleEntry>>(Routes.raffleEntries(merchantId, raffleId, page, pageSize)),
    });
  };

  const useMerchantRafflesQuery = (merchantId: number) => {
    return useQuery({
      queryKey: ['merchantRaffles', merchantId],
      queryFn: () => fetchJsonFunc<Raffle[]>(Routes.merchantRaffles(merchantId)),
    });
  };

  const useRaffleRequirementsQuery = () => {
    return useQuery({
      queryKey: ['raffleRequirements'],
      queryFn: () => fetchJsonFunc<RaffleEntryRequirement[]>(Routes.raffleRequirements()),
    });
  };

  return {
    useRaffleRequirementsQuery,
    useMerchantRafflesQuery,
    useCreateRaffleMutation,
    useMerchantSingleRaffleQuery,
    useMerchantRaffleEntries,
    useDrawRaffleMutation,
    useCompleteRaffleMutation,
    useCreateCustomEntryMutation,
  };
}