import React from 'react';
import useAuthContext from '../../hooks/auth-context.hook';
import useUserInvites from '../../hooks/user-invites.hook';
import Table, { TableColumn } from '../../common/library/Table';
import { MerchantUserInvite } from '../../models/invites';
import Button from '../../common/library/Button';
import { toast } from 'react-toastify';

export default function UserInvites() {
  const auth = useAuthContext();
  const { user } = auth.getUser();

  const { useGetUserInvitesQuery, useUpdateUserInviteMutation } = useUserInvites();
  const { data: invites, isLoading, refetch } = useGetUserInvitesQuery(user.id);
  const { mutate: acceptInvitation } = useUpdateUserInviteMutation(() => {
    refetch();
    toast('Invitation accepted!', { type: 'success' });
  }, () => {
    refetch();
    toast('Failed to accept invitation', { type: 'error' });
  });

  const columns: TableColumn<MerchantUserInvite>[] = [
    {
      label: 'Merchant Name',
      format: (val) => <>{val.merchant.name}</>,
    },
    {
      label: 'Received Date',
      format: (val) => <>{val.createdDate && new Date(val.createdDate).toLocaleDateString()}</>,
    },
    {
      label: 'Actions',
      format: ((invitation: MerchantUserInvite) => <>
        <div className={'flex'}>
          <div className={'px-2'}>
            <Button label={'Accept'}
                    onClick={() => acceptInvitation({ inviteId: invitation.id, userId: user.id, accept: true })} />
          </div>
          <div className={'px-2'}>
            <Button label={'Deny'} variant={'free'}
                    onClick={() => acceptInvitation({ inviteId: invitation.id, userId: user.id, accept: false })} />
          </div>
        </div>

      </>),
    },
  ];

  return (
    <>
      <div className="flex justify-between px-2 md:px-0 w-full pb-2">
        <h1 className="font-bold text-xl">Invites To Join Merchants</h1>
      </div>
      <div className="w-full">
        <Table
          loading={isLoading}
          columns={columns}
          data={invites ? invites : []}
        />
      </div>
    </>
  );
}



