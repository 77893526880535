import { Switch } from "@headlessui/react";

export interface ToggleProps {
  enabled: boolean;
  onChange: (value: boolean) => void;
  onColor?: string;
  offColor?: string;
}

export default function Toggle(props: ToggleProps) {
  const { enabled, onChange, onColor, offColor } = props;
  const colorOne = onColor ? onColor : "bg-green-600";
  const colorTwo = offColor ? offColor : "bg-red-500";

  return (
    <Switch
      checked={enabled}
      onChange={() => onChange(!enabled)}
      className={`${enabled ? colorOne : colorTwo}
          focus:outline-none relative inline-flex h-[25px] w-[40px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span
        aria-hidden="true"
        className={`${enabled ? "translate-x-4" : "translate-x-0"}
            pointer-events-none inline-block h-[21px] w-[21px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
}
