import classNames from 'classnames';
import {
  HTMLInputTypeAttribute,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import Button from './Button';
import HelpTip from './HelpTip/HelpTip';
import { CalendarIcon, ClipboardCopyIcon, SaveIcon } from '@heroicons/react/outline';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PencilIcon } from '@heroicons/react/solid';

export interface InputProps {
  value?: any;
  onChange?: (value: string) => void;
  placeholder?: string;
  onEnter?: () => void;
  label?: string;
  disabled?: boolean;
  inlineSaving?: boolean;
  deletable?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  type?: HTMLInputTypeAttribute;
  helpTip?: string;
  required?: boolean;
  copy?: boolean;
  icon?: ReactNode;
}

export default function Input({
                                value,
                                onChange,
                                placeholder,
                                onEnter,
                                label,
                                disabled,
                                inlineSaving = false,
                                deletable,
                                onSave,
                                onDelete,
                                type,
                                helpTip,
                                required,
                                copy,
                                icon,
                              }: InputProps) {
  const labelFor = `${label}_id`;
  const dateRef = useRef<HTMLDivElement>(null);

  const [disableWhileInline, setDisableWhileInline] = useState<boolean>(true);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dateRef.current && !dateRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dateRef]);

  return (
    <div>
      <div className="flex">
        {icon && (
          <>
            <div className="p-[2px]">{icon}</div>
          </>
        )}
        {label && (
          <label
            htmlFor={labelFor}
            className={classNames(
              'block mb-1 text-sm pl-1 font-bold',
              required && 'text-[#fb3310]',
              !required && disabled ? 'text-gray-400' : !required && 'text-gray-900',
            )}
          >
            {required && <>* </>}
            {label}
          </label>
        )}
        {helpTip && (
          <>
            <HelpTip message={helpTip} />
          </>
        )}
      </div>
      <div className="flex">
        <div className="grow flex" onClick={() => setDisableWhileInline(false)}>
          <input
            required={required}
            disabled={disabled || (inlineSaving && disableWhileInline)}
            id={labelFor}
            type={type === 'date' ? undefined : type}
            className={classNames(
              'w-full h-10 px-4 py-0 text-sm border focus:outline-none',
              (inlineSaving && disableWhileInline) || type === 'date' || copy
                ? 'bg-zinc-100 border-gray-300 text-gray-600 rounded-l-lg'
                : 'bg-white border-gray-300 text-gray-800 rounded-lg',
            )}
            placeholder={placeholder}
            value={
              type === 'date'
                ? value
                  ? new Date(value).toLocaleDateString()
                  : 'No Date Selected'
                : value
            }
            onChange={(event: any) => {
              if (onChange) {
                onChange(event.target.value as string);
              }
            }}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                if (onEnter) {
                  onEnter();
                }
                setDisableWhileInline(true);
              }
            }}
          ></input>
          {inlineSaving && disableWhileInline && (
            <span
              className="text-sm border cursor-pointer rounded-r-lg px-4 bg-[#fb3310] text-white whitespace-no-wrap">
              <div className="m-auto mt-2.5">
                <PencilIcon height={18} width={18} />
              </div>
            </span>
          )}
          {type === 'date' && (
            <span
              className="text-sm cursor-pointer border rounded-r-md px-4 bg-[#fb3310] text-white whitespace-no-wrap"
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              <div className="m-auto mt-1">
                <CalendarIcon className="w-4 h-5" />
              </div>
            </span>
          )}
          {copy && (
            <span
              className="text-sm border rounded-r-md px-4 bg-[#fb3310] text-white whitespace-no-wrap cursor-pointer hover:bg-slate-400">
              <div
                className="m-auto mt-1"
                onClick={() => {
                  navigator.clipboard.writeText(value);
                  alert('Copied text');
                }}
              >
                <ClipboardCopyIcon className="w-4 h-5" />
              </div>
            </span>
          )}
        </div>
        {inlineSaving && !disableWhileInline && (
          <div className="pl-2">
            <Button
              icon={<SaveIcon width={16} height={16} />}
              onClick={() => {
                setDisableWhileInline(true);
                if (onSave) {
                  onSave();
                }
              }}
            />
          </div>
        )}
        {deletable && (
          <div className="pl-2">
            <Button
              label="Delete"
              onClick={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
            />
          </div>
        )}
      </div>
      {showDatePicker && (
        <div className="absolute z-40" ref={dateRef}>
          <ReactDatePicker
            selected={value ? new Date(value) : null}
            className="absolute z-20"
            onChange={(date) => {
              setShowDatePicker(false);
              if (date && onChange) {
                onChange(date.toString());
              }
            }}
            inline
          />
        </div>
      )}
    </div>
  );
}
