import MerchantDevices from '../merchant-qr-code/MerchantDevices';
import Button from '../../../common/library/Button';
import WarningBox from '../../../common/WarningBox';

interface Props {
  onComplete: () => void;
}

export default function AddDevicesTutorial({ onComplete }: Props) {
  return (
    <>
      <WarningBox
        message={'Have a Qbon Plate? Click or tap the + button to scan the QR Code now and activate it for your Merchant Account.'} />
      <div className={'pt-6'}>
        <MerchantDevices />
      </div>
      <div className={'pt-6'}>
        <Button buttonWidth={'full'} label={'Continue'} onClick={onComplete} />
      </div>
    </>
  );
}