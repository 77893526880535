import { useFetch } from './fetch.hook';
import {
  BillingSessionDto,
  CheckoutSessionDto,
  CreateCheckoutSession,
  Product,
} from '../models/payment';
import { Subscription } from '../models/subscription';
import { useMutation, useQuery } from '@tanstack/react-query';

const Routes = {
  products: (licenseId?: number, state?: string) => `/products?${licenseId ? `&licenseId=${licenseId}` : ''}${state ? `&state=${state}` : ''}`,
  checkoutSession: (userId: number, merchantId: number) =>
    `/users/${userId}/merchants/${merchantId}/checkout/sessions`,
  billing: (userId: number, merchantId: number) =>
    `/users/${userId}/merchants/${merchantId}/billing/sessions`,
  subscriptions: (userId: number, merchantId: number) =>
    `/users/${userId}/merchants/${merchantId}/subscription`,
};

export function usePayments() {
  const { fetchJsonFunc } = useFetch();

  const useCreateCheckoutMutation = (
    userId: number,
    merchantId: number,
    onSuccess: (res: CheckoutSessionDto) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (checkoutSessionRequest: CreateCheckoutSession) => fetchJsonFunc<CheckoutSessionDto>(
        Routes.checkoutSession(userId, merchantId),
        {
          method: 'POST',
          body: JSON.stringify(checkoutSessionRequest),
        },
      ),
      onSuccess,
      onError,
    });
  };

  const useProductsQuery = (licenseId?: number, state?: string) => {
    return useQuery({
      queryKey: ['products'],
      queryFn: () => fetchJsonFunc<Product[]>(Routes.products(licenseId, state)),
    });
  };

  const useCreateBillingPortalMutation = (
    userId: number,
    merchantId: number,
    onSuccess: (res: BillingSessionDto) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: () => fetchJsonFunc<BillingSessionDto>(
        Routes.billing(userId, merchantId),
        {
          method: 'POST',
          body: JSON.stringify({
            returnUrl: `${window.location}`,
          }),
        },
      ),
      onSuccess,
      onError,
    });
  };

  const useSubscriptionQuery = (userId: number, merchantId: number) => {
    return useQuery({
      queryKey: ['subscription'],
      queryFn: () => fetchJsonFunc<Subscription>(Routes.subscriptions(userId, merchantId)),
      retry: false,
    });
  };

  return {
    useProductsQuery,
    useCreateCheckoutMutation,
    useCreateBillingPortalMutation,
    useSubscriptionQuery,
  };
}
