import { SupabaseClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import Input from '../../common/library/Input';
import Button from '../../common/library/Button';
import Divider from '../../common/library/Divider';
import { ErrorMessage } from '../../common/library/ErrorMessage';

export default function Login({ supabase }: { supabase: SupabaseClient }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const signIn = useCallback(() => {
    setLoading(true);
    setErrorMessage(undefined);
    supabase.auth
      .signInWithPassword({ password, email })
      .then((res) => {
        if (res.error) {
          setErrorMessage(res.error.message);
        }
      })
      .finally(() => setLoading(false));
  }, [supabase, password, email]);

  const signInWithProvider = useCallback(
    async (provider: 'google' | 'facebook') => {
      setErrorMessage(undefined);
      const { error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: `${window.location}`,
        },
      });
      if (error) setErrorMessage(error.message);
    },
    [supabase],
  );

  useEffect(() => {
    function handleKeyDown(e: any) {
      if (e.key === 'Enter') {
        signIn();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [signIn]);

  return (
    <>
      <div className="relative min-h-screen flex flex-col sm:justify-center items-center bg-gray-100">
        <div className="relative sm:max-w-md w-full align-middle">
          <div
            className="relative w-full sm:h-full h-screen sm:rounded-3xl px-2 sm:px-6 py-6 bg-white shadow-md m-auto flex sm:justify-center items-center">
            <div className="w-[500px] m-auto p-3">
              <img
                className="m-auto text-center font-bold h-[100px] w-[100px] mb-6"
                src={require('../../assets/qbon_logo.png')}
                alt="qbon-logo-login"
              />

              <div className="m-auto w-full">
                {errorMessage && (
                  <div className="py-2 text-red-500">
                    <ErrorMessage message={[errorMessage]} />
                  </div>
                )}

                <div className="py-3">
                  <Button
                    label="Sign in with Google"
                    variant="outline"
                    buttonWidth="full"
                    icon={
                      <>
                        <img
                          src={require('../../assets/google_logo.png')}
                          alt="google-logo"
                        />
                      </>
                    }
                    onClick={() => signInWithProvider('google')}
                  />
                </div>

                <div className="py-3">
                  <Button
                    label="Sign in with Facebook"
                    buttonWidth="full"
                    variant="outline"
                    icon={
                      <>
                        <img
                          src={require('../../assets/facebook_logo.png')}
                          alt="facebook-logo"
                        />
                      </>
                    }
                    onClick={() => signInWithProvider('facebook')}
                  />
                </div>

                <div className="py-3">
                  <Divider withLine size="sm" />
                </div>

                <div>
                  <Input
                    label="Email"
                    value={email || ''}
                    onChange={(value) => setEmail(value)}
                  />
                </div>

                <div className="mt-6">
                  <Input
                    type={'password'}
                    label="Password"
                    value={password || ''}
                    onChange={(value) => setPassword(value)}
                  />
                </div>

                <div className="mt-6">
                  <Button
                    label="Login"
                    buttonWidth="full"
                    onClick={signIn}
                    loading={loading}
                  />
                </div>

                <div className="mt-6 flex">
                  <label className="inline-flex items-center w-full cursor-pointer">
                  <span
                    className="ml-1 text-sm text-gray-600 underline"
                    onClick={() => navigate('/signup')}
                  >
                    Don't have an account? Sign up
                  </span>
                  </label>

                  <div className="w-full text-right">
                    <div
                      className="underline text-sm text-gray-600 hover:text-gray-900"
                      onClick={() => navigate('/forgot-password')}
                    >
                      Forgot Password?
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
