import { Page } from '../types';
import { useFetch } from './fetch.hook';
import {
  Merchant,
  MerchantRequestDTO,
  MerchantStoreFrontSettings,
  MerchantUser,
} from '../models/merchant';
import { useMutation, useQuery } from '@tanstack/react-query';

const Routes = {
  industries: () => `/search/merchants/industries`,
  userMerchants: (userId: number, page: number = 0, pageSize: number = 10) =>
    `/users/${userId}/merchants?page=${page}&pageSize=${pageSize}`,
  adminMerchants: (page: number = 0, pageSize: number = 10) =>
    `/admin/merchants?page=${page}&pageSize=${pageSize}`,
  merchant: (merchantId: number) =>
    `/merchants/${merchantId}`,
  merchantStoreFrontSettings: (merchantId: number) =>
    `/merchants/${merchantId}/store-front-settings`,
  merchantUsers: (
    merchantId: number,
    page: number,
    pageSize: number,
  ) =>
    `/merchants/${merchantId}/merchant-users?page=${page}&pageSize=${pageSize}`,
  merchantUser: (merchantId: number, merchantUserId: number) =>
    `/merchants/${merchantId}/merchant-users/${merchantUserId}`,
};

export default function useMerchants() {
  const { fetchJsonFunc, executeFetchFunc } = useFetch();

  const useCreateMerchantMutation = (
    userId: number,
    onSuccess?: (merchant: Merchant) => void,
    onError?: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (req: MerchantRequestDTO) => fetchJsonFunc<Merchant>(Routes.userMerchants(userId), {
        method: 'POST',
        body: JSON.stringify(req),
      }),
      onSuccess,
      onError,
    });
  };

  const useUserMerchantsQuery = (
    userId: number, contains?: string, page: number = 0, pageSize = 10
  ) => {
    return useQuery({
      queryKey: ['merchants', page, pageSize, userId, contains],
      queryFn: () => fetchJsonFunc<Page<Merchant>>(`${Routes.userMerchants(userId, page, pageSize)}${
        contains ? `&contains=${contains}` : ''
      }`),
      initialData: {
        data: [],
        pageSize: 0,
        pageNumber: 0,
        totalSize: 0,
        hasNext: false,
        hasPrevious: false,
      },
    });
  };

  const useMerchantIndustriesQuery = () => {
    return useQuery({
      queryKey: ['merchantIndustries'],
      queryFn: () => fetchJsonFunc<string[]>(Routes.industries()),
    });
  };

  const useUpdateMerchantMutation = (
    merchantId: number,
    onSuccess: (res: Merchant) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (req: MerchantRequestDTO) => fetchJsonFunc<Merchant>(Routes.merchant(merchantId), {
        method: 'PUT',
        body: JSON.stringify(req),
      }),
      onSuccess,
      onError,
    });
  };

  const useDeleteMerchantMutation = (
    merchantId: number,
    onSuccess: (res: Merchant) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: () => executeFetchFunc(`${Routes.merchant(merchantId)}`, {
        method: 'DELETE',
      }),
      onSuccess,
      onError,
    });
  };

  const useMerchantQuery = (merchantId: number, initialData?: Merchant) => {
    return useQuery({
      queryKey: ['merchant'],
      queryFn: () => fetchJsonFunc<Merchant>(Routes.merchant(merchantId)),
      initialData,
    });
  };

  const useAdminMerchantsQuery = (contains?: string, page: number = 0, pageSize: number = 10) => {
    return useQuery({
      queryKey: ['merchant', page, pageSize],
      queryFn: () => fetchJsonFunc<Page<Merchant>>(Routes.adminMerchants(page, pageSize)),
    });
  };

  const useMerchantUsersQuery = (
    merchantId: number,
    page: number = 0,
    pageSize: number = 10,
  ) => {
    return useQuery({
      queryKey: ['merchantUsers'],
      queryFn: () => fetchJsonFunc<Page<MerchantUser>>(
        Routes.merchantUsers(merchantId, page, pageSize),
      ),
    });
  };

  const useCreateMerchantUserMutation = (
    merchantId: number,
    onSuccess: (res: Merchant) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (userIdToAdd: number) => executeFetchFunc(`${Routes.merchantUsers(merchantId, 0, 0)}`, {
        method: 'POST',
        body: JSON.stringify({ userId: userIdToAdd }),
      }),
      onSuccess,
      onError,
    });
  };

  const useDeleteMerchantUserMutation = (
    merchantId: number,
    onSuccess: (res: void) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (merchantUserId: number) => fetchJsonFunc<void>(
        Routes.merchantUser(merchantId, merchantUserId),
        {
          method: 'DELETE',
        },
      ),
      onSuccess,
      onError,
    });
  };

  const useUpdateStoreFrontSettingsMutation = (
    merchantId: number,
    onSuccess: (res: MerchantStoreFrontSettings) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (req: MerchantStoreFrontSettings) => fetchJsonFunc<MerchantStoreFrontSettings>(
        Routes.merchantStoreFrontSettings(merchantId),
        {
          method: 'PUT',
          body: JSON.stringify(req),
        },
      ),
      onSuccess,
      onError,
    });
  };

  return {
    useCreateMerchantMutation,
    useUserMerchantsQuery,
    useMerchantIndustriesQuery,
    useUpdateMerchantMutation,
    useDeleteMerchantMutation,
    useMerchantQuery,
    useAdminMerchantsQuery,
    useMerchantUsersQuery,
    useCreateMerchantUserMutation,
    useDeleteMerchantUserMutation,
    useUpdateStoreFrontSettingsMutation,
  };
}
