import { ReactNode } from 'react';
import Input from './Input';
import 'react-datepicker/dist/react-datepicker.css';
import Combobox from './Combobox';
import QbonDatePicker from './DatePicker';
import classNames from 'classnames';
import HelpTip from './HelpTip/HelpTip';
import dayjs from 'dayjs';
import InfoBox from '../InfoBox';

export interface FormField {
  label: string;
  onValueChange: (value: string) => void;
  inlineEditing?: boolean;
  onSave?: () => void;
  value?: string;
  icon?: ReactNode;
  helpTip?: string;
  type?: 'date' | 'file' | 'number';
  required?: boolean;
  combobox?: boolean;
  options?: string[];
  helpTipWritten?: string;
}

export interface BasicFormProps {
  fields: FormField[];
  title?: string;
}

export default function BasicForm(props: BasicFormProps) {
  const { fields, title } = props;
  return (
    <>
      <div className="w-full lg:w-[550px]">
        {title && <div className="font-bold text-xl">{title}</div>}
        {fields.map((field: FormField, index: number) => (
          <>
            {field.helpTipWritten && <div key={index} className="w-full pt-12">
              <InfoBox message={field.helpTipWritten} />
            </div>}
            <div key={index} className="w-full py-3">
              {!field.combobox && field.type !== 'date' && (
                <Input
                  label={field.label}
                  icon={field.icon}
                  required={field.required}
                  inlineSaving={field.inlineEditing}
                  helpTip={field.helpTip}
                  onChange={(value) => field.onValueChange(value)}
                  onEnter={() => {
                    if (field.onSave) {
                      field.onSave();
                    }
                  }}
                  onSave={() => {
                    if (field.onSave) {
                      field.onSave();
                    }
                  }}
                  value={field.value}
                  type={field.type}
                />
              )}
              {field.combobox && (
                <Combobox
                  icon={field.icon}
                  required={field.required}
                  label={field.label}
                  options={field.options ? field.options : []}
                  value={field.value}
                  onChange={field.onValueChange}
                />
              )}
              {field.type === 'date' && (
                <>
                  <div className="flex">
                    {field.icon && (
                      <>
                        <div className="p-[2px]">{field.icon}</div>
                      </>
                    )}
                    {field.label && (
                      <label
                        className={classNames(
                          'block mb-1 text-sm font-bold pl-1',
                        )}
                      >
                        {field.required && <>* </>}
                        {field.label}
                      </label>
                    )}
                    {field.helpTip && (
                      <>
                        <HelpTip message={field.helpTip} />
                      </>
                    )}
                  </div>
                  <QbonDatePicker value={field.value ? dayjs(field.value) : null} onChange={(val) => {
                    field.onValueChange && val && field.onValueChange(val.toString());
                  }} />
                </>
              )}
            </div>
          </>
        ))}
      </div>
    </>
  );
}
