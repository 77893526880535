import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Session } from '@supabase/supabase-js';
import { fetchJson } from '../util/fetcher';
import { User } from '../models/users';
import { Merchant } from '../models/merchant';
import supabaseClient from './supabase';
import { vars } from '../env/env';
import { Page } from '../types';

const Routes = {
  queryUser: (externalId: string) => `/users?externalId=${externalId}`,
  user: (userId: number) => `/users/${userId}`,
};

export function useProviderAuth() {
  const navigate = useNavigate();
  const [merchant, setMerchant] = useState<Merchant>();
  const [authUser, setAuthUser] = useState<User>();
  const [session, setSession] = useState<Session | null>(null);
  const [loadingSession, setLoadingSession] = useState<boolean>(true);

  const refreshUserSession = (session: any) => {
    let intervalId: any;

    const fetchUser = async () => {
      setLoadingSession(true);
      fetchJson<User>(
        Routes.queryUser(session!.user.id),
        session?.access_token,
      )
        .then((user) => {
          if (user.email && user.email !== '') {
            setAuthUser(user);
            clearInterval(intervalId); // Stop the interval if the fetch is successful
          } else {
            setSession(null);
            supabaseClient.auth.signOut();
          }
        })
        .catch((error) => {
          setSession(null);
          supabaseClient.auth.signOut();
          console.error(error);
        }).finally(() => setLoadingSession(false));
    };
    fetchUser();
  };

  const refetchUser = () => {
    fetchJson<User>(
      Routes.queryUser(session!.user.id),
      session?.access_token,
    )
      .then((user) => {
        if (!user) {
          setSession(null);
          supabaseClient.auth.signOut();
        }
        navigate('/home');
        const currentMerchant = localStorage.getItem('qbonMerchant');
        setMerchant(
          currentMerchant
            ? (JSON.parse(currentMerchant) as Merchant)
            : undefined,
        );
        setAuthUser(user);
      })
      .catch(() => {
        setSession(null);
        supabaseClient.auth.signOut();
      })
      .finally(() => setLoadingSession(false));
  };

  const userHasMerchants = (userId: number, accessToken: string): Promise<boolean> => {
    return fetch(`${vars.api.baseUrl}/users/${userId}/merchants?page=0&pageSize=10`, {
      headers: new Headers({
        "Content-Type": "application/json",
        authorization: `${accessToken}`,
      })
    }).then(async (res) => {
      const body = await res.json();
      const page = body as Page<Merchant>;
      return page.data.length > 0;
    })
  }

  useEffect(() => {
    const { data } = supabaseClient.auth.onAuthStateChange(
      async (event: any, newSession: any) => {
        if (event === 'INITIAL_SESSION') {
          setLoadingSession(false);
        } else if (event === 'SIGNED_IN' && session === null) {
          fetchJson<User>(
            Routes.queryUser(newSession!.user.id),
            newSession?.access_token,
          )
            .then((user) => {
              if (!user) {
                supabaseClient.auth.signOut();
              }
              const currentMerchant = localStorage.getItem('qbonMerchant');
              setMerchant(
                currentMerchant
                  ? (JSON.parse(currentMerchant) as Merchant)
                  : undefined,
              );
              setAuthUser(user);
              setSession(newSession);
              userHasMerchants(user.id, newSession.access_token).then((val) => {
                if (!val) {
                  navigate("/create-merchant");
                }
              });
            })
            .catch(() => supabaseClient.auth.signOut())
            .finally(() => setLoadingSession(false));
        } else if (event === 'SIGNED_OUT') {
          setSession(null);
          setLoadingSession(false);
          localStorage.removeItem('qbonMerchant');
          navigate('/login');
        } else if (event === 'PASSWORD_RECOVERY') {
        } else if (event === 'TOKEN_REFRESHED') {
          if (session) {
            setSession(session);
            refreshUserSession(session);
          }
        } else if (event === 'USER_UPDATED') {
          setLoadingSession(false);
        }
      },
    );

    return () => {
      data.subscription.unsubscribe();
    };
  }, [navigate, session]);

  const setMerchantLocal = useCallback(
    (merchant: Merchant) => {
      localStorage.setItem('qbonMerchant', JSON.stringify(merchant));
      setMerchant(merchant);
      navigate('dashboard/profile');
    },
    [navigate],
  );

  const updateMerchant = useCallback((merchant: Merchant) => {
    localStorage.setItem('qbonMerchant', JSON.stringify(merchant));
    setMerchant(merchant);
  }, []);

  const exitMerchant = useCallback(() => {
    localStorage.removeItem('qbonMerchant');
    setMerchant(undefined);
    navigate('/home/merchants');
  }, [navigate]);

  const logOut = useCallback(() => {
    localStorage.clear();
    setMerchant(undefined);
    return supabaseClient.auth.signOut();
  }, []);

  const getMerchantAndUser = useCallback(() => {
    if (!merchant) {
      navigate('/home');
    }

    if (!authUser) {
      navigate('/home');
    }

    return {
      merchant: merchant!,
      user: authUser!,
    };
  }, [merchant, navigate, authUser]);

  const getUser = useCallback(() => {
    if (!authUser) {
      navigate('/home');
    }

    return {
      user: authUser!,
    };
  }, [navigate, authUser]);

  return {
    authUser,
    session, // Current active session
    supabaseClient, // auth client
    setMerchant: setMerchantLocal, // Sets a merchant for dashboard
    exitMerchant, // To leave merchant dashboard
    updateMerchant, // Update a merchant internally
    merchant, // Getting a merchant
    loadingSession, // Returned when session is loading
    refetchUser, // Refetch user data
    logOut, // Exit session
    getMerchantAndUser, // Get a merchant and user or return to home screen
    getUser, // Get a user or return to home
  };
}
