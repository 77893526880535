import React, { useState, useEffect, useRef } from 'react';

interface SixDigitInputProps {
  onComplete: (code: string) => void;
  onNotComplete: () => void;
}

const SixDigitInput: React.FC<SixDigitInputProps> = ({ onComplete, onNotComplete }) => {
  const [digits, setDigits] = useState<string[]>(new Array(6).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));

  useEffect(() => {
    const allDigitsFilled = digits.every(digit => digit.length === 1);
    if (allDigitsFilled) {
      onComplete(digits.join(''));
    } else if (digits[5] === '') {
      onNotComplete();
    }
  }, [digits, onComplete, onNotComplete]);

  const handleTextChange = (text: string, index: number) => {
    const newDigits = [...digits];
    newDigits[index] = text;
    setDigits(newDigits);

    if (text && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && index > 0 && digits[index] === '') {
      setDigits((prevDigits) => {
        const newDigits = [...prevDigits];
        newDigits[index - 1] = '';
        return newDigits;
      });
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div className="flex justify-around p-2.5">
      {digits.map((_, index) => (
        <input
          key={index}
          className="w-10 h-10 text-center mx-1 border-gray-400 border-2 text-lg rounded-lg"
          maxLength={1}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          onChange={(e) => handleTextChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyPress(e, index)}
          value={digits[index]}
          ref={(el) => (inputRefs.current[index] = el)}
        />
      ))}
    </div>
  );
};

export default SixDigitInput;
