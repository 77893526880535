import { useState } from 'react';
import { PageInfo } from '../../../common/library/Pagination';
import Table, { TableColumn } from '../../../common/library/Table';
import { Modal } from '../../../common/library/Modal';
import Button from '../../../common/library/Button';
import { ErrorMessage } from '../../../common/library/ErrorMessage';
import { MerchantUser } from '../../../models/merchant';
import useAuthContext from '../../../hooks/auth-context.hook';
import useMerchants from '../../../hooks/merchants.hook';
import { toast } from 'react-toastify';

export default function MerchantUsers() {
  const { merchant } = useAuthContext().getMerchantAndUser();
  const { useMerchantUsersQuery, useDeleteMerchantUserMutation } =
    useMerchants();

  const [userToDelete, setUserToDelete] = useState<MerchantUser>();
  const [deleteError, setDeleteError] = useState<string>();
  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 0,
    pageSize: 10,
  });


  const {
    data: merchantUsers, refetch: refetchMerchantUsers, isLoading: merchantUsersLoading,
    isSuccess: isSuccessMerchantUsers,
  } =
    useMerchantUsersQuery(
      merchant.id,
      pageInfo.pageNumber,
      pageInfo.pageSize,
    );

  const deleteUser = useDeleteMerchantUserMutation(
    merchant.id,
    () => {
      setUserToDelete(undefined);
      toast('Successfully removed user', { type: 'success' });
      refetchMerchantUsers();
    },
    (error: any) => {
      toast('An error occurred removing user', { type: 'error' });
    },
  );

  const columns: TableColumn<MerchantUser>[] = [
    {
      label: 'First Name',
      format: (val) => <>{val.user.firstName}</>,
    },
    {
      label: 'Last Name',
      format: (val) => <>{val.user.lastName}</>,
    },
    {
      className: 'hidden md:table-cell',
      label: 'Email',
      format: (val) => <>{val.user.email}</>,
    },
    {
      label: 'Actions',
      format: (val) => {
        return (
          <>
            {val.role !== 'MERCHANT_ADMIN' && (
              <>
                <Button label="Remove" onClick={() => setUserToDelete(val)} />
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        open={userToDelete !== undefined}
        buttonLoading={deleteUser.status === 'pending'}
        explicitCloseButton={true}
        onClose={() => {
          setUserToDelete(undefined);
          setDeleteError(undefined);
        }}

        onButtonClick={() => userToDelete && deleteUser.mutate(userToDelete.id)}
        title="Remove User"
        buttonLabel="Remove"
      >
        <div className="py-4">
          {deleteError && <ErrorMessage message={[deleteError]} />}
        </div>
        <p>
          Are you sure you want to remove{` `}
          {userToDelete &&
            `${userToDelete.user.lastName}, ${userToDelete.user.firstName} from your merchant profile?`}
        </p>
      </Modal>
      <div>
        <div className="w-full">
          <Table
            loading={merchantUsersLoading}
            columns={columns}
            data={isSuccessMerchantUsers ? merchantUsers.data : []}
            pagination={{
              pageInfo: {
                pageNumber: parseInt(pageInfo.pageNumber as any) + 1,
                pageSize: parseInt(pageInfo.pageSize as any),
                totalSize: isSuccessMerchantUsers ? merchantUsers.totalSize : 0,
                hasNext: isSuccessMerchantUsers ? merchantUsers.hasNext : false,
                hasPrevious: isSuccessMerchantUsers ? merchantUsers.hasPrevious : false,
              },
              onChange: (info: PageInfo) => {
                setPageInfo({
                  pageNumber: info.pageNumber - 1,
                  pageSize: info.pageSize,
                });
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
