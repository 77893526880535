import { Modal } from '../../../common/library/Modal';
import { ErrorMessage } from '../../../common/library/ErrorMessage';
import { useState } from 'react';
import { Coupon } from '../../../models/coupon';
import useCoupons from '../../../hooks/coupon.hook';

interface Props {
  open: boolean;
  coupon: Coupon;
  merchantId: number;
  userId: number;
  onClose: () => void;
  onArchiveComplete: () => void;
}

export default function ArchiveCouponModal({
                                             open,
                                             coupon,
                                             merchantId,
                                             onClose,
                                             onArchiveComplete,
                                           }: Props) {
  const { useArchiveCouponMutation } = useCoupons();
  const archiveCoupon = useArchiveCouponMutation(merchantId,
    () => onArchiveComplete(),
    () => setError(['An error occurred with the server']));
  const [error, setError] = useState<string[]>([]);

  return (
    <>
      <Modal
        open={open}
        buttonLoading={archiveCoupon.status === 'pending'}
        explicitCloseButton={true}
        onClose={() => {
          onClose();
        }}
        onButtonClick={() => archiveCoupon.mutate(coupon.id)}
        title="Archive Qbon"
        buttonLabel="Archive"
      >
        <p className="text-[#fb3310] p-4">
          <ErrorMessage message={error} />
        </p>
        <p>
          Are you sure you want to archive coupon{` `}
          {coupon && coupon.name}? It will no longer be available to users.
        </p>
      </Modal>
    </>
  );
}
