export interface Page<T> {
  data: T[];
  pageNumber: number;
  pageSize: number;
  totalSize: number;
  hasNext: boolean;
  hasPrevious: boolean;
}

export interface DynamoRecords<T> {
  records: T[],
  lastKey?: string;
}

export interface PageSizing {
  page: number;
  pageSize: number;
}

export interface Timeseries {
  timeseries: {
    day: any;
    count: number;
  }[];
}

export interface TimeseriesV2 {
  timeseries: MetricV2[];
}

export interface MetricV2 {
  date: string;
  [key: string]: number | string;
}

export type CouponMetricValue = 'CLAIMED' | 'REDEEMED' | 'VIEWED' | 'CREATED';
export type RaffleMetricValue = 'ENTERED' | 'VIEWED';
export type SystemMetricValue = 'MERCHANT_CREATED' | 'USER_CREATED' | 'LICENSE_UTILIZED';
export type ContentPostMetricValue = 'VIEWED' | 'CREATED';

export const pointsOptions = [
  '10',
  '20',
  '30',
  '40',
  '50',
  '60',
  '70',
  '80',
  '90',
  '100',
  '110',
  '120',
  '130',
  '140',
  '150',
  '160',
  '170',
  '180',
  '190',
  '200',
  '210',
  '220',
  '230',
  '240',
  '250',
  '260',
  '270',
  '280',
  '290',
  '300',
  '310',
  '320',
  '330',
  '340',
  '350',
  '360',
  '370',
  '380',
  '390',
  '400',
];