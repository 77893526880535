import Toggle from '../../../common/library/Toggle';
import Combobox from '../../../common/library/Combobox';
import { feetToMetersTextToInt, metersToText } from '../../../util';
import { User } from '../../../models/users';
import { Merchant } from '../../../models/merchant';
import useMerchants from '../../../hooks/merchants.hook';
import Button from '../../../common/library/Button';
import WarningBox from '../../../common/WarningBox';

interface Props {
  user: User,
  merchant: Merchant;
  onComplete: () => void;
}

export default function GeofenceTutorial({ merchant: incomingMerchant, user, onComplete }: Props) {

  const {
    useMerchantQuery,
    useUpdateStoreFrontSettingsMutation,
  } = useMerchants();
  const { data: merchant, refetch: refetchMerchant } = useMerchantQuery(incomingMerchant.id, incomingMerchant);

  const updateStoreFrontSettings = useUpdateStoreFrontSettingsMutation(incomingMerchant.id, (res) => {
    refetchMerchant();
  }, (error) => {

  });

  return (
    <>
      {merchant && (
        <>
          <WarningBox
            message={'Want us to verify the users location when they scan your QR Code? We will not allow users to access your storefront unless they are in your business, this prevents scanning your QR Code outside of your business. Not recommended for E-Commerce.'} />
          <div className="text-sm pt-6">
            Enable Verify QR Scan Location? (Users must be a certain
            distance from your locations map marker for QR codes to work)
          </div>
          <div className="pt-3">
            <Toggle
              enabled={
                merchant.storeFrontSettings?.enableVerifyQRScanLocation
                  ? merchant.storeFrontSettings.enableVerifyQRScanLocation
                  : false
              }
              onChange={(val) => {
                if (merchant.storeFrontSettings) {
                  updateStoreFrontSettings.mutate({
                    ...merchant.storeFrontSettings,
                    enableVerifyQRScanLocation: val,
                  });
                } else {
                  updateStoreFrontSettings.mutate({
                    enableArriveWithLocation: false,
                    arriveAtDistanceMeters: 10,
                    enableVerifyQRScanLocation: val,
                    verifyQRScanDistanceMeters: 0,
                  });
                }
              }}
            />
          </div>

          {merchant.storeFrontSettings?.verifyQRScanDistanceMeters && <div className="py-3">
            <Combobox
              label="Maximum distance to allow QR scan for users"
              options={[
                '15 Meters, Approximately 50 Feet',
                '30 Meters, Approximately 100 Feet',
                '60 Meters, Approximately 200 Feet',
                '150 Meters, Approximately 500 Feet',
                '230 Meters, Approximately 750 Feet',
                '300 Meters, Approximately 1000 Feet',
              ]}
              value={metersToText(
                merchant.storeFrontSettings?.verifyQRScanDistanceMeters,
              )}
              onChange={(val: string) => {
                if (merchant.storeFrontSettings) {
                  updateStoreFrontSettings.mutate({
                    ...merchant.storeFrontSettings,
                    verifyQRScanDistanceMeters:
                      feetToMetersTextToInt(val),
                  });
                } else {
                  updateStoreFrontSettings.mutate({
                    enableArriveWithLocation: true,
                    verifyQRScanDistanceMeters:
                      feetToMetersTextToInt(val),
                    enableVerifyQRScanLocation: false,
                    arriveAtDistanceMeters: 0,
                  });
                }
              }}
            />
          </div>}
        </>
      )}
      <div className={'pt-6'}>
        <Button buttonWidth={'full'} label={'Continue'} onClick={onComplete} />
      </div>
    </>
  );
}