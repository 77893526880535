import classNames from "classnames";

export interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export default function Card(props: CardProps) {
  const { children, className } = props;
  return (
    <>
      <div className={classNames("bg-white rounded-xl p-4 shadow-sm", className ? className : "w-full")}>{children}</div>
    </>
  );
}
