import { BanIcon, CheckIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import Pagination, { PageInfo } from "./Pagination";
import Spinner from "./Spinner";

export interface TableColumn<T> {
  key?: keyof T;
  label: string;
  check?: (value: T) => boolean;
  format?: (value: T) => React.ReactNode;
  className?: string;
}

export interface TableProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  loading?: boolean;
  pagination?: {
    pageInfo: PageInfo;
    onChange: (pageInfo: PageInfo) => void;
  };
  onRowClick?: (row: T) => void;
}

export default function Table<T>(props: TableProps<T>) {
  const { columns, data, pagination, onRowClick, loading } = props;
  return (
    <>
      <div className="py-4 overflow-visible overflow-x-auto">
        <div className="inline-block min-w-full overflow-hidden md:rounded-md">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                {columns.map((column: TableColumn<T>, index: number) => {
                  return (
                    <>
                      <th
                        key={index}
                        className={classNames(
                          "px-5 py-4 border-b-2 border-red-700 bg-white text-center text-xs font-semibold text-gray-600 uppercase tracking-wider",
                          column.className
                        )}
                      >
                        <p className="float-left">{column.label}</p>
                      </th>
                    </>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                <>
                  {data.length > 0 ? (
                    data.map((row: T, index: number) => {
                      return (
                        <>
                          <tr
                            className={classNames("hover:bg-red-100 bg-white -z-10", onRowClick && "hover:cursor-pointer")}
                            key={index}
                            onClick={() => {
                              if (onRowClick) {
                                onRowClick(row);
                              }
                            }}
                          >
                            {columns.map(
                              (column: TableColumn<T>, columnIndex: number) => {
                                return (
                                  <>
                                    <td
                                      key={columnIndex}
                                      className={classNames(
                                        "px-2 py-4 border-b border-gray-200 text-sm",
                                        column.className
                                      )}
                                    >
                                      <div className="float-left flex">
                                        {column.check && column.check(row) && (
                                          <div>
                                            <CheckIcon className="h-4 w-4" />
                                          </div>
                                        )}
                                        <div className="flex-shrink-0 hidden sm:table-cell"></div>
                                        <div className="ml-3">
                                          <p className="text-gray-900 whitespace-no-wrap">
                                            {column.key &&
                                              !column.format &&
                                              (row[column.key] as any)}
                                            {column.format &&
                                              !column.key &&
                                              column.format(row)}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                  </>
                                );
                              }
                            )}
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <tr className="h-24 bg-white w-full">
                        <td colSpan={columns.length}>
                          <div className="flex justify-center m-auto">
                            <BanIcon className="h-8 w-8 text-[#fb3310]" />
                            <div className="font-bold pt-1 pl-2">
                              No records available
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ) : (
                <>
                  <tr className="h-24 bg-white w-full">
                    <td colSpan={columns.length}>
                      <div className="flex justify-center m-auto items-center">
                        <Spinner height={8} width={8} color="red" />
                      </div>
                    </td>
                  </tr>
                </>
              )}
              {pagination && (
                <tr className="w-full">
                  <td colSpan={columns.length}>
                    <Pagination
                      pageInfo={pagination.pageInfo}
                      onChange={pagination.onChange}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
