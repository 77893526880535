import { useFetch } from './fetch.hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MerchantUserInvite } from '../models/invites';

const Routes = {
  userInvites: (userId: number) => `/users/${userId}/invites?statusId=1`,
  userInvite: (userId: number, inviteId: number) => `/users/${userId}/invites/${inviteId}`,
};

export default function useUserInvites() {
  const { fetchJsonFunc } = useFetch();

  const useUpdateUserInviteMutation = (
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    return useMutation({
      mutationFn: ({ inviteId, userId, accept }: {
        inviteId: number,
        userId: number,
        accept: boolean
      }) => fetchJsonFunc<MerchantUserInvite>(Routes.userInvite(userId, inviteId), {
        method: 'PUT',
        body: JSON.stringify({ accept }),
      }),
      onSuccess,
      onError,
    });
  };

  const useGetUserInvitesQuery = (
    userId: number,
  ) => {
    return useQuery({
      queryKey: ['userInvites', userId],
      queryFn: () => fetchJsonFunc<MerchantUserInvite[]>(Routes.userInvites(userId)),
    });
  };

  return {
    useGetUserInvitesQuery,
    useUpdateUserInviteMutation
  };
}
